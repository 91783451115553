import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc, collection, addDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { Table, Form, Button, Container } from 'react-bootstrap';
import Swal from 'sweetalert2';

const RegistroPracticas = () => {
  const { id } = useParams(); // Obtener el id del usuario desde la URL
  const db = getFirestore();

  const [moduloSeleccionado, setModuloSeleccionado] = useState('1');
  const [fichas, setFichas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFichas = async () => {
      setLoading(true);
      try {
        const fichasRef = collection(db, 'fichas');
        const q = query(fichasRef, where("alumnoId", "==", id), where("modulo", "==", moduloSeleccionado));
        const querySnapshot = await getDocs(q);
        setFichas(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching fichas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFichas();
  }, [id, moduloSeleccionado, db]);

  const handleFichaChange = (index, field, value) => {
    const updatedFichas = [...fichas];
    const fieldParts = field.split('[');
    if (fieldParts.length > 1) {
      // Handling nested fields like ejercicios[0]
      const fieldName = fieldParts[0];
      const fieldIndex = parseInt(fieldParts[1].replace(']', ''), 10);
      updatedFichas[index][fieldName][fieldIndex] = value;
    } else {
      updatedFichas[index][field] = value;
    }
    setFichas(updatedFichas);
  };

  const handleAddFicha = async () => {
    try {
      const newFicha = {
        alumnoId: id,
        ejercicios: Array(10).fill(''),
        fecha: '',
        numeroDePractica: '',
        tituloYpagsLeidas: '',
        modulo: moduloSeleccionado,
        fechaDeActualizacion: new Date(),
      };

      const docRef = await addDoc(collection(db, 'fichas'), newFicha);
      setFichas([...fichas, { ...newFicha, id: docRef.id }]);

      Swal.fire({
        title: 'Añadido',
        text: 'Nueva ficha añadida con éxito.',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error al añadir ficha:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al añadir',
        text: 'Ocurrió un error al intentar añadir la ficha.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleSaveFicha = async (index) => {
    const ficha = fichas[index];
    try {
      if (ficha.id) {
        const docRef = doc(db, 'fichas', ficha.id);
        await updateDoc(docRef, { ...ficha, fechaDeActualizacion: new Date() });

        Swal.fire({
          title: 'Guardado',
          text: 'Ficha actualizada con éxito.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        });
      }
    } catch (error) {
      console.error('Error al guardar ficha:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al guardar',
        text: 'Ocurrió un error al intentar guardar los cambios.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleDeleteFicha = async (index) => {
    const ficha = fichas[index];
    try {
      if (ficha.id) {
        await deleteDoc(doc(db, 'fichas', ficha.id));
        const updatedFichas = fichas.filter((_, i) => i !== index);
        setFichas(updatedFichas);

        Swal.fire({
          title: 'Eliminado',
          text: 'Ficha eliminada con éxito.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        });
      }
    } catch (error) {
      console.error('Error al eliminar ficha:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar',
        text: 'Ocurrió un error al intentar eliminar la ficha.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <Container fluid>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <div>
          <h2>Registro de Prácticas</h2>
          <Form>
            <Form.Group controlId="moduloSelect">
              <Form.Label>Seleccionar Módulo</Form.Label>
              <Form.Control as="select" value={moduloSeleccionado} onChange={e => setModuloSeleccionado(e.target.value)}>
                {Array.from({ length: 15 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>{`Módulo ${i + 1}`}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Título y Páginas Leídas</th>
                <th>Número de Práctica</th>
                {Array.from({ length: 10 }, (_, i) => <th key={i}>{`Ej. ${i + 1}`}</th>)}
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {fichas.map((ficha, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control type="date" value={ficha.fecha} onChange={(e) => handleFichaChange(index, 'fecha', e.target.value)} />
                  </td>
                  <td>
                    <Form.Control type="text" value={ficha.tituloYpagsLeidas} onChange={(e) => handleFichaChange(index, 'tituloYpagsLeidas', e.target.value)} />
                  </td>
                  <td>
                    <Form.Control type="text" value={ficha.numeroDePractica} onChange={(e) => handleFichaChange(index, 'numeroDePractica', e.target.value)} />
                  </td>
                  {ficha.ejercicios.map((ej, ejIndex) => (
                    <td key={ejIndex}>
                      <Form.Control
                        type="text"
                        value={ej}
                        onChange={(e) => handleFichaChange(index, `ejercicios[${ejIndex}]`, e.target.value)}
                      />
                    </td>
                  ))}
                  <td>
                    <Button onClick={() => handleSaveFicha(index)} className="btn btn-warning me-2">Guardar</Button>
                    <Button onClick={() => handleDeleteFicha(index)} className="btn btn-danger">Eliminar</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={handleAddFicha} className="btn btn-success">Añadir Ficha</Button>
        </div>
      )}
    </Container>
  );
};

export default RegistroPracticas;
