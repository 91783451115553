import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M6_Eje_Retencion = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de retencion",
                nivel: "Intermerdio",
                modulo: "6",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h2>Ejercicio de retención</h2>
            
            <h4>
                Retenga lo leído
           </h4>
          <p> 
          Repita el ejercicio realizado en el nivel anterior. Asocie las palabras clave a imágenes conocidas suya.
ordénelas según la secuencia y coloque un número consecutivo a cada imagen, repita la secuencia de
imágenes en su memoria. Dígase así mismo la idea principal que escogió con ésas imágenes.
            </p>
            

            <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};

export default M6_Eje_Retencion;
