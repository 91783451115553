import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M7_Eje_Comprension = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Comprensión",
                nivel: "Intermedio",
                modulo: "7",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h2>Ejercicio deComprensión</h2>
            <h4>Los Siete Cuervos</h4>
            <p>
            Érase una vez un hombre que tenía siete hijos pero deseaba tener una hija. Un buen día, su esposa
dio a luz a una hermosa niña. La alegría de los padres fue inmensa, pero como la niña era muy
pequeñita y débil, tuvieron que bautizarla en casa. El padre envio a sus hijos a la fuente a buscar
agua para el bautizo y, como todos querian ser el primero en llevar el agua, la jarra termino por
caerse al suelo y se rompió en mil pedazos.
            </p>
            <p>
            Viendo el padre que los chicos tardaban en volver, empezó a impacientarse y dijo: "No sé porqué se
demoran tanto en traer el agua si el pozo queda muy cerca. ¡Ojalá que estos bribones se
convirtieran en cuervos!". Apenas pronuncio estas palabras, se oyo un trueno poderoso y el rumor
de alas batiendo. El hombre y su esposa, asustados salieron de la casa a ver que sucedía. Miraron
hacia el cielo y vieron siete cuervos negros que volaban sobre la casa y se alejaban de allí. Los
pobres padres se quedaron muy tristes por la perdida de sus siete hijos, pero poco a poco fueron
consolándose con su hija que crecía cada vez más linda y menos débil.
            </p>
            <p>
            Durante algún tiempo la pequeña ignoró que hubiera tenido siete hermanos, pero al enterarse de
la triste historia que su madre no podría guardar más en secreto, decidió ir ella misma en busca de
sus hermanos. Se dirigió al sol, pero era terriblemente caluroso y nadie podía acercarse hasta el.
Entonces decidio ir a la luna, pero allí todo era oscuro, frío y triste. Al fin decidió preguntarle a las
estrellas que eran muy buenas y dulces. Estas, dandole un huesito, le dijeron: "solo con este
pequeño hueso puedes abrir la puerta de la Montaña de Cristal, dentro de la cual están tus
hermanos." Ella dudo al principio lo que le decian porque le parecía muy raro. Pero penso que no
perdía nada con intentarlo. La nina tomo el huesito y corrio hacia la Montaña de Cristal.
            </p>
            <p>
            Cuando llego, encontró la puerta cerrada, así que metió el hueso en la cerradura pudo entrar.
Dentro había una mesa con siete pequeños platos y copas donde se veían ricos alimentos y
bebidas. La nina se acordo de la historia de Ricitos de Oro, pero de todas formas probo un poquito
de cada plato y vaso, y entonces oyó chillidos y el batir de muchas alas. La pequeña sintió miedo y
se escondió rápidamente detrás de unas cortinas.
            </p>
            <p>
            Los cuervos entraron y se pusieron a beber y comer. Cuando el séptimo de ellos bebió todo el
contenido de su copa suspiro y dijo: "¡Ojalá a nuestra hermanita se le ocurriera venir a liberarnos!" La
niña oyó el deseo del pájaro y corrió a besarlos a todos. Entonces los siete cuervos volvieron a
recobrar su forma de niños, y muy felices regresaron todos a casa, sanos y salvos.
            </p>
         
            <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};

export default M7_Eje_Comprension;
