import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M8_EjercicioRetencion = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de retencion",
                nivel: "Intermerdio",
                modulo: "8",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h2>Ejercicio de retención</h2>
            
            <h4>
                Retenga lo que lee
           </h4>
          <p> 
          Repita nuevamente el ejercicio de asociación a imagenes que hizo para las palabras de la lectura del
modulo 6 y exprese la idea principal. Recuerde que el refuerzo es muy importante para la memoria.
entre mas se realice. mas posibilidades hay de que un dato se conserve en la memoria a largo plazo.
Y ahora, repita las palabras clave de la lectura del anterior módulo ¿?? , ¿las recuerda ?? ¿Recuerda
el argumento de la historia?
            </p>
            

            <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};




export default M8_EjercicioRetencion;
