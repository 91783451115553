import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M10_Ejercicio5 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    

 


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        
    try {
        await addDoc(collection(db, "evaluaciones"), {
            userId: currentUser.uid,
            nombre: "Ejercicio 5: Ejercicio de rastreo",
            nivel: "Intermedio",
            modulo: "10",
            createdAt: new Date(),
            tipo: "rastreo",
            respuestas
        });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const [respuestas, setRespuestas] = useState({
        item1: '',
        item2: '',
        item3: '',
        item4: '',
        item5: '',
        item6: '',
        item7: '',
        item8: ''
    });
    
    const handleChange = (key, value) => {
        setRespuestas(prev => ({
            ...prev,
            [key]: value
        }));
    };
    
    return (
        <div className="container mt-4">
           <h3>Nivel Intermedio</h3>
                    <h3>Módulo 10 - Ejercicio 5</h3>
                    <h5>Ejercicio de rastreo</h5>

            <p><strong>OBJETIVO</strong> <br></br>
Esta tecnica le permite ubicar en la lectura unicamente los datos o aspectos importantes, o aquellos que
usted necesita conocer.</p>

<p><strong>INSTRUCCIONES</strong></p>
<p>Vigile su tiempo al ubicar en la siguiente lectura los datos que le solicitamos: </p>

            <Row>
                <Col md={12}>

                    <Form className='border p-3'>

                    <Form.Label>1. Sauces y macizos de juncos</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item1}
        onChange={e => handleChange('item1', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>2. Sin duda sorprendente</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item2}
        onChange={e => handleChange('item2', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>3. Chozas de paja</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item3}
        onChange={e => handleChange('item3', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>4. Villanos y bandoleros</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item4}
        onChange={e => handleChange('item4', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>5. Resoplantes narices</Form.Label> 
    <Form.Control
        type="text"
        value={respuestas.item5}
        onChange={e => handleChange('item5', e.target.value)}
        placeholder="Ingrese su respuesta"
    />  

    <Form.Label>6. Sesenta yardas de ancho</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item6}
        onChange={e => handleChange('item6', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>7. Nueve leguas en línea recta</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item7}
        onChange={e => handleChange('item7', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

    <Form.Label>8. Cien millas diarias</Form.Label>
    <Form.Control
        type="text"
        value={respuestas.item8}
        onChange={e => handleChange('item8', e.target.value)}
        placeholder="Ingrese su respuesta"
    />

                        <br />

                      
                        
                        <br />

                        </Form>

                        
                </Col>
              

                <h4>Encuentro con el General Rosas de Charles Darwin</h4>
                <p>
                A la mañana siguiente, al acercarnos al Río Colorado, vimos cambiar el aspecto de la región, y
pronto llegamos a una pradera cubierta de césped con sus flores, su trébol crecido, y sus pequeños
búhos, se parecía mucho a las pampas. También dejamos a nuestro paso un fangoso pantano
bastante extenso, que en verano se seca y queda cubierto de incrustaciones de diferentes sales, de
donde deriva su denominación de salitral. Estaba recubierto de plantas bajas y carnosas, de la misma
clase que las que crecen a orillas del mar. El Colorado, en el paso por donde lo atravesamos, tiene
solamente unas sesenta yardas de ancho, aunque por lo general su anchura debe ser el doble. Su
curso es muy sinuoso y esta jalonado de sauces y macizos de juncos. Según se me informó, la
distancia hasta la desembocadura era de nueve leguas en línea recta, pero de veinticinco siguiendo
el curso del Río. Nos demoramos al cruzarlo en canoa debido a la presencia de unas enormes
tropillas de yeguas que nadaban por el Río siguiendo una división de tropas que se hallaban en el
interior. Jamás contemple un espectáculo más cómico que esos cientos y cientos de cabezas, todas
en la misma dirección con orejas rectas y resoplantes narices, emergiendo apenas sobre la superficie
del agua como un enorme banco de algún tipo de anfibio. La carne de yegua es el único alimento
que utilizan las tropas en campana. Esto les da una gran facilidad de movimiento pues la distancia
que los caballos pueden recorrer sobre las llanuras es sin duda sorprendente: me han asegurado que
un caballo, si no lleva carga, es capaz de recorrer cien millas por espacio de varios días
ininterrumpidamente. El campamento del General Rosas estaba situado junto al Río. y consistía en
un cuadrado formado por carreteras, artillería chozas de paja, etc. Los soldados eran casi todos la
caballería, y yo diria que un ejercito integrado por gentes con apariencia de villanos y bandoleros
jamás podría haberse reunido en época alguna. La mayor parte de los hombres eran mestizos de
negro, indio y español. No sé por qué razón, pero la gente de esa sangre, rara vez tienen una buena
expresión en el semblante. me dirigi al secretario de Rosas para presentarle mis pasaportes, y
comenzo a interrogarme en forma grave y misteriosa.
                </p>

                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M10_Ejercicio5;
