import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';
import '../../../../App.css';

const Ejercicio11 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 11: Infiera por Contexto",
                nivel: "Basico",
                modulo: "1",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
             <h3>Nivel Básico</h3>
      <h3>Módulo 1 - Ejercicio 11</h3>
      <h5>Infiera por Contexto</h5>

            
            <p className='text-justify'>
                Inferir por contexto es encontrar el significado de una palabra, relacionada con el contexto general de la lectura. Implica leer las palabras anteriores o siguientes como referente para hallar una pista sobre el significado de la palabra que no entendemos.
            </p>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="mb-3"
            >
                Pasos para inferir por contexto una vez leído el texto
            </Button>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <ul>
                        <li>Subrayar la palabra desconocida.</li>
                        <li>Determinar, considerando sus características, estructura y determinantes, si es un sustantivo, verbo, adjetivo, etc.</li>
                        <li>Aislar la palabra, tratando a través de sus morfemas y constitutivos, de entender su significado.</li>
                        <li>Relacionar la palabra con los componentes de la oración.</li>
                        <li>Buscar una palabra que la pueda reemplazar sin perder el contexto.</li>
                        <li>Situar la palabra encontrada en el contexto inmediato u oracional.</li>
                        <li>Revisar si el sentido de la oración no se ha perdido.</li>
                        <li>Determinar el significado de la palabra.</li>
                    </ul>
                    <p><strong>Ejemplo</strong></p>
                    <p>Texto de trabajo:</p>
                    <p>"Casi ni tiempo había tenido el desdichado animal de humedecer su lengua en el líquido cuando sufrió un temblor 'compulsivo' en todos sus miembros y quedó tan rígido y sin vida como si lo hubiese herido el rayo".</p>
                </div>
            </Collapse>
            <h4>Desarrollando los 8 pasos de la estrategia</h4>
            <p className='text-justify'>La palabra desconocida es "compulsivo". Por sus características, es un adjetivo calificativo, palabra cuya raíz
                es puls; el prefijo es com. Compulsivo, califica al sustantivo "temblor". El verbo es "sufrió", por lo tanto hay
                un cambio que pareciera connotar algo negativo. El que sufre es el "desdichado animal". Con respecto del
                temblor, éste tiene una característica que se sufre "en todos sus miembros"; algo simultáneo que invade a
                todo el cuerpo. Se describe tambien que éste temblor lo deja "tan rígido y sin vida como si lo hubiese
                herido el rayo".</p>
            <p className='text-justify'>
                Por la segunda oración del párrafo: "y quedo tan ... ", se está señalando que fue un temblor intenso.
                arrebatador, potente, que involucra a todas las partes del cuerpo y las obliga a reaccionar.
                En la primera oración, unida a la segunda por la conjuncion "y", se expresa que fue algo muy repentino e
                inevitable, porque sólo llegó. Entonces, la palabra desconocida estaría en esa dirección.
                Palabras que se asemejan: "forzoso, obligatorio, apremiante".</p>
            
                <h4>Para verificar si la inferencia es correcta:</h4>
            <p className='text-justify'>
                <strong>a. Observa </strong> si la palabra de tu inferencia pertenece a la misma categoría gramatical de la palabra
                desconocida. Las palabras forzoso, obligatorio, apremiante son adjetivos calificativos al igual que la palabra
                compulsivo. <strong>b. Reemplazar</strong> la palabra desconocida por que se infirió. Si la oración tiene sentido, la
                inferencia probablemente está correcta." ... cuando sufrio un temblor apremiante en todos sus miembros y
                quedo rgido y sin vida como si lo hubiese herido el rayo" <strong>c. Si la palabra</strong> desconocida puede separarse en
                prefijo, raíz, y sufijo, y darle un sentido, ayuda también a inferir sus significados. Hay tener cuidado con
                palabras que tienen mas de una acepcion, ya que se podra inferir un significado distinto al que precisa el
                contexto, y cometer un error. Es conveniente seguir primero las estrategias anteriores, es decir, inferir por
                contexto, antes de optar por este sistema. Experiencias han demostrado que el solo uso de afijos y raíces
                como elementos de inferencia no son tan eficaces, ya que no todas las palabras tienen estos
                componentes. Una vez que la palabra desconocida ha sido analizada según sus partes, es mas probable
                que la inferencia tuerza la interpretación del contexto. Recomendamos el uso de afijos y las raíces como el
                último paso de la estrategia para que se acerque a la interpretacion del contexto con una mente abierta.
            </p>    



            {/* <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Cerrar Ejercicio</Button>
            </Form> */}
                 
        </Container>
    );
};

export default Ejercicio11;
