import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';

const AddCuadernoDigital = () => {
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [form, setForm] = useState({
    titulo: '',
    descripcion: '',
    nivel: '',
    tipo: ''
  });
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    const fetchEvaluaciones = async () => {
      const querySnapshot = await getDocs(collection(db, 'evaluaciones'));
      setEvaluaciones(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchEvaluaciones();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentId) {
      const evalRef = doc(db, 'evaluaciones', currentId);
      await updateDoc(evalRef, form);
      alert('Evaluación actualizada con éxito');
    } else {
      await addDoc(collection(db, 'evaluaciones'), form);
      alert('Evaluación agregada con éxito');
    }
    setForm({ titulo: '', descripcion: '', nivel: '', tipo: '' });
    setCurrentId(null);
  };

  const handleEdit = (evaluation) => {
    setForm(evaluation);
    setCurrentId(evaluation.id);
  };

  const handleDelete = async (id) => {
    const evalRef = doc(db, 'evaluaciones', id);
    await deleteDoc(evalRef);
    alert('Evaluación eliminada con éxito');
    setEvaluaciones(evaluaciones.filter(evaluation => evaluation.id !== id));
  };

  return (
    <Container>
      <h1 className="text-center my-4">Administrar Evaluaciones</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="titulo">
              <Form.Label>Título</Form.Label>
              <Form.Control type="text" name="titulo" value={form.titulo} onChange={handleChange} placeholder="Título de la evaluación" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="nivel">
              <Form.Label>Nivel Asignado</Form.Label>
              <Form.Control type="text" name="nivel" value={form.nivel} onChange={handleChange} placeholder="Nivel asignado" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control as="textarea" rows={3} name="descripcion" value={form.descripcion} onChange={handleChange} placeholder="Descripción de la evaluación" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group controlId="tipo">
              <Form.Label>Tipo de Evaluación</Form.Label>
              <Form.Control as="select" name="tipo" value={form.tipo} onChange={handleChange}>
                <option value="">Seleccione tipo de evaluación</option>
                <option value="Texto libre">Texto libre</option>
                <option value="Opción múltiple">Opción múltiple</option>
                <option value="Verdadero/falso">Verdadero/falso</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="my-3">
          {currentId ? 'Actualizar Evaluación' : 'Agregar Evaluación'}
        </Button>
      </Form>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Título</th>
            <th>Nivel</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {evaluaciones.map((evaluation) => (
            <tr key={evaluation.id}>
              <td>{evaluation.titulo}</td>
              <td>{evaluation.nivel}</td>
              <td>
                <Button variant="outline-primary" onClick={() => handleEdit(evaluation)} className="me-2">Editar</Button>
                <Button variant="outline-danger" onClick={() => handleDelete(evaluation.id)}>Eliminar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};


export {AddCuadernoDigital};