import { useEffect } from "react";
import { logout } from "../firebase/firebase";

function SignOutView() {
    
  useEffect(() => {
    logout()
    .then(() => {
      window.location.href = "/login";
    })
  }, []);

  return (
    <></>
  );
}

export { SignOutView };