import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M5_Ejercicio10 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        // Raíz: physis = naturaleza
        fisioterapia: '',
        
        // Raíz: polis, polys = muchos
        poligamia: '',
        
        poligono: '',
        
        // Raíz: tele = lejos, a distancia, a lo lejos
        television: '',
        telefono: '',
        telegrama: '',
        telepatia: '',
        
        // Raíz: theos, teos = dios
        teologia: '',
        teocracia: '',
        teofilo: '',
        ateo: '',

        // Raíz: sophia = saber, ciencia

        

        //Raíz syn = unión, inclusión, unión en uno solo.

        sintesis: '',
        simpatia: '',
        simbolico: '',
        sinfonia: '',
        simetrico: '',
        sintonia: '',
        sinoptico: '',
        sinergia: '',
        

        
        // Raíz: sarkos = carne, cuerpo
        
        sarcofago: '',
        sarcasmo: '',

        // Raíz: therapeu, therapautos = curar, cultivar, cuidar
        hidroterapia: '',
        psicoterapia: '',
        terapeuta: '',
        
        // Raíz: xenos = extranjero, ajeno
        xenofobia: '',
        xenofilia: '',

        // Raíz: zoon = animal, ser viviente
        zoologia: '',
        zoologico: '',
        protozoo: ''
        

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 10 - Ejercicio de Comprensión",
                nivel: "Basico",
                modulo: "5",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h3>Nivel Básico</h3>
            <h3>Módulo 5 - Ejercicio 10</h3>
            <h5>Ejercicio de comprensión</h5>

            
            <p><strong>Incremente su vocabulario</strong></p>


            <Row>
                <Col md={6}>

                    <Form className='border p-3'>

                        <p><strong>Raiz: physis = naturaleza.</strong></p>
                        <p>Física: Ciencia que estudia los cuerpos de la naturaleza <br />
                            Fisonomía: Aspecto físico</p>

                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="fisioterapia"
                                    value={respuestas.fisioterapia}
                                    onChange={handleChange}
                                    placeholder="Fisioterapia"
                                />
                            </FormGroup>
                           <p>
                            Fisiología: Ciencia que estudia la vida.</p> 
                        

                        </Form>


                        <br />

                        <Form className='border p-3'>




                            <p><strong>Raíz: Polis, polys = muchos</strong></p>
                            
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="poligamia"
                                    value={respuestas.poligamia}
                                    onChange={handleChange}
                                    placeholder="Poligamia"
                                />
                            </FormGroup>
                            <p>Poliglota: Que habla muchos idiomas</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="poligono"
                                    value={respuestas.poligono}
                                    onChange={handleChange}
                                    placeholder="Polígono"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: tele = lejos, a distancia, a lo lejos</strong></p>
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="television"
                                    value={respuestas.television}
                                    onChange={handleChange}
                                    placeholder="Televisión"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="telefono"
                                    value={respuestas.telefono}
                                    onChange={handleChange}
                                    placeholder="Teléfono"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="telegrama"
                                    value={respuestas.telegrama}
                                    onChange={handleChange}
                                    placeholder="Telegrama"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="telepatia"
                                    value={respuestas.telepatia}
                                    onChange={handleChange}
                                    placeholder="Telepatía"
                                />
                            </FormGroup>
                        </Form>
                        <br />


                        <Form className='border p-3'>

                            <p><strong>Raíz: theos, teos = dios</strong></p>
                            <p>Teodor: donde dios</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="teologia"
                                    value={respuestas.teologia}
                                    onChange={handleChange}
                                    placeholder="Teología"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="teocracia"
                                    value={respuestas.teocracia}
                                    onChange={handleChange}
                                    placeholder="Teocracia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="teofilo"
                                    value={respuestas.teofilo}
                                    onChange={handleChange}
                                    placeholder="Teofilo"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="ateo"
                                    value={respuestas.ateo}
                                    onChange={handleChange}
                                    placeholder="Ateo"
                                />
                            </FormGroup>

                        </Form>
                        <br />

                        <Form className='border p-3'>
                            <p><strong>Raíz: sophia = saber, ciencia</strong></p>
                            <p>Filósofo: Amigo del estudio, de la ciencia. Filos es amigo.
                            </p>
                    </Form>    
                </Col>
                <Col md={6}>
                    <Form>
                        <Form className='border p-3'>
                            <p><strong>Raíz:  syn = unión, inclusión, unión en uno solo.</strong></p>
                            
                            <FormGroup className="mb-3 ">
                                <FormControl
                                    type="text"
                                    name="sintesis"
                                    value={respuestas.sintesis}
                                    onChange={handleChange}
                                    placeholder="Síntesis"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="simpatia"
                                    value={respuestas.simpatia}
                                    onChange={handleChange}
                                    placeholder="Simpatía"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="simbolico"
                                    value={respuestas.simbolico}
                                    onChange={handleChange}
                                    placeholder="Simbólico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sinfonia"
                                    value={respuestas.sinfonia}
                                    onChange={handleChange}
                                    placeholder="Sinfonía"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="simetrico"
                                    value={respuestas.simetrico}
                                    onChange={handleChange}
                                    placeholder="Simétrico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sintonia"
                                    value={respuestas.sintonia}
                                    onChange={handleChange}
                                    placeholder="Sintonia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sinoptico"
                                    value={respuestas.sinoptico}
                                    onChange={handleChange}
                                    placeholder="Sinóptico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sinergia"
                                    value={respuestas.sinergia}
                                    onChange={handleChange}
                                    placeholder="Sinergia"
                                />
                            </FormGroup>
                            <p>Sintaxis: Unido con un orden</p>

                        </Form>
                        <br />
                        <Form className='border p-3'>

                            <p><strong>Raíz: sarkos = carne, cuerpo</strong></p>
                            <p>Sarcoma: Tumor de tejido.
                            </p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sarcofago"
                                    value={respuestas.sarcofago}
                                    onChange={handleChange}
                                    placeholder="Sarcófago"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sarcasmo"
                                    value={respuestas.sarcasmo}
                                    onChange={handleChange}
                                    placeholder="Sarcasmo"
                                />
                            </FormGroup>
                        </Form>
                        <br />
                        <Form className='border p-3'>


                            <p><strong>Raíz: therapeu, therapautos = curar, cultivar, cuidar.</strong></p>
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="hidroterapia"
                                    value={respuestas.hidroterapia}
                                    onChange={handleChange}
                                    placeholder="Hidroterapia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="psicoterapia"
                                    value={respuestas.psicoterapia}
                                    onChange={handleChange}
                                    placeholder="Psicoterapia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="terapeuta"
                                    value={respuestas.terapeuta}
                                    onChange={handleChange}
                                    placeholder="Terapeuta"
                                />
                            </FormGroup>

                        </Form>
                        <br />
                        <Form className='border p-3'>



                            <p><strong>Raíz: xenos = extranjero, ajeno</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="xenofobia"
                                    value={respuestas.xenofobia}
                                    onChange={handleChange}
                                    placeholder="Xenofobia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="xenofilia"
                                    value={respuestas.xenofilia}
                                    onChange={handleChange}
                                    placeholder="Xenofilia"
                                />
                            </FormGroup>
                        </Form>
                        <br />
                        <Form className='border p-3'>
                            <p><strong>Raíz: zoon = animal, ser viviente</strong></p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="zoologia"
                                    value={respuestas.zoologia}
                                    onChange={handleChange}
                                    placeholder="Zoología"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="zoologico"
                                    value={respuestas.zoologico}
                                    onChange={handleChange}
                                    placeholder="Zoológico"
                                />
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="protozoo"
                                    value={respuestas.protozoo}
                                    onChange={handleChange}
                                    placeholder="Protozoo"
                                />
                            </FormGroup>
                            
                            </Form>





                    </Form>
                </Col>
                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M5_Ejercicio10;
