import React, { useState, useContext } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { AppContext } from '../../Context'; // Importando AuthContext
import { db } from '../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore";

function Evaluacion2() {
    const { currentUser } = useContext(AppContext); // Utilizando AuthContext para obtener el usuario actual
    const [respuestas, setRespuestas] = useState({
        abdicar: '',
        abandonar: '',
        abadia: '',
        abad: '',
        aborto: '',
        absoluto: '',
        anales: '',
        milenio: '',
        agrario: '',
        agricultura: '',
        agricultor: '',
        agreste: '',
        enajenar: '',
        alienacion: '',
        alienar: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };

    const enviarResultados = async () => {
        if (!currentUser) {
            alert("No está autenticado");
            return;
        }
        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid, // Usar el UID del usuario autenticado
                nombre: "Intermedio - Módulo 6",
                nivel: "Intermedio",
                tipo: "Texto libre",
                respuestas
            });
            // const puntuacion = Math.floor(Math.random() * 101); // Genera una puntuación aleatoria entre 0 y 100
            alert(`Resultados enviados`);
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            alert('Error al enviar las respuestas.');
        }
    };

    return (
        <Container className="my-4">
            <h1 className="text-center mb-3">Intermedio - Módulo 6</h1>
            <h2>EJERCICIO DE COMPRENSIÓN: INCREMENTE SU VOCABULARIO</h2>
            <p>RAÍCES LATINAS</p>
            <Form>
                {Object.entries(respuestas).map(([key, value]) => (
                    <Form.Group as={Row} className="mb-3" key={key}>
                        <Form.Label column sm="4">
                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}:
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name={key}
                                value={value}
                                onChange={handleChange}
                                placeholder={`Ingrese su respuesta para ${key}`}
                            />
                        </Col>
                    </Form.Group>
                ))}
                <div className="d-grid">
                    <Button variant="primary" onClick={enviarResultados}>Enviar Resultados</Button>
                </div>
            </Form>
        </Container>
    );
}

export { Evaluacion2 };
