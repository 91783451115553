import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, ListGroup, Container } from 'react-bootstrap';

const ViewMaterialTeorico = () => {
    const { nivel } = useParams();
    const [lecciones, setLecciones] = useState([]);
    const [leccionSeleccionada, setLeccionSeleccionada] = useState(null);

    useEffect(() => {
        // Simular la carga de lecciones para un nivel específico
        const leccionesCargadas = [
            { id: 1, titulo: 'Lección 1', contenido: 'Contenido de Lección 1' },
            { id: 2, titulo: 'Lección 2', contenido: 'Contenido de Lección 2' }
        ];
        setLecciones(leccionesCargadas);
        setLeccionSeleccionada(leccionesCargadas[0]); // Seleccione la primera lección por defecto
    }, [nivel]);

    return (
        <div className='container'>
            <Row>
                <Col md={3} className="sidebar">
                    <ListGroup>
                        {lecciones.map(leccion => (
                            <ListGroup.Item key={leccion.id} action onClick={() => setLeccionSeleccionada(leccion)}>
                                {leccion.titulo}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col md={9}>
                    {leccionSeleccionada && (
                        <div>
                            <h2>{leccionSeleccionada.titulo}</h2>
                            <p>{leccionSeleccionada.contenido}</p>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export  {ViewMaterialTeorico};
