import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Row, Col, Navbar, Nav, Container, Breadcrumb } from 'react-bootstrap';
import { AppContext } from '../Context/index'; // Asegúrate de que la ruta de importación sea correcta
import { obtenerUsuarios } from '../firebase/firebase';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Importa Link y useNavigate de React Router
import './ListadoAlumnos.css'; // Incluye tu CSS para el componente

const defaultProfilePicture = '../img/default-profile.jpg'; // Ruta a la imagen por defecto

const ListadoAlumnos = () => {
  const [alumnos, setAlumnos] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate(); // Hook para navegación
  const location = useLocation(); // Hook para obtener la ruta actual

  

  useEffect(() => {
    const fetchAlumnos = async () => {
      if (!currentUser?.displayName) {
        setError('El nombre del profesor (displayName) no está definido');
        return;
      }

      try {
        setCargando(true);
        const todosLosUsuarios = await obtenerUsuarios();
        const alumnosFiltrados = todosLosUsuarios.filter(usuario => 
          usuario.asignTeacher === currentUser.displayName &&
          (usuario.displayName.toLowerCase().includes(searchQuery.toLowerCase()) || 
           usuario.email.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setAlumnos(alumnosFiltrados);
      } catch (error) {
        console.error("Error al cargar alumnos: ", error);
        setError('No se pudieron cargar los alumnos.');
      } finally {
        setCargando(false);
      }
    };

    fetchAlumnos();
  }, [currentUser, searchQuery]); // Se añade searchQuery a la lista de dependencias

  if (cargando) return <div>Cargando alumnos...</div>;
  if (error) return <div>Error: {error}</div>;

  

  return (
    <div>
      <Navbar bg="dark" variant="dark" className="mb-4">
        <Container>
          <Navbar.Brand>Listado de Alumnos</Navbar.Brand>
          <Nav className="me-auto">
            <Button variant="outline-light" onClick={() => navigate(-1)}>Volver</Button>
          </Nav>
        </Container>
      </Navbar>
      <div className='container'>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item href="/dashboard">Inicio</Breadcrumb.Item>
        <Breadcrumb.Item active>Listado de Alumnos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mb-4">
      <input
  type="text"
  className="form-control"
  placeholder="Buscar por nombre o email"
  value={searchQuery}
  onChange={(e) => {
    console.log("Buscando:", e.target.value);
    setSearchQuery(e.target.value);
  }}
  
  autoComplete="off" // Evita que el navegador intente completar el campo automáticamente
/>
      </div>
      
      {alumnos.length > 0 ? (
        <Row>
          {alumnos.map((alumno) => (
            <Col sm={12} md={6} lg={4} xl={3} key={alumno.id} className="mb-4">
              <Card className="user-card">
                <Card.Img
                  variant="top"
                  src={alumno.profilePicture || defaultProfilePicture}
                  alt={alumno.displayName}
                  className="user-img"
                />
                <Card.Body>
                  <Card.Title>{alumno.displayName}</Card.Title>
                  <Button variant="primary" as={Link} to={`/dashboard/listado-alumnos/detalle-alumno/${alumno.id}`} className="me-2">
                    Ver Ficha
                  </Button>
                  <hr></hr>
                  <Button variant="secondary" as={Link} to={`/dashboard/listado-alumnos/registro-de-practicas/${alumno.id}`}>
                    Ver Registro de Prácticas
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>No hay alumnos para mostrar.</p>
      )}
      </div>
    </div>
  );
};

export { ListadoAlumnos };
