
import { useContext } from 'react'
import { AppContext } from '../../Context'
import { AppRoutes } from './AppRoutes'
import { PublicRoutes } from '../Public'
import { Layout } from '../../components/Layout'
import { AppProvider } from '../../Context'

function App() {
  const { isAuth } = useContext(AppContext)

  return (
    <AppProvider>  
        {isAuth && (
            <Layout>
              <AppRoutes />
            </Layout>
        )}

        {!isAuth && (
          <PublicRoutes />
        )}
    </AppProvider>
  )
}

export { App }
