import React, { createContext, useState, useEffect, useRef } from 'react';
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, collection, query, getDocs, where } from 'firebase/firestore';
import { ref as firebaseRef, listAll, getDownloadURL } from 'firebase/storage'; // Correcto

import { storage } from '../firebase/firebase';

// Crear el contexto
const AppContext = createContext();

// Proveedor del contexto
const AppProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);  // Cambiar a null en lugar de {}
  const [lecciones, setLecciones] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [level, setUserLevel] = useState(null);
  const [teacher, setTeacher] = useState(false);
  const [audios, setAudios] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);


  const auth = getAuth();
  const db = getFirestore();

  // Manejar el estado de autenticación del usuario
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = { uid: user.uid, ...userDoc.data() };
            setCurrentUser(userData);
            setIsAuth(true);
            setUserLevel(userData.level);
            setTeacher(userData.teacher || false);
          } else {
            // Manejar el caso cuando el documento del usuario no existe
            setCurrentUser({ uid: user.uid });
            setIsAuth(true);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setCurrentUser(null);  // Cambiar a null en lugar de {}
        setIsAuth(false);
        setLecciones([]);
        setMaterials([]);
        setEvaluaciones([]);
        setUserLevel(null);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  // Función para cargar datos
  const fetchData = async (collectionName, setter) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setter(items);
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      fetchData('Lecciones', setLecciones);
      fetchData('materials', setMaterials);
    }
  }, [isAuth]);

  const fetchEvaluacionesPorNivel = async (nivel) => {
    const q = query(collection(db, 'evaluaciones'), where("nivel", "==", nivel));
    try {
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvaluaciones(items);
    } catch (error) {
      console.error('Error fetching evaluations:', error);
      setEvaluaciones([]);
    }
  };

  // Función para cerrar sesión
  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

    // Cargar URLs de audios desde Firebase Storage
    useEffect(() => {
      const fetchAudioURLs = async () => {
        const audioList = [];
        const audioFolderRef = firebaseRef(storage, 'music/');
        const { items } = await listAll(audioFolderRef);
        for (let item of items) {
          const url = await getDownloadURL(item);
          audioList.push({ name: item.name, url });
        }
        setAudios(audioList);
      };
  
      fetchAudioURLs();
    }, []);
  
    // Funciones para controlar el audio
    const playNext = () => {
      setCurrentAudioIndex(index => (index + 1) % audios.length);
      setIsPlaying(true);
    };
  
    const playPrev = () => {
      setCurrentAudioIndex(index => index === 0 ? audios.length - 1 : index - 1);
      setIsPlaying(true);
    };
  
    const togglePlayPause = () => {
      setIsPlaying(!isPlaying);
    };

  return (
    <AppContext.Provider value={{
      isAuth, setIsAuth, currentUser, logOut, lecciones, materials, teacher, level, evaluaciones, fetchEvaluacionesPorNivel,  audios, currentAudioIndex, isPlaying, playNext, playPrev, togglePlayPause, audioRef
    }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
