import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse, Row, Col, FormLabel } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';

const M7_Ejercicio7 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [step, setStep] = useState(1); // Paso actual
    const [causa, setCausa] = useState('');
    const [efecto, setEfecto] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    // Datos ejercicio 1
    const [items, setItems] = useState([
        { id: 'a', content: 'Dio a luz hermosa niña' },
        { id: 'b', content: 'Niña pequeña y débil' },
        { id: 'c', content: 'Todos querían ser el primero' },
        { id: 'd', content: 'Se enteró de la triste noticia' },
        { id: 'e', content: 'Sintió miedo' }
    ]);

    const [targets, setTargets] = useState([
        { id: '1', content: '(  ) la jarra se cayó', letter: '' },
        { id: '2', content: '(  ) se escondió', letter: '' },
        { id: '3', content: '(  ) alegría', letter: '' },
        { id: '4', content: '(  ) la bautizaron en casa', letter: '' },
        { id: '5', content: '(  ) decidió ir a buscarlos', letter: '' }
    ]);

    // Datos del ejercicio 2
    const [causes, setCauses] = useState([{ id: 'cause-1', content: 'El padre maldice a los hijos' }]);
    const [effects, setEffects] = useState([
        { id: 'effect-1', content: 'a) Se quedaron sin agua' },
        { id: 'effect-2', content: 'b) Se oyó un trueno poderoso' },
        { id: 'effect-3', content: 'c) Los hijos se convierten en cuervos' }
    ]);

    const  secuencia = [
    {key: 'a', text: 'Padre de impacienca, sale asustado, maldice a los hijos'},
    {key: 'b', text: 'Mete el hueso en la cerradura, oye chirridos, puede entrar.'},
    {key: 'c', text: 'Oye el deseo del pajaro, besa al hermano, vuelven a ser ninos.'},
];

const [blocks, setBlocks] = useState([
    {
        id: 'block-1',
        title: '1. El fútbol es un deporte de:',
        items: [
            { id: 'item-1-1', content: 'a) Niños convertidos en cuervos, padres tristes, maldición del papá.' },
            { id: 'item-1-2', content: 'b) Nina enferma, se rompio la jarra, todos querían ser el primero, padre envio a la fuente a buscar agua' },
            { id: 'item-1-3', content: 'c) Nina oye deseo, cuervo expresa deseo, nina besa hermanos, cuervos vuelven a ser ninos.' }
            
        ]
    }
]);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const sourceClone = Array.from(items);
        const destClone = Array.from(targets);
        const [removed] = sourceClone.splice(source.index, 1);

        const targetIndex = destClone.findIndex(target => target.id === destination.droppableId);
        if (targetIndex !== -1) {
            destClone[targetIndex].letter = removed.id;
        }

        setTargets(destClone);
    };

    const enviarResultados = async () => {
        const compiledResults = targets.reduce((acc, target) => {
            const item = items.find(i => i.id === target.letter);
            if (item) {
                acc[target.content] = item.content;
            }
            return acc;
        }, {});

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 7: Causa y efecto",
                nivel: "Intermedio",
                modulo: "7",
                tipo: "Seleccion simple",
                createdAt: new Date(),
                respuestas: compiledResults
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const advanceStep = () => {
        // Cambio de paso basado en la validación o lógica necesaria
        setStep(step + 1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Aquí podrías agregar la lógica para guardar los datos en la base de datos o manejarlos como necesites
        console.log("Causa:", causa);
        console.log("Efecto:", efecto);
        cerrarModal(); // Cierra el modal después de enviar
    };

    return (
        <Container className="mt-4">
            <h2>  EJERCICIO 7: CAUSA Y EFECTO</h2>
            {step === 1 && (
                <div>

                    <p>
                        Una de las habilidades que nos permiten comprender mejor un texto es, distinguir en una relación de
                        hechos, cuál causa otro. ¿Cuáles hechos son consecuencias de otros? Un hecho es causante cuando
                        constituye la razón por la qué algo sucedió. Y el efecto es el resultado de ésa causa.
                    </p>
                    <p>
                        En una lectura podemos encontrar varias secuencias causa-efecto-causa-efecto; en donde un efecto se
                        convierte en la causa de otro y asi sucesivamente. Diremos, por ejemplo, la lechera caminaba
                        distraídamente (causa) y se cayó (efecto). al caerse (causa) se le rompió el cántaro (efecto), y se le regó la
                        leche (efecto del rompimiento del cantaro), Iloro desconsolada (efecto final de toda la cadena de
                        hechos)
                    </p><p>
                        La habilidad para diferenciar causas de efectos se produce en el nivel de interpretación: donde el lector
                        analiza el papel que juega la composicion de las frases dentro de la lectura. De diferentes maneras.
                        unas frases son complemento de otras.
                    </p><p>
                        En los ejercicios siguientes usted establecerá las relaciones causa-efecto de la lectura de este módulo.
                    </p><p className='text-center'>
                        <strong>Lectura: Los Siete Cuervos</strong>
                    </p>
                    <p>
                        1. Relacione las causas con los efectos, colocando entre el parentesis al lado de cada efecto, la letra que
                        corresponda a la causa.
                    </p>
                    <Button onClick={advanceStep}>Iniciar Ejercicio</Button>
                </div>
            )}
            {step === 2 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row>
                        <Col md={6}>
                            <h5>Causas Posibles:</h5>
                            <Droppable droppableId="itemsDroppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                        <Col md={6}>
                            <h5>Efectos Correspondientes:</h5>
                            {targets.map((target, index) => (
                                <Droppable key={target.id} droppableId={target.id}>
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} style={{ border: '2px dashed gray', minHeight: '50px', marginBottom: '10px', padding: '10px' }}>
                                            ({target.letter ? items.find(item => item.id === target.letter)?.content : '  '}) {target.content}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            ))}
                        </Col>
                    </Row>
                    <Button onClick={() => advanceStep()}>Siguiente Ejercicio</Button>

                </DragDropContext>

            )}
            {step === 3 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row>
                        <Col md={6}>
                            <Droppable droppableId="causesDroppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {causes.map((cause, index) => (
                                            <Draggable key={cause.id} draggableId={cause.id} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {cause.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                        <Col md={6}>
                            {effects.map((effect, index) => (
                                <div key={effect.id} style={{ border: '2px dashed gray', minHeight: '50px', marginBottom: '10px', padding: '10px' }}>
                                    {effect.content}
                                </div>
                            ))}
                        </Col>
                    </Row>
                    <Button onClick={() => advanceStep()}>Siguiente Ejercicio</Button>
                    
                </DragDropContext>
            )}
            {step === 4 && (
  <Container className="mt-4">
  
  <p>Ella dudó al principio de lo que le decían, porque le parecía muy raro</p>
  <Form onSubmit={handleSubmit}>
      <FormGroup className="mb-3">
          <FormLabel for="causaInput">Causa</FormLabel>
          <FormControl
              type="text"
              name="causa"
              id="causaInput"
              placeholder="Escriba la causa aquí..."
              value={causa}
              onChange={e => setCausa(e.target.value)}
              style={{ width: '100%' }}
          />
      </FormGroup>
      <FormGroup className="mb-3">
          <FormLabel for="efectoInput">Efecto</FormLabel>
          <FormControl
              type="text"
              name="efecto"
              id="efectoInput"
              placeholder="Escriba el efecto aquí..."
              value={efecto}
              onChange={e => setEfecto(e.target.value)}
              style={{ width: '100%' }}
          />
      </FormGroup>
      
      <Button onClick={() => advanceStep()}>Siguiente Ejercicio</Button>
  </Form>
</Container>
)}
{step === 5 && (
                <div>
                     <h5>Ejercicio 7</h5>
                     
                    {secuencia.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="criteriosCultura"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button onClick={() => advanceStep()}>Siguiente Ejercicio</Button>
                </div>
             )}
                 {step === 6 && (
                <DragDropContext onDragEnd={onDragEnd}>
                {blocks.map(block => (
                    <div key={block.id}>
                        <h3>{block.title}</h3>
                        <Droppable droppableId={block.id}>
                            {(provided, snapshot) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: "none" }}>
                                    {block.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <li ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        userSelect: "none",
                                                        padding: '5px',
                                                        margin: '0 0 8px 0',
                                                        backgroundColor: snapshot.isDragging ? '#f4f4f4' : '#fff',
                                                        border: '1px solid lightgrey',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {item.content}
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </div>
                ))}
                    <Button onClick={enviarResultados} variant="primary">Finalizar Ejercicio</Button>
            </DragDropContext>
                
                    
                
            )}
           
        </Container>
    );
};

export default M7_Ejercicio7;


