import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';
import '../../../../App.css';

const M4_Ejercicio9 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState('');
    const [respuestaLibre, setRespuestaLibre] = useState(''); 

    const [causa, setCausa] = useState(''); // Estado para la causa
    const [efecto, setEfecto] = useState(''); // Estado para el efecto

    const p1 = [
        { key: 'a', text: 'Habia una vez un gigante que tenia un hermoso jardín.' },
        { key: 'b', text: 'El gigante tenía muy mal genio.' },
        { key: 'c', text: 'Los ninos iban todos los dias al jardín.' },
        { key: 'd', text: 'A menudo lograba controlar su genio.' }
    ];

    const p2 = [
        { key: 'a', text: 'Los ninos se pusieron muy tristes.' },
        { key: 'b', text: 'Aquella primavera sucedió una cosa muy curiosa.' },
        { key: 'c', text: 'El invierno no queria marcarse del jardin del gigante.' },
        { key: 'd', text: 'El suelo y los árboles seguían cubiertos de nieve.' }
    ];

    const p3 = [
        { key: 'a', text: 'El gigante se dio cuenta de que hacia tiempo no oía cantar los pájaros.' },
        { key: 'b', text: 'El gigante sintio deseos de volver a oir las risas de los niños.' },
        { key: 'c', text: 'La primavera tardaba en llegar.' },
        { key: 'd', text: 'Un dia se poso un pajaro en la ventana.' }
    ];

    const p4 = [
        { key: 'a', text: 'El gigante derribó el muro.' },
        { key: 'b', text: 'El aire cálido de la primavera entró en el jardín.' },
        { key: 'c', text: 'Los ninos acudieron felices al ver que el gigante ya no era gruñón.' },
        { key: 'd', text: 'El gigante y los ninos fueron muy buenos amigos y vivieron felices.' }
    ];

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const getTitleForStep = (step) => {
        switch(step) {
            case 3: return "Párrafo 1";
            case 4: return "Párrafo 2";
            case 5: return "Párrafo 3";
            case 6: return "Párrafo 4";
            default: return "Pregunta General";
        }
    }

    const handleNextExercise = async () => {
        const title = getTitleForStep(step);

        if (!selectedOption) {
            Swal.fire({
                icon: 'error',
                title: 'Campos Incompletos',
                text: 'Debe seleccionar una opción antes de continuar.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 9",
                pregunta: title,
                nivel: "Basico",
                modulo: "4",
                tipo: "seleccion simple y texto libre",
                respuesta: selectedOption,
                createdAt: new Date()
            });
            setStep(step + 1);
        } catch (error) {
            console.error("Error al guardar la respuesta: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo guardar la respuesta.',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: getTitleForStep(step),
                nombre: "Ejercicio 9",
                nivel: "Basico",
                modulo: "4",
                tipo: "seleccion simple y texto libre",
                createdAt: new Date(),
                respuestaLibre
            });

            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonText: 'Aceptar'
            });
        }
    };
    
    

    const advanceStep = () => {
        setStep(step + 1);
    };

    return (
        <Container className="mt-4">
            {step === 1 && (
                <div>
                     <h3>Nivel Básico</h3>
      <h3>Módulo 4 - Ejercicio 9</h3>
      
      <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                   
      <p className='text-justify'><strong>Lea  aplicando las técnicas aprendidas hasta ahora y realice el ejercicio solicitado en la siguiente pagina </strong></p>
                    <p className='text-justify'>
                    Había una vez un gigante que tenia un hermoso jardin lleno de árboles y plantas con flores de todos
los colores. Los ninos iban todos los dias al jardin del gigante: se subian a los arboles, corrian, jugaban
al escondite y se divertian mucho. Pero el gigante tenia muy mal genio y era un gruñón de tiempo
completo. A menudo no podia controlar su genio y espantaba a los ninos que jugaban en su hermoso
jardin, hasta que un dia, para que no lo molestaran más, construyo una gran pared alrededor del
jardin.
</p>
<p className='text-justify'> 
Los ninos se pusieron muy tristes, pues no habia otro lugar tan bonito y agradable para jugar como el
jardin del gigante gruñón. Pero aquella primavera sucedió una cosa muy curiosa: mientras en todas
partes la nieve empezaba a derretirse y los pájaros a cantar. en el jardin del gigante gruñón parecia
que el invierno no queria marcharse. El suelo y los árboles seguían cubiertos de nieve: ni una sola flor
nacia en el jardin; ni un solo pajaro se posaba alli para cantar. No habia ardillas ni conejos correteando
por ahi.
</p>
<p className='text-justify'>
El gigante gruñón pensaba  <i>¡Vaya, cuánto tarda en llegar la primavera este año</i>, pero no se
preocupaba demasiado. Un dia, sin embargo, se posó un pájaro en su ventana y emitió agudos trinos.
Entones el gigante se dio cuenta que hacia muchisimo tiempo que no oía cantar a los pájaros. En
realidad, hacia muchisimo tiempo que casi no ola ningún sonido, pues nadie le hablaba debido a su
mal humor y. desde que los niños no jugaban en su jardin, ya no escuchaba sus risas y gritos.
Curiosamente. sintió deseos de volver a oir aquellas risas que tanto le molestaban antes.
</p>
<p className='text-justify'>
Sin pensarlo un segundo, el gigante bajó corriendo a su jardin y empezó a derribar con toda su fuerza
el muro que habia construido. Inmediatamente. el aire cálido de la primavera entró en el jardin y la
nieve empezo a derretirse. Las flores llenaron las ramas de los arboles y de todas las direcciones
acudieron los pajaros y alegraron el jardin con sus trinos. Un grupo de ninos, los mismos que solian
jugar alli, se habían reunido a cierta distancia para ver qué pasaba, y entonces el gigante gruñón los
llamo: Vengan, vengan todos a jugar al jardin! Los ninos acudieron felices al ver que el gigante ya no
era el mismo grunon de antes y se pusieron a jugar con el, que reia y saltaba como un nino mas en su
jardin en el que acababa de entrar. por fin y para siempre. la primavera. Desde entonces, el gigante y
los ninos fueron muy buenos amigos y vivieron felices y se comieron a las perdices que se pararon en
su jardín.
                    </p>
                    <Button variant="primary" onClick={handleNextStep}>Comenzar ejercicio</Button>
                </div>
            )}
            {step === 2 && (
                <div>
                    <h5>Ejercicio 9: Narre el argumento</h5>
                    <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                    <p className='text-justify'>Usted ya habrá notado que el método de análisis de la lectura es progresivo y acumulativo. Primero
se extraen las palabras clave y una vez extraidas, le sirven para establecer la idea principal de cada
párrafo y una vez se tienen las ideas principales, si éstas han sido bien establecidas, será fácil armar el
argumento uniendo las ideas principales y complementándolas con algunos detalles o ideas, si es
necesario.
</p>
<p className='text-justify'>
El argumento es el eje central de una historia, generalmente tiene un principio, donde se plantean las
situaciones y los personajes: un desarrollo, donde los personajes comienzan a desarrollar las acciones:
un climax. donde los hechos llegan a su punto más importante o de mayor desarrollo: un descenso.
donde se resuelve el punto maximo planteado; y un final, donde se concluyen todos los aspectos
planteados. En el contexto que nos narran una historia tambien se puede observar un argumento,
una primera parte donde se plantean los hechos o ideas, luego estas ideas son desarrolladas o
complementadas -argumentadas- para luego proceder a sacar conclusiones. Empecemos entonces
por extraer las ideas principales de cada parrafo en la lectura de este modulo.</p>
                    
                        <Button variant="primary" onClick={handleNextStep}>Comenzar ejercicio parrafo 1</Button>
                    
                </div>
            )}
            {step === 3 && (
                <div>
                    <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                    <p><strong>Párrafo 1</strong></p>
                    {p1.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="criteriosCultura"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
             
            {step === 4 && (
                <div>
                     <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                    <p><strong>Párrafo 2</strong></p>
                    {p2.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="criteriosCultura"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            
            {step === 5 && (
                <div>
                     <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                    <p><strong>Párrafo 3</strong></p>
                    {p3.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="criteriosCultura"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
             )}
             {step === 6 && (
                 <div>
                      <h5>Lectura: EL GIGANTE GRUÑÓN</h5>
                    <p><strong>Párrafo 4</strong></p>
                     {p4.map(option => (
                         <div key={option.key}>
                             <label>
                                 <input
                                     type="radio"
                                     name="criteriosCultura"
                                     checked={selectedOption === option.key}
                                     onChange={() => handleOptionChange(option.key)}
                                 />
                                 {option.text}
                             </label>
                         </div>
                     ))}
                     <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                 </div>
             )}
             {step === 7 && (
                 <div>
                    <Form>
                <FormGroup className="mb-3">
                    <p>Ahora, uniendo las ideas principales de cada parrafo, haga una narracion de la lectura en el siguiente campo</p>
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button className='mt-4' variant="primary" onClick={enviarResultados}>Enviar Respuesta</Button>
            </Form>
                 </div>
             )}
             
             </Container>
    );
};

export default M4_Ejercicio9;
