import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../../firebase/firebase'; // Asegúrate de que esta es la ruta correcta
import { SkipBack, SkipForward, Play, Pause, Volume2 } from 'lucide-react';
import AudioPlayer from 'react-audio-player';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import './EstimulacionNeuronal.css';

const EstimulacionNeuronal = () => {
    // const [audios, setAudios] = useState([]);
    // const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    // const [loading, setLoading] = useState(true);

    // // Función para cargar URLs de audio de Firebase Storage
    // const fetchAudioURLs = async () => {
    //     const audioList = [];
    //     const audioFolderRef = ref(storage, 'music/'); // Cambiar 'musica/' por tu carpeta específica
    //     const { items } = await listAll(audioFolderRef);

    //     for (let item of items) {
    //         const url = await getDownloadURL(item);
    //         audioList.push({
    //             name: item.name,
    //             url: url
    //         });
    //     }
    //     setAudios(audioList);
    //     setLoading(false);
    // };

    // // Cargar audios al montar el componente
    // useEffect(() => {
    //     fetchAudioURLs();
    // }, []);

    // // Funciones para controlar el audio
    // const playNext = () => {
    //     setCurrentAudioIndex((prevIndex) => (prevIndex + 1) % audios.length);
    // };

    // const playPrev = () => {
    //     setCurrentAudioIndex((prevIndex) => {
    //         return prevIndex === 0 ? audios.length - 1 : prevIndex - 1;
    //     });
    // };

    // if (loading) {
    //     return <div>Cargando audios...</div>;
    // }

    const [audios, setAudios] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchAudioURLs = async () => {
      const audioList = [];
      const audioFolderRef = ref(storage, 'music/');
      const { items } = await listAll(audioFolderRef);

      for (let item of items) {
        const url = await getDownloadURL(item);
        audioList.push({
          name: item.name,
          url: url
        });
      }
      setAudios(audioList);
    };

    fetchAudioURLs();
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, currentAudioIndex]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const playNext = () => {
    setCurrentAudioIndex((prevIndex) => (prevIndex + 1) % audios.length);
  };

  const playPrev = () => {
    setCurrentAudioIndex((prevIndex) => (prevIndex === 0 ? audios.length - 1 : prevIndex - 1));
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const onLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (audios.length === 0) {
    return <div>Cargando audios...</div>;
  }

    return (
        <div className="audio-player">
        <div className="audio-info">
          <h3>{audios[currentAudioIndex].name}</h3>
          <Volume2 size={16} />
        </div>
        <div className="progress-bar">
          <div 
            className="progress" 
            style={{width: `${(currentTime / duration) * 100}%`}}
          ></div>
        </div>
        <div className="time-info">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
        <div className="controls">
          <button onClick={playPrev}><SkipBack size={24} /></button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button onClick={playNext}><SkipForward size={24} /></button>
        </div>
        <audio
          ref={audioRef}
          src={audios[currentAudioIndex].url}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={onLoadedMetadata}
          onEnded={playNext}
        />
      </div>
    );
};

export default EstimulacionNeuronal;
