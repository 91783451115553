import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Container, Button } from 'react-bootstrap';

const DetalleEvaluacion = () => {
  const { id } = useParams();
  const [evaluacion, setEvaluacion] = useState(null);

  useEffect(() => {
    const fetchEvaluacion = async () => {
      const docRef = doc(db, 'evaluaciones', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEvaluacion(docSnap.data());
      } else {
        console.log('No se encontró la evaluación');
      }
    };

    fetchEvaluacion();
  }, [id]);

  return (
    <Container>
      {evaluacion ? (
        <div>
          <h1>{evaluacion.nombre}</h1>
          <p>{evaluacion.descripcion}</p>
          {/* Mostrar más detalles de la evaluación si es necesario */}
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </Container>
  );
};

export  {DetalleEvaluacion};
