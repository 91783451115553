import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';

const M5_Ejercicio7 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [step, setStep] = useState(0);

    const [blocks, setBlocks] = useState([

       
        {
            id: 'block-1',
            title: '1. El fútbol es un deporte de:',
            items: [
                { id: 'item-1-1', content: 'a) Creación reciente' },
                { id: 'item-1-2', content: 'b) Edad media' },
                { id: 'item-1-3', content: 'c) Tiene 24 siglos' },
                { id: 'item-1-4', content: 'd) Tiene 21 siglos' }
            ]
        },
        {
            id: 'block-2',
            title: '2. Organice en orden cronológico:',
            items: [
                { id: 'item-2-1', content: 'a) En Ámsterdam se decidió organizar un campeonato internacional' },
                { id: 'item-2-2', content: 'b) Fu-Hi inventó la pelota' },
                { id: 'item-2-3', content: 'c) Se crearon nuevas reglas y el fútbol se separó del rugby' },
                { id: 'item-2-4', content: 'd) Nació la idea de la copa del mundo' }
            ]
        },
        {
            id: 'block-3',
            title: '3. El mundial de fútbol es un campeonato que se realizó:',
            items: [
                { id: 'item-3-1', content: 'a) En este siglo' },
                { id: 'item-3-2', content: 'b) En el siglo pasado' },
                { id: 'item-3-3', content: 'c) En el siglo XIX' },
                { id: 'item-3-4', content: 'd) En el 2002' }
            ]
        },
        {
            id: 'block-4',
            title: '4. Establezca cuál es la secuencia adecuada:',
            items: [
                { id: 'item-4-1', content: 'a) Mundial de Corea-Japón' },
                { id: 'item-4-2', content: 'b) Mundial de Suecia' },
                { id: 'item-4-3', content: 'c) Mundial de Uruguay' },
                { id: 'item-4-4', content: 'd) Inclusión en los estatutos de la federación de un artículo que ordena organizar un campeonato' }
            ]
        }
    ]);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return; // No hace nada si el item no se deja en un destino válido
        }

        if (source.droppableId !== destination.droppableId) {
            return; // No hace nada si el item se intenta mover entre listas diferentes
        }

        const block = blocks.find(b => b.id === source.droppableId);
        const copiedItems = [...block.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        setBlocks(blocks.map(b => {
            if (b.id === block.id) {
                return {
                    ...b,
                    items: copiedItems
                };
            }
            return b;
        }));
    };

    const enviarResultados = async () => {
        const orden = blocks.map(block => ({
            blockId: block.id,
            title: block.title,
            items: block.items.map(item => item.content)
        }));

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 7: Ordene cronológicamente",
                nivel: "Basico",
                modulo: "5",
                tipo: "Orden de Respuestas",
                createdAt: new Date(),
                orden: orden
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    if (step === 0) {
        return (
            <Container className="mt-4">
                <h3>Nivel Básico</h3>
             <h3>Módulo 5 - Ejercicio 7</h3>
            <h5>Ejercicio de comprensión</h5>
            
            <h4>Lectura 1: La Historia del futbol</h4>
            <p className='text-justify'>
            El fútbol es el deporte más popular en todo el mundo. Fue jugado por primera vez en Egipto, como
parte de un rito por la fertilidad, durante el Siglo IlI antes de Cristo. La pelota de cuero fue inventada
por los chinos en el Siglo VI antes de Cristo. Los chinos rellenaban estas pelotas con las cerdas de los
mismos animales. Esto surgio, cuando uno de los cinco grandes gobernantes de China en la
antigüedad. Fu-Hi, apasionado inventor, apelmazó varias raíces duras hasta formar una masa esférica
a la que recubrio con pedazos de cuero crudo. Acababa de inventar la pelota. Lo primero que se hizo
con ella fue sencillamente jugar a pasarla de mano en mano.
            </p>
            <p className='text-justify'>
            En la Edad Media hubo muchos caballeros obsesionados por los juegos con pelota, entre ellos
Ricardo Corazón de León, quien llegó a proponer al caudillo musulmán Saladino, que dirimieran sus
cuestiones sobre la propiedad de Jerusalen con un partido de pelota. La respuesta fue no. Los
Hindúes, los persas y los egipcios adoptaron este elemento para sus juegos, utilizándolo en una
especie de handball que llego tímidamente a Europa. Siglos después, en Cambridge, un grupo de
estudiantes unificó las distintas reglas que se utilizaban en la región. En 1863, se crearon nuevas reglas
y el fútbol se separó definitivamente del rugby. El 21 de mayo de 1904 se fundó la FIFA (Federación
Internacional de Fútbol Asociado) y por primera vez se establecieron reglas mundiales.
            </p>
            <p className='text-justify'>
            El fútbol se juega con balón de cuero sobre una superficie de césped de 45 por 100 metros,
aproximadamente. Cada equipo cuenta con once jugadores que desempeñan diferentes papeles.
Hay un portero, defensas, medio campistas y delanteros. Pero la evolución del deporte ha producido
diferentes esquemas tacticos, por lo que el número de jugadores en cada posicion ha variado. Lo
único que no ha cambiado es el número de porteros: uno. Y es él quien desempeña la tarea más
dificil: evitar que los contrincantes introduzcan el balon en el arco. De eso se trata el fútbol, de meter
goles. Y el equipo ganador es precisamente el que haga más goles en los 90 minutos reglamentarios.
            </p>
            <p className='text-justify'>
            En el fútbol se pueden hacer cambios de jugadores, pero una vez un jugador sale del campo de
juego no puede entrar. Existen dos tipos de faltas: cuando un jugador que no sea el portero toca el
balon con la mano o cuando agrede a un contrincante. Dependiendo de la severidad de la falta, el
árbitro puede sacarle una tarjeta amarilla o roja, esta última significa que el jugador es expulsado y
deja a su equipo con un jugador menos. Dos tarjetas amarillas equivalen a una roja.  
            </p>
            <p className='text-justify'>
            La copa del Mundo nacio como idea el 21 de Mayo de 1904 en parís, fecha en que se fundó la
Federación Internacional de Fútbol Asociado (FIFA), el organismo rector de este deporte, con la
presencia de siete asociaciones europeas de fútbol: Francia, Dinamarca, Países Bajos, España, Suecia y
Suiza. El representante de la asociación francesa de fútbol, Robert Guérin, fue quien propuso que en
los estatus de la flamante Federación se registrara un artículo, especificando la obligación de
organizar periodicamente un torneo internacional abierto a todas las entidades nacionales afiliadas.
            </p>
            <p className='text-justify'>
            Años mas tarde, el francés Jules Rimet tuvo la intención de concretar el tan ansiado campeonato, ya
que entendía que el fútbol podía fortalecer los ideales de una paz permanente y verdadera, al unir a
varias naciones en pos de un objetivo común. También pretendía con este campeonato desplazara el
centro de atención de este deporte de Europa, ya que consideraba que el nivel del juego fuera del
viejo continente era suficiente para llevar a cabo una competición de este tipo.
            </p>
            <p className='text-justify'>
            El proyecto nacio ademas de un desacuerdo entre la FIFA y el Comité Olímpico Internacional, a raíz
del Torneo Olímpico de fútbol. La FIFA, y Jules Rimet específicamente, insistían en que era suficiente
competentes para asumir la responsabilidad total del Torneo; de otro modo, organizarían uno propio.
Por otra parte, los Juegos Olímpicos eran el único evento de aquella época que tenía un torneo en el
cual podían participar todas las asociaciones nacionales de fútbol del mundo. Rimet quería que el
Campeonato Mundial tuviese el mismo carácter universal, pero al mismo tiempo quería que también
estuviese abierto a jugadores profesionales.
            </p>
            <p className='text-justify'>
            Finalmente, en el congreso de la FIFA de 1928 en Ámsterdam. Holanda, se decidió organizar dos años
mas tarde un "Campeonato Internacional". En sucesivos congresos de la FIFA, se fijaron los
lineamientos generales de la competencia. Fundamentalmente, se decidió que la competición se
desarrollaria por el sistema de eliminación, esto es, de copa, y que se celebraría cada cuatro años, en
países o regiones diferentes. En 1930, se realizó el Primer Campeonato Mundial de Fútbol en Uruguay.
La Selección de Uruguay fue el primer campeón mundial de fútbol, tras vencer a la selección de
Argentina 4 goles a 2.
            </p>
            <p className='text-justify'>
            De los datos más resaltantes de la historia de la Copa Mundial tenemos a Brasil como país que ha
estado presente en todas las fases finales del Mundial desde 1930. Entre personajes destacados esta
Fontaine, frances quien tiene el récord de más goles marcados durante fase final, obteniendo en el
mundial de Suecia en 1958. Y Edson Arantes do Nacimiento, brasileno, conocido en todo el mundo
como Pele: el jugador que ha participado y ganado en tres Copas Mundiales, 1958, 1962 y 1970. De
igual forma, es el mayor goleador del mundo con 1300 goles, de los cuales 109 fueron anotados en
partidos internacionales con la selección brasileña. Brasil, al ser campeón del Mundial de Fútbol
Corea y Japon 2002, se convirtio en el primer y unico penta-campeon del mundo, tras vencer 2-0 a
Alemania en la final.
            </p>
                <Button variant="primary" onClick={() => setStep(1)}>Comenzar Ejercicio</Button>
            </Container>
        );
    }


    return (
        <Container className="mt-4">
            <h3>Nivel Básico</h3>
            <h3>Módulo 5 - Ejercicio 9</h3>
            <h5>Ordene cronológicamente</h5>

            
            <DragDropContext onDragEnd={onDragEnd}>
                {blocks.map(block => (
                    <div key={block.id}>
                        <h3>{block.title}</h3>
                        <Droppable droppableId={block.id}>
                            {(provided, snapshot) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: "none" }}>
                                    {block.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <li ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        userSelect: "none",
                                                        padding: '5px',
                                                        margin: '0 0 8px 0',
                                                        backgroundColor: snapshot.isDragging ? '#f4f4f4' : '#fff',
                                                        border: '1px solid lightgrey',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {item.content}
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </div>
                ))}
            </DragDropContext>
            <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
        </Container>
    );
};



export default M5_Ejercicio7;
