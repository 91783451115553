import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import styles from './Ejercicio10.module.css';
import Swal from 'sweetalert2';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';

const Ejercicio10 = ({ cerrarModal }) => {
  const { currentUser } = useContext(AppContext);
  const [respuestas, setRespuestas] = useState({
    Geopolítica: '',
    Geografía: '',
    geometria: '',
    geologia: '',
    heterogeneo: '',
    heterodoxo: '',
    heteromorfo: '',
    hipoglicemia: '',
    hipotesis: 'Menos que una tesis', // Completado por defecto
    hipotalamo: '',
    hipogeo: '',
    hipodromo: '',
    hipica: '',
    homeopatia: 'Curación mediante sustancias semejantes a lo que se quiere curar', // Completado por defecto
    homosexual: '',
    homófono: '',
    homogeneo: '',
    hipertrofia: '',
    hipermercado: '',
    hipermetropía: '',
    Heliotropo: '',
    Helioterapia: '',
    Hemiciclo: '',
    Hemisferio: '',
    Hemiplejia: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRespuestas(prev => ({ ...prev, [name]: value }));
  };

  const enviarResultados = async () => {
    const emptyFields = Object.entries(respuestas).filter(([key, value]) => !value.trim());
    if (emptyFields.length > 0) {
      console.log("Campos vacíos:", emptyFields);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    try {
      await addDoc(collection(db, "evaluaciones"), {
        userId: currentUser.uid,
        nombre: "Módulo 3 - Ejercicio de Comprensión",
        nivel: "Basico",
        modulo: "3",
        tipo: "Texto libre",
        createdAt: new Date(),
        respuestas
      });
      Swal.fire({
        title: '¡Evaluación Enviada!',
        text: 'Resultados enviados con éxito.',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Excelente'
      }).then((result) => {
        if (result.isConfirmed) {
          cerrarModal(); // Cierra la modal al confirmar la alerta
        }
      });
    } catch (error) {
      console.error("Error al enviar las respuestas: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al enviar las respuestas.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className={styles.container}>
      <h3>Nivel Básico</h3>
      <h3>Módulo 3 - Ejercicio 10</h3>
      <h5>Ejercicio de Comprensión</h5>

      <p><strong>Incremente su Vocabulario</strong></p>
      <Row>
      <Col md={6}>
      
        
      <Form className='border p-3'>
            {/* Contenido de la primera columna */}
            <FormGroup className="mb-3">
            <p>Raíces Griegas</p>
            {/* Ge, genos = tierra */}
              <p><strong>Raíz: Ge, genos = tierra</strong></p>
              <p>Apogeo: Punto en que la luna se encuentra a mayor distancia de la tierra. Apo, significa separación, contra, fuera de.</p>
              
              {["Geopolítica", "Geografía", "Geometría", "Geología"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              
              </FormGroup>
                                          

                                </Form>
                                <br />
                                <Form className='border p-3'>
                                <FormGroup className="mb-3">
              {/* Raíz: genos, genea:*/}
              <p><strong>Raíz: genos, genea: linaje </strong>= origen, génesis, nacimiento.</p>
              <p>Genética: Ciencia que estudia la transmisión de genes.</p>
              <p>Genealogía: Estudio de los orígenes.</p>
              <p>Heterogéneo: diferente gen u origen.</p>
              {["homogeneo"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}

          </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">
                                  
              {/* Raíz Haima = sangre */}
              <p><strong>Raíz: Haima = sangre</strong></p>
              <p>Hemorragia: Pérdida de sangre</p>
              <p>Hemática: Estudio de la sangre</p>
              <p>Hematocitos: Células sanguíneas</p>
              <p>Hemoglobina: Proteína de la sangre</p>
              {/* Raíz: helios = sol */}
              <p><strong>Raíz: helios = sol</strong></p>
              <p>Heliofísica: Ciencia que estudia la constitución del sol</p>
              <p>Heliostato: Aparato para reflejar los rayos del sol en un punto específico.</p>

              {["Heliotropo", "Helioterapia"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">
              {/* Raíz: hemi = medio */}
              <p><strong>Raíz: hemi = medio</strong></p>
              <p>Ejemplos:</p>

              {["Hemiciclo", "Hemisferio", "Hemiplejia"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />

                </div>
              ))}
              </FormGroup>
              </Form>
              <br />


            
          
            </Col>
            <Col md={6}>
            
            <Form className='border p-3'>
                 <FormGroup className="mb-3">
              {/* Contenido de la segunda columna */}
              

              <p>Hemistiquio: Parte de un verso cortada por una pausa</p>


              <p><strong>Raíz: Heteros = diferente</strong></p>

              {["heterogeneo", "heterodoxo", "heteromorfo"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
               </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">

              {/* Raíz: Hypos: bajo, inferior */}
              <p><strong>Raíz: Hypos: bajo, inferior</strong></p>

              {["hipoglicemia", "hipotesis", "hipotalamo", "hipogeo"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">

              {/* Raíz: hipos = cabello */}
              <p><strong>Raíz: hipos = cabello</strong></p>

              {["hipodromo", "hipica"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">

              {/* Raíz: homoios = igual */}
              <p><strong>Raíz: homoios = igual</strong></p>
              <p>Homeopatía: curación mediante sustancias semejantes a lo que se quiere curar</p>
              {["homologo", "homosexual", "homófono", "homogéneo"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              </FormGroup>
              </Form>
              <br />
              <Form className='border p-3'>
                 <FormGroup className="mb-3">


              <p><strong>Raíz: hiper: sobre, mucho</strong></p>
              <p>Hiperestesia: Mucha sensibilidad</p>
              {["hipertrofia", "hipermercado", "hipermetropía"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </div>
              ))}
              </FormGroup>
              </Form>
              <br />
            
          
        
        </Col>
        
        <button onClick={enviarResultados} className="btn btn-primary">Enviar Resultados</button>
        </Row>
      </div>
      );
};

export default Ejercicio10;

