import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { existsUsername, updateUser } from "../firebase/firebase";

function ChooseUsernameView(){
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const  [currentUser, setCurrentUser] = useState({});
    const  [username, setUsername] = useState("");

    function handdleInputUsername(e){
        setUsername(e.target.value);
    }

    async function handdleContinue(){
        if(username != ""){
            const exists = await existsUsername(username);
            if (exists){
                setState(5);
            } else {
                const tpm = {... currentUser};
                tpm.username = username;
                tpm.processCompleted = true;
                await updateUser(tpm);
                setState(6);

            }
        }
    }


    if (state === 3 || state === 5) {
        return( <div>
            <h1> Bienvenido {currentUser?.displayName} </h1>
            <p>Para terminar el proceso elige un nombre de usuario</p>
            {state === 5? <p>El nombre de usuario ya existe, escoge otro</p> : ""}
        
        <div>
            <input type="text" onChange={handdleInputUsername} />
        </div>

        <div>
            <button onClick={handdleContinue}>Continue</button>
        </div>
        
        </div>
      
        );
    }   

    if (state === 6) {
        return ( 
        <div>
            <h1>Felicidades, ya puedes ir al dashboard a crear tus links</h1>
           <NavLink to="/dashboard">
            Continuar
            </NavLink> 
            </div>
        );
    }

    return (
     <>
        Lorem ipsum dolor sit amet.
     </>
        );
}

export { ChooseUsernameView };