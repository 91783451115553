import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';
import '../../../../App.css';


function M1_Ejercicio10({ cerrarModal }) {
    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        arquitecto: '',
        arquidiocesis: '',
        arzobispo: '',
        arcangel: '',
        archiduque: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };

    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Módulo Básico - Ejercicio de Comprensión",
                nivel: "Basico",
                modulo: "1",
                tipo: "Texto libre",
                createdAt: new Date(),
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Llamando cerrarModal correctamente
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
             <h3>Nivel Básico</h3>
      <h3>Módulo 1 - Ejercicio 10: Incremente su vocabulario</h3>
      <h5>Ejercicio de comprensión</h5>
           
      <p className='text-justify'>

            El primer obstáculo para entender un texto es el vocabulario. De hecho, cuando leemos en otro
idioma, entendemos tanto como palabras conozcamos de éste. De igual manera sucede con textos
sobre temas muy técnicos o desconocidos. Cuando el lector encuentra palabras cuyo significado no
le es familiar, es normal que entender le parezca un trabajo dificil.
            </p>
                <p className='text-justify'>
Conocer el significado de las palabras es el primer paso para una buena comprensión. Por una
parte, toda palabra tiene una connotación adicional subjetiva. Es decir, cuando a usted le dicen,
incendio, el significado objetivo de la palabra, según el diccionario, es: <strong>fuego grande que abrasa lo
que no está destinado a arder como edificios, cultivos, etc.</strong> Sin embargo, si usted vivió de cerca la
experiencia de un incendio, probablemente la connotación que le dará será muy diferente.
Por otro lado, en algunas ocasiones interpretamos el significado de las palabras relacionándolo con
alguna experiencia o conocimiento anterior: no obstante, la inferencia puede ser equivocada o
imprecisa y altera radicalmente el sentido de la frase.</p>

    <p className='text-justify'>
Esto no significa que nos olvidemos de inferir. Por el contrario, cuando encuentre palabras sobre las
que no conoce el significado, no se detenga: trate de deducir el significado con base en lo que
conoce, o en la información que el mismo texto le proporcione. Según el diccionario inferir significa:
Sacar consecuencias o deducir una cosa de otra.</p>

    <p className='text-justify'>
Nuestro idioma ha estado influenciado por otras lenguas, especialmente por el latín y el griego. De
allí que una forma práctica para inferir significados es conocer el sentido de las raíces griegas y
latinas, de las cuales se derivan gran cantidad de palabras. En cada módulo conoceremos el
significado de algunas raíces griegas o latinas; en este primer módulo los hará con el material
impreso, el resto se encontrarán en los ejercicios digitales. Empecemos con las griegas:</p>
            <h3>Raíz: archi - aquí</h3>
            <p>Significado: primero, denota preeminencia</p>
            <p>Ejemplos:</p>
            <ListGroup>
                <ListGroupItem><strong>Arquetipo: Primer modelo de un arte o una cosa. </strong></ListGroupItem>
                <ListGroupItem><strong>Arquitectura: Primera estructura. </strong></ListGroupItem>
                <ListGroupItem><strong>Arqueología: Ciencia que estudia la primera historia, la más antigua.</strong></ListGroupItem>
                <ListGroupItem><strong>Archimillonario: El más rico. </strong></ListGroupItem>
            </ListGroup>
            
            <Form>
                <p>Ahora, deduzca usted las siguientes:</p>
                {['arquitecto', 'arquidiocesis', 'arzobispo', 'arcangel', 'archiduque'].map(campo => (
                    <Form.Group className="mb-3" key={campo}>
                        <Form.Label>{campo.charAt(0).toUpperCase() + campo.slice(1)}</Form.Label>
                        <Form.Control
                            type="text"
                            name={campo}
                            value={respuestas[campo]}
                            onChange={handleChange}
                            placeholder={`Respuesta a ${campo.charAt(0).toUpperCase() + campo.slice(1)}`}
                        />
                    </Form.Group>
                ))}
                                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>

            </Form>
        </Container>
    );
}

export { M1_Ejercicio10 };
