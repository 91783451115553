import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';
import '../../../../App.css';

const M5_Ejercicio8 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 8: Infiera significados por contexto",
                nivel: "Basico",
                modulo: "5",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h3>Nivel Básico</h3>
            <h3>Módulo 5 - Ejercicio 8</h3>
            <h5>Infiera significado por contexto</h5>
            
            
            <p className='text-justify'>
           <em> A pesar de lo sucias que estaban. Polo logró <strong>bruñir</strong>  las botas del Libertador </em> </p>
          <p className='text-justify'> 
Algunas palabras o expresiones como "a pesar", "por el contrario", "en oposición a " sugieren que el significado es el opuesto a una situación dada.
            </p>
            

            <Form>
                <FormGroup className="mb-3">
                    Bruñir:
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};

export default M5_Ejercicio8;
