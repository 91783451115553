import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M8_Ejercicio9 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        // Raíz: physis = naturaleza
        
        
        cabestro: '',
        cabestrillo: '',
        capitulo: '',
        capitel: '',
        decapitar: '',
        capataz: '',
        caotico: '',
        caducar: '',
        caos: '',
        cariño: '',
        querido: '',
        querer: '',
        artefacto: '',
        ipsofacto: '',
        fabrica: '',
        fabricar: '',

    
        

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Comprensión - Ejercicio 9",
                nivel: "Intermedio",
                modulo: "8",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h2>Ejercicio de Comprensión</h2>
            <p><strong>Ejercicio 9: Raíces Latinas</strong></p>


            <Row>
                <Col md={6}>

                    <Form className='border p-3'>

                        <p><strong>Raíz: cápitis = parte más alta del cuerpo, cabeza</strong></p>
                        <p>Per cápita: por cabeza <br></br>
                        Capota: gorro para la cabeza <br></br>
                        Capitolio: edificio cabeza, principal <br></br>
                        Capitón: cabezón</p>



                        <Form>
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="cabestro"
                                    value={respuestas.cabestro}
                                    onChange={handleChange}
                                    placeholder="Cabestro"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="cabestrillo"
                                    value={respuestas.cabestrillo}
                                    onChange={handleChange}
                                    placeholder="Cabestrillo"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="capitulo"
                                    value={respuestas.capitulo}
                                    onChange={handleChange}
                                    placeholder="Capítulo"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="capitel"
                                    value={respuestas.capitel}
                                    onChange={handleChange}
                                    placeholder="Capitel"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="decapitar"
                                    value={respuestas.decapitar}
                                    onChange={handleChange}
                                    placeholder="Decapitar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="capataz"
                                    value={respuestas.capataz}
                                    onChange={handleChange}
                                    placeholder="Capataz"
                                />
                            </FormGroup>
                        </Form>

                        <br />

                        <Form className='border p-3'>




                            <p><strong>Raíz: cado = caer, caerse</strong></p>
                            <p>Caesum = que cae, derribar, tumbar, herir a muerte</p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="caotico"
                                    value={respuestas.caotico}
                                    onChange={handleChange}
                                    placeholder="Caótico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="caducar"
                                    value={respuestas.caducar}
                                    onChange={handleChange}
                                    placeholder="Caducar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="caos"
                                    value={respuestas.caos}
                                    onChange={handleChange}
                                    placeholder="Caos"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                      
                         </Form>
                </Col>
                <Col md={6}>
                    <Form>
                       
                    <Form className='border p-3'>

<p><strong>Raíz: Campus = campo, llano, llanura</strong></p>
<p>Campesino, acampar, campo, <br />
campaña, campestre <br />
Campaña: Expedición con un propósito, originalmente al campo. <br />
Campañol: ratón de campo </p>
</Form>
<br />


<Form className='border p-3'>

<p><strong>Raíz: caritas = carestía, precio, efecto, ternura.</strong></p>

<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="cariño"
        value={respuestas.cariño}
        onChange={handleChange}
        placeholder="Cariño"
    />
</FormGroup>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="querido"
        value={respuestas.querido}
        onChange={handleChange}
        placeholder="Querido"
    />
</FormGroup>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="querer"
        value={respuestas.querer}
        onChange={handleChange}
        placeholder="Querer"
    />
</FormGroup>

</Form>
<br />

<Form className='border p-3'>

<p><strong>Raíz: facto, Facio, Factum = hacer, hecho, construir, hechura.</strong></p>


<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="artefacto"
        value={respuestas.artefacto}
        onChange={handleChange}
        placeholder="Artefacto"
    />
</FormGroup>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="ipsofacto"
        value={respuestas.ipsofacto}
        onChange={handleChange}
        placeholder="Ipso facto"
    />
</FormGroup>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="fabrica"
        value={respuestas.fabrica}
        onChange={handleChange}
        placeholder="Fábrica"
    />
</FormGroup>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="fabricar"
        value={respuestas.fabricar}
        onChange={handleChange}
        placeholder="Fabricar"
    />
    <p>Factito: hacer con frecuencia, hábito.</p>
</FormGroup>

</Form>   




                    </Form>
                </Col>
                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M8_Ejercicio9;
