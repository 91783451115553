import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, getDoc, doc, getDocs, setDoc, where, updateDoc   } from "firebase/firestore"; // Añadir setDoc
import { getAuth } from "firebase/auth";
import './ChatComponent.css';
import { FaComments } from "react-icons/fa"; // Icono de chat
import { Button } from 'react-bootstrap'; // Importar el botón de Bootstrap

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false); // Estado para abrir/cerrar el chat
  const [validUsers, setValidUsers] = useState([]);
  const [selectedChatUser, setSelectedChatUser] = useState(null); // Usuario con quien se está chateando
  const db = getFirestore();
  const auth = getAuth();
  const [conversations, setConversations] = useState([]);
const [activeConversation, setActiveConversation] = useState(null);


  // Función para abrir/cerrar la ventana de chat
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  useEffect(() => {
    const fetchConversations = async () => {
      const { uid } = auth.currentUser;
  
      // Consulta las conversaciones donde el usuario es participante
      const q = query(
        collection(db, "conversations"),
        where("participants", "array-contains", uid),
        orderBy("timestamp", "desc")
      );
  
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setConversations(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });
  
      return () => unsubscribe();
    };
  
    fetchConversations();
  }, [auth, db]);

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const getValidContacts = async () => {
      const { uid } = auth.currentUser; // Obtener el UID del usuario autenticado
      const userDoc = await getDoc(doc(db, "users", uid)); // Obtener el documento del usuario actual
  
      if (userDoc.exists()) {
        const userData = userDoc.data(); // Obtener los datos del usuario
        const usersSnapshot = await getDocs(collection(db, "users")); // Obtener todos los usuarios
        const allUsers = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        let validContacts = [];
  
        // Regla 1: Si el usuario es Master, puede hablar con todos los profesores (teacher: true)
        if (userData.isMaster) {
          validContacts = allUsers.filter(
            (user) => user.teacher === true && user.id !== uid // Mostrar solo usuarios con teacher: true
          );
        }
  
        // Regla 2: Si es un usuario con teacher: true
        else if (userData.teacher) {
          validContacts = allUsers.filter(
            (user) => 
              user.isMaster === true || // Mostrar usuarios con isMaster: true
              user.teacherId === uid     // Mostrar estudiantes cuyo teacherId es el UID del profesor
          );
        }
  
        // Regla 3: Si es un alumno, puede hablar solo con su profesor asignado (teacherId)
        else if (userData.teacherId) {
          const assignedTeacher = allUsers.find(
            (user) => user.id === userData.teacherId
          );
          if (assignedTeacher) {
            validContacts = [assignedTeacher]; // Solo mostrar su profesor asignado
          }
        }
  
        setContacts(validContacts); // Actualizar la lista de contactos válidos
      }
    };
  
    getValidContacts(); // Ejecutar la función para obtener los contactos válidos
  }, [auth, db]);
  
  
  

const startConversation = async (contact) => {
    const { uid } = auth.currentUser;
  
    // Crear un ID único para la conversación (puede ser combinación de UIDs)
    const conversationId =
      uid > contact.id ? `${uid}_${contact.id}` : `${contact.id}_${uid}`;
  
    // Verificar si la conversación ya existe
    const conversationRef = doc(db, "conversations", conversationId);
    const conversationDoc = await getDoc(conversationRef);
  
    if (!conversationDoc.exists()) {
      // Crear una nueva conversación
      await setDoc(conversationRef, {
        participants: [uid, contact.id],
        timestamp: serverTimestamp(),
      });
    }
  
    // Establecer la conversación activa
    setActiveConversation({ id: conversationId, contact });
  };

  useEffect(() => {
    if (!activeConversation) return;
  
    const q = query(
      collection(db, "conversations", activeConversation.id, "messages"),
      orderBy("timestamp", "asc")
    );
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  
    return () => unsubscribe();
  }, [db, activeConversation]);
  
  

  // Función para seleccionar un usuario con quien chatear
  const selectChatUser = async (user) => {
    setSelectedChatUser(user);

    // Crear un ID único para la conversación (podría ser combinación de los IDs de ambos usuarios)
    const chatId = [auth.currentUser.uid, user.uid].sort().join("_");

    // Escuchar los mensajes de la conversación
    const q = query(collection(db, "conversations", chatId, "messages"), orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  };

  const sendMessage = async (e) => {
    e.preventDefault();
  
    if (newMessage.trim() !== "" && activeConversation) {
      const { uid } = auth.currentUser;
  
      await addDoc(
        collection(db, "conversations", activeConversation.id, "messages"),
        {
          senderId: uid,
          text: newMessage,
          timestamp: serverTimestamp(),
        }
      );
  
      // Actualizar el timestamp y el último mensaje en la conversación
      await updateDoc(doc(db, "conversations", activeConversation.id), {
        lastMessage: newMessage,
        timestamp: serverTimestamp(),
      });
  
      setNewMessage("");
    }
  };
  

  return (
    <>
      {/* Globo flotante del chat */}
      <div className="chat-bubble" onClick={toggleChat}>
        <FaComments size={30} />
      </div>
  
      {/* Ventana del chat */}
      <div className={`chat-window ${isChatOpen ? "active" : ""}`}>
        {/* Si no hay una conversación activa, mostrar la lista de contactos */}
        {!activeConversation ? (
          <div className="chat-list">
            <h5>Contactos</h5>
            {contacts
              .filter(contact => contact.name && contact.id)  // Filtrar contactos que tienen un nombre e ID válidos
              .map((contact) => (
                <div
                  key={contact.id}
                  className="contact-item"
                  onClick={() => startConversation(contact)}
                >
                  <p>{contact.name}</p>
                </div>
              ))
            }
            <h5>Conversaciones</h5>
            {conversations.map((conv) => {
              const contactId = conv.participants.find(
                (participant) => participant !== auth.currentUser.uid
              );
              const contact = contacts.find((user) => user.id === contactId);
  
              // Asegurarse de que el contacto tenga un nombre e ID válidos antes de mostrarlo
              if (!contact || !contact.name) return null;
  
              return (
                <div
                  key={conv.id}
                  className="conversation-item"
                  onClick={() => setActiveConversation({ id: conv.id, contact })}
                >
                  <p>{contact.name}</p>
                  <p>{conv.lastMessage}</p>
                </div>
              );
            })}
          </div>
        ) : (
          /* Mostrar la conversación activa */
          <div className="chat-content">
            <div className="chat-header">
              <button onClick={() => setActiveConversation(null)}>←</button>
              <h5>{activeConversation.contact.name}</h5>
            </div>
            <div className="messages">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`message ${
                    message.senderId === auth.currentUser.uid
                      ? "my-message"
                      : "other-message"
                  }`}
                >
                  <p>{message.text}</p>
                </div>
              ))}
            </div>
            <form onSubmit={sendMessage}>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Escribe un mensaje..."
                className="form-control"
              />
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </form>
          </div>
        )}
      </div>
    </>
  );
    
};

export default ChatComponent;
