import React from 'react';
import { Table, Button, Form } from 'react-bootstrap';

const FichasTable = ({ fichas, editableFichas, onFieldChange, onToggleEdit, onSaveFicha, onAddNewFicha, newFicha, onNewFichaChange }) => (
  <>
    <Button variant="success" onClick={onAddNewFicha} className="mb-3">Añadir Nueva Práctica</Button>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Título y Páginas Leídas</th>
          <th>Número de Práctica</th>
          {Array.from({ length: 10 }, (_, i) => <th key={i}>Ejercicio {i + 1}</th>)}
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {fichas.map((ficha, index) => (
          <tr key={index}>
            <td>
              <Form.Control
                type="date"
                value={ficha.fecha || ''}
                onChange={(e) => onFieldChange(index, 'fecha', e.target.value)}
                disabled={!editableFichas[index]}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                value={ficha.tituloYpagsLeidas || ''}
                onChange={(e) => onFieldChange(index, 'tituloYpagsLeidas', e.target.value)}
                disabled={!editableFichas[index]}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                value={ficha.numeroDePractica || ''}
                onChange={(e) => onFieldChange(index, 'numeroDePractica', e.target.value)}
                disabled={!editableFichas[index]}
              />
            </td>
            {ficha.ejercicios.map((ej, ejIndex) => (
              <td key={ejIndex}>
                <Form.Control
                  type="text"
                  value={ej || ''}
                  onChange={(e) => onFieldChange(index, `ejercicios[${ejIndex}]`, e.target.value)}
                  disabled={!editableFichas[index]}
                />
              </td>
            ))}
            <td>
              {editableFichas[index] ? (
                <Button variant="success" onClick={() => onSaveFicha(index)}>Guardar</Button>
              ) : (
                <Button variant="primary" onClick={() => onToggleEdit(index)}>Editar</Button>
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <Form.Control
              type="date"
              value={newFicha.fecha || ''}
              onChange={(e) => onNewFichaChange('fecha', e.target.value)}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              value={newFicha.tituloYpagsLeidas || ''}
              onChange={(e) => onNewFichaChange('tituloYpagsLeidas', e.target.value)}
            />
          </td>
          <td>
            <Form.Control
              type="text"
              value={newFicha.numeroDePractica || ''}
              onChange={(e) => onNewFichaChange('numeroDePractica', e.target.value)}
            />
          </td>
          {Array.from({ length: 10 }, (_, i) => (
            <td key={i}>
              <Form.Control
                type="text"
                value={newFicha.ejercicios ? newFicha.ejercicios[i] : ''}
                onChange={(e) => {
                  const newEjercicios = newFicha.ejercicios || Array(10).fill('');
                  newEjercicios[i] = e.target.value;
                  onNewFichaChange('ejercicios', newEjercicios);
                }}
              />
            </td>
          ))}
          <td>
            <Button variant="success" onClick={onAddNewFicha}>Añadir</Button>
          </td>
        </tr>
      </tbody>
    </Table>
  </>
);

export default FichasTable;
