import React, { useRef, useState, useContext } from 'react';
import { storage, db } from '../../../../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import styles from './M4_Ejercicio8.module.css';
import Swal from 'sweetalert2';
import { FaPaintBrush, FaEraser, FaSave } from 'react-icons/fa';
import { BsSquareFill } from 'react-icons/bs';
import { AppContext } from '../../../../Context';

const M4Ejercicio8 = ({ cerrarModal }) => {
  const { currentUser } = useContext(AppContext);
  const [evocar, setEvocar] = useState('');
  const [color, setColor] = useState('black');
  const [lineWidth, setLineWidth] = useState(5);
  const canvasRef = useRef(null);



  // const getMousePosition = (canvas, event) => {
  //   const rect = canvas.getBoundingClientRect();
  //   const scaleX = canvas.width / rect.width;
  //   const scaleY = canvas.height / rect.height;
  //   return {
  //     x: (event.clientX - rect.left) * scaleX,
  //     y: (event.clientY - rect.top) * scaleY
  //   };
  // };

  // const getTouchPosition = (canvas, event) => {
  //   const rect = canvas.getBoundingClientRect();
  //   const scaleX = canvas.width / rect.width;
  //   const scaleY = canvas.height / rect.height;
  //   return {
  //     x: (event.touches[0].clientX - rect.left) * scaleX,
  //     y: (event.touches[0].clientY - rect.top) * scaleY
  //   };
  // };

  
  
    const getPosition = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      let x = 0;
      let y = 0;
  
      if (event.type.includes('touch')) {
        x = (event.touches[0].clientX - rect.left) * scaleX;
        y = (event.touches[0].clientY - rect.top) * scaleY;
      } else { // Mouse events
        x = (event.clientX - rect.left) * scaleX;
        y = (event.clientY - rect.top) * scaleY;
      }
  
      return { x, y };
    };
  
    const startDrawing = (event) => {
      const { x, y } = getPosition(event);
      const ctx = canvasRef.current.getContext('2d');
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(x, y);
      canvasRef.current.isDrawing = true;
    };
  
    const draw = (event) => {
      if (!canvasRef.current.isDrawing) return;
      const { x, y } = getPosition(event);
      const ctx = canvasRef.current.getContext('2d');
      ctx.lineTo(x, y);
      ctx.stroke();
    };
  
    const stopDrawing = () => {
      if (canvasRef.current) {
        canvasRef.current.isDrawing = false;
      }
    };
  
    const handleClear = () => {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };
  
    const handleSave = async () => {
      if (!evocar.trim()) {
        Swal.fire({
          icon: 'error',
          title: 'Campo Vacío',
          text: 'Debe completar el campo de texto antes de guardar.',
          confirmButtonText: 'Aceptar'
        });
        return;
      }
  
      const canvas = canvasRef.current;
      canvas.toBlob(async (blob) => {
        try {
          const storageRef = ref(storage, `images/${Date.now()}.png`);
          await uploadBytes(storageRef, blob);
          const imageUrl = await getDownloadURL(storageRef);
  
          await addDoc(collection(db, "evaluaciones"), {
            userId: currentUser.uid,
            nombre: "Módulo 4 - Ejercicio 8",
            nivel: "Basico",
            modulo: "4",
            tipo: "Dibujo y Texto",
            texto: evocar,
            imageUrl: imageUrl,
            createdAt: new Date()
          });
  
          Swal.fire({
            title: 'Guardado Exitoso',
            text: 'Imagen y texto guardados con éxito.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              cerrarModal(); // Cierra la modal después de confirmar
            }
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error al Guardar',
            text: 'Error al guardar la imagen y el texto.',
            confirmButtonText: 'Aceptar'
          });
        }
      });
    };
  

  return (
    <div className={styles.container}>
      <h3>Nivel Básico</h3>
      <h3>Módulo 4 - Ejercicio 8</h3>
      <h5>Infiera significado por Contexto</h5>
      <p><strong>"Como el cofre que guarda un tesoro, el frágil vidrio de una botella contiene la
historia de la humanidad toda: Sabe de reflexiones y de poesía, de noche
seductoras y compartidas, de aromas </strong> evocadores  <strong>de memorias"</strong></p>

<p>En algunas ocasiones podemos inferir el significado, por el mensaje implícito en la frase anterior o
posterior a la palabra. La situación planteada en la frase, nos permite afirmar que no se trata de
olvidar memorias sino de:</p>
<input
        type="text"
        value={evocar}
        onChange={(e) => setEvocar(e.target.value)}
        placeholder="Evocar"
        className={styles.input}
      />
      <hr></hr>
      <p>Cree a continuación una imagen que pueda expresar el concepto desarrollado</p>
      <div className='mt-3'>
        <button onClick={() => setColor('black')} className={styles.colorButton}><BsSquareFill color="black" /></button>
        <button onClick={() => setColor('red')} className={styles.colorButton}><BsSquareFill color="red" /></button>
        <button onClick={() => setColor('blue')} className={styles.colorButton}><BsSquareFill color="blue" /></button>
        <button onClick={() => setColor('green')} className={styles.colorButton}><BsSquareFill color="green" /></button>
        <button onClick={() => setLineWidth(prev => Math.max(prev - 1, 1))} className={styles.controlButton}>-</button>
        <button onClick={() => setLineWidth(prev => Math.min(prev + 1, 10))} className={styles.controlButton}>+</button>
        <button onClick={handleClear} className={styles.colorButton}><FaEraser /></button>
      </div>
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
        className={styles.canvas}
      />
      <button onClick={handleSave} className="btn btn-primary"><FaSave /> Guardar Dibujo y Texto</button>
    </div>
  );
};

export default M4Ejercicio8;
