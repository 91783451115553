import React, { useState } from 'react';

import M6_Ejercicio6 from '../components/evaluaciones/Intermedio/Modulo6/M6_Ejercicio6';
import M6_Ejercicio7 from '../components/evaluaciones/Intermedio/Modulo6/M6_Ejercicio7';
import M6_Eje_Retencion from '../components/evaluaciones/Intermedio/Modulo6/M6_Eje_Retencion';
import M7_Ejercicio6 from '../components/evaluaciones/Intermedio/Modulo7/M7_Ejercicio6';
import M7_Ejercicio7 from '../components/evaluaciones/Intermedio/Modulo7/M7_Ejercicio7';
import M7_Ejercicio8 from '../components/evaluaciones/Intermedio/Modulo7/M7_Ejercicio8';
import M7_Eje_Comprension from '../components/evaluaciones/Intermedio/Modulo7/M7_Eje_Comprension';
import M8_Ejercicio7 from '../components/evaluaciones/Intermedio/Modulo8/M8_Ejercicio7';
import M8_Ejercicio8 from '../components/evaluaciones/Intermedio/Modulo8/M8_Ejercicio8';
import M8_Ejercicio9 from '../components/evaluaciones/Intermedio/Modulo8/M8_Ejercicio9';
import M8_EjercicioRetencion from '../components/evaluaciones/Intermedio/Modulo8/M8_EjercicioRetencion';
import M9_Ejercicio6 from '../components/evaluaciones/Intermedio/Modulo9/M9_Ejercicio6';
import M9_Ejercicio7 from '../components/evaluaciones/Intermedio/Modulo9/M9_Ejercicio7';
import M10_Ejercicio5 from '../components/evaluaciones/Intermedio/Modulo10/M10_Ejercicio5'
import M10_Ejercicio6 from '../components/evaluaciones/Intermedio/Modulo10/M10_Ejercicio6'
import M10_Ejercicio7 from '../components/evaluaciones/Intermedio/Modulo10/M10_Ejercicio7'


import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import './EvaluacionBasica.css'; // Asegúrate de crear este archivo CSS

// Agrupando ejercicios por módulo
const modulos = {
  Modulo6: [
    { id: 1, nombre: "Ejercicio 6", componente: M6_Ejercicio6 },
    { id: 2, nombre: "Ejercicio 7", componente: M6_Ejercicio7 },
    { id: 3, nombre: "Ejercicio de retención", componente: M6_Eje_Retencion },
  ],
  Modulo7: [
    { id: 4, nombre: "Ejercicio de comprensión", componente: M7_Eje_Comprension },
    { id: 5, nombre: "Ejercicio 6", componente: M7_Ejercicio6 },
    { id: 6, nombre: "Ejercicio 7", componente: M7_Ejercicio7 },
    { id: 7, nombre: "Ejercicio 8", componente: M7_Ejercicio8 }
  ],
  Modulo8: [
    { id: 8, nombre: "Ejercicio 7", componente: M8_Ejercicio7 },
    { id: 9, nombre: "Ejercicio 8", componente: M8_Ejercicio8}, 
    { id: 10, nombre: "Ejercicio 9", componente: M8_Ejercicio9},
    { id: 11, nombre: "Ejercicio de retención", componente: M8_EjercicioRetencion},
  ],
  Modulo9: [
    { id: 12, nombre: "Ejercicio 6", componente: M9_Ejercicio6 },
    { id: 13, nombre: "Ejercicio 7", componente: M9_Ejercicio7 }
    
   
  ],
  Modulo10: [
    { id: 14, nombre: "Ejercicio 5", componente: M10_Ejercicio5 },
    { id: 15, nombre: "Ejercicio 6", componente: M10_Ejercicio6 },
    { id: 16, nombre: "Ejercicio 7", componente: M10_Ejercicio7},
    

  ]
};

const EvaluacionIntermedia = () => {
  const [modalShow, setModalShow] = useState(false);
  const [componenteActivo, setComponenteActivo] = useState(null);
  const [completadas, setCompletadas] = useState([]);

  const abrirModal = (Componente, id) => {
    if (!completadas.includes(id)) {
      setComponenteActivo(<Componente cerrarModal={() => setModalShow(false)} />);
      setModalShow(true);
    } else {
      alert("Esta evaluación ya ha sido completada.");
    }
  };

  const cerrarModal = () => {
    setModalShow(false);
  };

  return (
    <Container>
      <h1 className="text-center mt-4 mb-4">Nivel 2 Intermedio</h1>
      {Object.entries(modulos).map(([modulo, evaluaciones], index) => (
        <div key={index}>
          <h2>{modulo}</h2>
          <Row xs={1} md={2} lg={4} className="g-4">
            {evaluaciones.map(evaluacion => (
              <Col key={evaluacion.id}>
                <div className="evaluacion-item">
                  <h3>{evaluacion.nombre}</h3>
                  <Button 
                    onClick={() => abrirModal(evaluacion.componente, evaluacion.id)}
                    disabled={completadas.includes(evaluacion.id)}
                    className="w-100">
                    Realizar Ejercicio
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
      <Modal show={modalShow} onHide={cerrarModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Ejercicio en Curso</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componenteActivo}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EvaluacionIntermedia;

