import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M9_Ejercicio6 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        // Raíz: physis = naturaleza
       
        
        coartar: '',
        undecimo: '',
        sibarita: '',
        fragmentar: '',
        grosor: '',
        hebraico: '',
        alabastro: '',
        fibrina: '',
        libamen: '',
        buzamiento: '',
        nebulosidad: '',
        zedilla: '',
        diabetes: '',
        lubricar: '',
        gelatina: '',
        muchedumbre: '',
        abadia: '',
        viaje: '',
        fracasado: '',
        bucal: ''
        

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 6 - Actividad de refuerzo",
                nivel: "intermedio",
                modulo: "9",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h2><strong>Ejercicio 6: Actividad de refuerzo</strong></h2>
            <h4>Objetivo</h4>
            <p>
            Aplicar su vocabulario y capacidad de asociación, mediante la búsqueda rápida de palabras en el
diccionario. Este ejercicio le permite además, mejorar sus niveles de atención y concentración
            </p>
            <h4>Instrucciones</h4>
            <p> 
            Ubique a la mayor velocidad posible las siguientes palabras en el diccionario. Controle el tiempo
empleado en la búsqueda.
            </p>    
            <Row>
                <Col md={6}>

                    <Form className='border p-3'>
                        
                            <FormGroup className="mb-3">
                               <p>1.Coartar</p>
                                <FormControl
                                    type="text"
                                    name="coartar"
                                    value={respuestas.coartar}
                                    onChange={handleChange}
                                    placeholder="Coartar"
                                />
                            </FormGroup>
                            </Form>
                        <br />
                        <Form className='border p-3'>

                            <p>2. Undécimo</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="undecimo"
                                    value={respuestas.undecimo}
                                    onChange={handleChange}
                                    placeholder="Undécimo"
                                />
                            </FormGroup>
                            
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p>3. Sibarita</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="sibarita"
                                    value={respuestas.sibarita}
                                    onChange={handleChange}
                                    placeholder="Sibarita"
                                />
                            </FormGroup>
                           
                        </Form>
                        <br />


                        <Form className='border p-3'>

<p>4. Fragmentar</p>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="fragmentar"
        value={respuestas.fragmentar}
        onChange={handleChange}
        placeholder="Fragmentar"
    />
</FormGroup>

</Form>
                        <br />

                        <Form className='border p-3'>

<p>5. Grosor</p>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="grosor"
        value={respuestas.grosor}
        onChange={handleChange}
        placeholder="Grosor"
    />
</FormGroup>

</Form>

<Form className='border p-3'>

                            <p>6. Hebraico</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="hebraico"
                                    value={respuestas.hebraico}
                                    onChange={handleChange}
                                    placeholder="Hebraico"
                                />
                            </FormGroup>
                           
                        </Form>

                        <Form className='border p-3'>

                            <p>7. Alabastro</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="alabastro"
                                    value={respuestas.alabastro}
                                    onChange={handleChange}
                                    placeholder="Alabastro"
                                />
                            </FormGroup>
                           
                        </Form>

                        <Form className='border p-3'>

                            <p>8. Fibrina</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="fibrina"
                                    value={respuestas.fibrina}
                                    onChange={handleChange}
                                    placeholder="Fibrina"
                                />
                            </FormGroup>
                           
                        </Form>
                        <Form className='border p-3'>

                            <p>9. Libamen</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="libamen"
                                    value={respuestas.libamen}
                                    onChange={handleChange}
                                    placeholder="Libamen"
                                />
                            </FormGroup>
                           
                        </Form>
                        <Form className='border p-3'>

                            <p>10. Buzamiento</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="buzamiento"
                                    value={respuestas.buzamiento}
                                    onChange={handleChange}
                                    placeholder="Buzamiento"
                                />
                            </FormGroup>
                           
                        </Form>
                </Col>
                <Col md={6}>
                    
                <Form className='border p-3'>

<p>11. Nebulosidad</p>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="nebulosidad"
        value={respuestas.nebulosidad}
        onChange={handleChange}
        placeholder="Nebulosidad"
    />
</FormGroup>

</Form>
                        <br />
                        <Form className='border p-3'>

<p>12. Zedilla</p>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="zedilla"
        value={respuestas.zedilla}
        onChange={handleChange}
        placeholder="Zedilla"
    />
</FormGroup>

</Form>
                        <br />
                        <Form className='border p-3'>

                            <p>13. Diabetes</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="diabetes"
                                    value={respuestas.diabetes}
                                    onChange={handleChange}
                                    placeholder="Diabetes"
                                />
                            </FormGroup>
                           
                        </Form>
                        <br />
                        <Form className='border p-3'>

                            <p>14. Lubricar</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="lubricar"
                                    value={respuestas.lubricar}
                                    onChange={handleChange}
                                    placeholder="Lubricar"
                                />
                            </FormGroup>
                           
                        </Form>
                        <br />
                        <Form className='border p-3'>

                            <p>15. Gelatina</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="gelatina"
                                    value={respuestas.gelatina}
                                    onChange={handleChange}
                                    placeholder="Gelatina"
                                />
                            </FormGroup>
                           
                        </Form>
                        <Form className='border p-3'>

<p>16. Muchedumbre</p>
<FormGroup className="mb-3">
    <FormControl
        type="text"
        name="muchedumbre"
        value={respuestas.muchedumbre}
        onChange={handleChange}
        placeholder="Muchedumbre"
    />
</FormGroup>

</Form>
<Form className='border p-3'>

                            <p>17. Abadía</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="abadia"
                                    value={respuestas.abadia}
                                    onChange={handleChange}
                                    placeholder="Abadía"
                                />
                            </FormGroup>
                           
                        </Form>

                        <Form className='border p-3'>

                            <p>18. Viaje</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="viaje"
                                    value={respuestas.viaje}
                                    onChange={handleChange}
                                    placeholder="Viaje"
                                />
                            </FormGroup>
                           
                        </Form>
                        <Form className='border p-3'>

                            <p>19. Fracasado</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="fracasado"
                                    value={respuestas.fracasado}
                                    onChange={handleChange}
                                    placeholder="Fracasado"
                                />
                            </FormGroup>
                           
                        </Form>
                        <Form className='border p-3'>

                            <p>20. Bucal</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="bucal"
                                    value={respuestas.bucal}
                                    onChange={handleChange}
                                    placeholder="Bucal"
                                />
                            </FormGroup>
                           
                        </Form>




                    
                </Col>
                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M9_Ejercicio6;
