import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../Context'; // Ajusta la ruta de importación según sea necesario
import { Table } from 'react-bootstrap';

function MaterialDidactico() {
    const { materials, currentUser } = useContext(AppContext); // Asegúrate de que los materiales también se carguen correctamente
    const [filteredMaterials, setFilteredMaterials] = useState([]);

    useEffect(() => {
        let materialsToDisplay = [];

        if (currentUser && currentUser.teacher) {
            materialsToDisplay = materials; // El usuario es maestro, mostrar todos los materiales
        } else if (currentUser) {
            // Filtra los materiales basado en el nivel del usuario
            const maxLevel = parseInt(currentUser.level, 10);
            materialsToDisplay = materials.filter(material => parseInt(material.level, 10) <= maxLevel);
        }
        
        setFilteredMaterials(materialsToDisplay);
    }, [currentUser, materials]); // Dependencias añadidas aquí para reaccionar a los cambios

    return (
        <div class="container"> 
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Nivel</th>
                    <th>Descargar</th>
                </tr>
            </thead>
            <tbody>
                {filteredMaterials.map((material, index) => (
                    <tr key={index}>
                        <td>{material.name}</td>
                        <td>{material.level}</td>
                        <td><a href={material.url} download>Descargar</a></td>
                    </tr>
                ))}
            </tbody>
        </Table>

 </div>
    );
}


export  {MaterialDidactico };
