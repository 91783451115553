import React, { useState, useContext } from 'react';
import styles from './m4_ejercicio7.module.css';
import Swal from 'sweetalert2';

import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';


const M4Ejercicio7 = ({ cerrarModal }) => {
  const { currentUser } = useContext(AppContext);

  const [respuestas, setRespuestas] = useState({
    citología: 'Estudio de las célula',
    hematocitos: '',
    citoplasma: '',
    leucocitos: '',
    diagonal: 'A través del ángulo',
    diafragma: '',
    diapasón: '',
    diagnóstico: 'Determinar el conocimiento  (sobre la enfermedad) a través de los síntomas',
    diáfano: '',
    díalogo: 'Intercambio de ideas a tráves del discurso',
    diámetro: '',
    diarrea: '',
    bolígrafo: '',
    grafologia: '',
    caligrafia: '',
    etnografia: '',
    ortografia: '',
    idiosincrasia: 'Temperamento propio',
    cíclico: '',
    bicicleta: '',
    triciclo: '',
    semiología: 'Estudio de la significación de las palabras y los signos.',
    epistemología: 'Estudio del conocimiento científico',
    psicología: '',
    biología: '',
    macrocefálico: '',
    macroeconomía: '',
    macrobiótico: '',
    macrocosmos: '',
    neófito: '',
    neolítico: '',
    neomenia: 'Luna nueva',
    neonato: '',
    neofobia: 'Horror a todo lo nuevo.',
    economía: '',
    astronomía: '',
    autónomo: '',
    deuteronomio: 'Segunda ley',
    pedagogía: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRespuestas(prev => ({ ...prev, [name]: value }));
  };

  const enviarResultados = async () => {
    const emptyFields = Object.entries(respuestas).filter(([key, value]) => !value.trim());
    if (emptyFields.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Campos Incompletos',
        text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    try {
      await addDoc(collection(db, "evaluaciones"), {
        userId: currentUser.uid,
        nombre: "Módulo 4 - Incrementa tu Vocabulario",
        nivel: "Basico",
        modulo: "4",
        createdAt: new Date(),
        tipo: "Texto libre",
        respuestas
      });
      Swal.fire({
        title: 'Resultados enviados con éxito.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          cerrarModal();
        }
      });
    } catch (error) {
      console.error("Error al enviar las respuestas: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al enviar las respuestas.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };


  return (
    <div className={styles.container}>
      <h3>Nivel Básico</h3>
      <h3>Módulo 4 - Ejercicio 7</h3>
      <h5>Incrementa tu Vocabulario</h5>
      <Row>
        <Col md={6}>
          <Form className='border p-3'>
            <FormGroup className="mb-3">
              <p><strong>Raíz: sitos, citos = célula</strong></p>
              {["citología", "hematocitos", "citoplasma", "leucocitos"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.inputm7}
                  />
                </div>
              ))}
            </FormGroup>


          </Form>
          <br />
          <Form className='border p-3'>
            <FormGroup className="mb-3">


              <p><strong>Prefijo: día = a través de, entre</strong></p>
              {["diagonal", "diafragma", "diapasón", "diagnóstico", "diáfano", "díalogo", "diámetro", "diarrea"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.inputm7}
                  />
                </div>
              ))}

            </FormGroup>


          </Form>
          <br />
          <Form className='border p-3'>
            <FormGroup className="mb-3">



              <p><strong>Raíz: grafo = escritura, descripción</strong></p>
              {["bolígrafo", "grafologia", "caligrafia", "etnografia", "ortografia"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.inputm7}
                  />
                </div>
              ))}

            </FormGroup>


          </Form>
          <br />
          <Form className='border p-3'>
            <FormGroup className="mb-3">

              <p><strong>Raíz idios = propio, forma particular de ser.</strong></p>
              {["idiosincrasia"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.inputm7}
                  />
                </div>
              ))}

            </FormGroup>


          </Form>
          <br />
          <Form className='border p-3'>
            <FormGroup className="mb-3">

              <p><strong>Raíz: kyklos = ciclos = circulo</strong></p>
              {["cíclico", "bicicleta", "triciclo"].map(key => (
                <div key={key} className={styles.inputGroup}>
                  <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                  <input
                    id={key}
                    type="text"
                    name={key}
                    value={respuestas[key]}
                    onChange={handleChange}
                    className={styles.inputm7}
                  />
                </div>
              ))}

            </FormGroup>


          </Form>
          <br />
          

            </Col>
            <Col md={6}>
              <Form className='border p-3'>
                <FormGroup className="mb-3">
                  <p><strong>Raíz: logos = tratado, estudio</strong></p>
                  {["semiología", "epistemología", "psicología", "biología"].map(key => (
                    <div key={key} className={styles.inputGroup}>
                      <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                      <input
                        id={key}
                        type="text"
                        name={key}
                        value={respuestas[key]}
                        onChange={handleChange}
                        className={styles.inputm7}
                      />
                    </div>
                  ))}
                   </FormGroup>
                    </Form>
                    <br />
                    <Form className='border p-3'>
                <FormGroup className="mb-3">

                  <p><strong>Raíz: makro = mucho,grande</strong></p>
                  {["macrocefálico", "macroeconomía", "macrobiótico", "macrocosmos"].map(key => (
                    <div key={key} className={styles.inputGroup}>
                      <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                      <input
                        id={key}
                        type="text"
                        name={key}
                        value={respuestas[key]}
                        onChange={handleChange}
                        className={styles.inputm7}
                      />
                    </div>
                  ))}
                  </FormGroup>
                    </Form>
                    <br />
                    <Form className='border p-3'>
                <FormGroup className="mb-3">

                  <p><strong>Raíz: neo = nuevo</strong></p>
                  {["neófito", "neolítico", "neomenia", "neonato", "neofobia"].map(key => (
                    <div key={key} className={styles.inputGroup}>
                      <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                      <input
                        id={key}
                        type="text"
                        name={key}
                        value={respuestas[key]}
                        onChange={handleChange}
                        className={styles.inputm7}
                      />
                    </div>
                  ))}
                  </FormGroup>
                    </Form>
                    <br />
                    <Form className='border p-3'>
                <FormGroup className="mb-3">

                  <p><strong>Raíz: nomos = ley, regla, nómina, orden</strong></p>
                  {["economía", "astronomía", "autónomo", "deuteronomio"].map(key => (
                    <div key={key} className={styles.inputGroup}>
                      <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                      <input
                        id={key}
                        type="text"
                        name={key}
                        value={respuestas[key]}
                        onChange={handleChange}
                        className={styles.inputm7}
                      />
                    </div>
                  ))}
                  </FormGroup>
                    </Form>
                    <br />
                    <Form className='border p-3'>
                <FormGroup className="mb-3">

                  <p><strong>Raíz: paidos = niño instrucción enseñanza</strong></p>
                  {["pedagogía"].map(key => (
                    <div key={key} className={styles.inputGroup}>
                      <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                      <input
                        id={key}
                        type="text"
                        name={key}
                        value={respuestas[key]}
                        onChange={handleChange}
                        className={styles.inputm7}
                      />
                    </div>
                  ))}
                  </FormGroup>
                    </Form>
                    <br />
                </Col>

                <button onClick={enviarResultados} className="btn btn-primary">Enviar Resultados</button>
              </Row>
            </div>
            );
            
};

            export default M4Ejercicio7;