import { useState, useEffect } from "react";
import { obtenerUsuarios, updateById } from "../firebase/firebase";
import AddUserModal from "./Components/AddUserModal";
import EditUserModal from "./Components/EditUserModal";
import { Button, Spinner, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import './MasterView.css';



function MasterView() {
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    setLoading(true);
    obtenerUsuarios().then(usuarios => {
      setUsuarios(usuarios);
      setLoading(false);
    }).catch(err => {
      console.error('Error on MasterView.loadUsers', err);
      setError('Failed to fetch users');
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().isMaster) {
          // nothing to do if user is master
        } else {
          navigate("/dashboard");
        }
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [auth, db, navigate]);

  if (!usuarios.length && loading) {
    return <Spinner animation="border" role="status">
             <span className="visually-hidden">Loading...</span>
           </Spinner>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleOpenAddModal = () => setShowAddModal(true);
  const handleOpenEditModal = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleCloseEditModal = () => setShowEditModal(false);

  const updateIsTeacher = async (uid, isTeacher) => {
    try {
      console.warn('updateIsTeacher', uid, isTeacher);
      await updateById(uid, { teacher: isTeacher });
    } catch (err) {
      console.error('Error on MasterView.updateIsTeacher', err);
    }
  };

  const handleCheckChanges = async (e, item) => {
    const isTeacher = e.target.checked;
    await updateIsTeacher(item.id, isTeacher);
    const updatedUsers = usuarios.map(usuario => 
      usuario.id === item.id ? { ...usuario, teacher: isTeacher } : usuario
    );
    setUsuarios(updatedUsers);
  };

  
  
    // Filtrar usuarios según el estatus seleccionado
    const filteredUsuarios = usuarios.filter(usuario => 
      statusFilter === '' || usuario.estatus === statusFilter
    
    );
  
  return (
    <div className="container">
      <h1 className="text-center">MASTER</h1>
      <Button onClick={handleOpenAddModal}>Agregar Usuario</Button>
      <AddUserModal show={showAddModal} handleClose={handleCloseAddModal} />
      <EditUserModal show={showEditModal} handleClose={handleCloseEditModal} user={selectedUser} />

      {/* Botones redirigidos a las páginas de material */}
      <Button variant="primary" onClick={() => navigate('/dashboard/master/agregar-material-didactico')}>Material para imprimir</Button>
      <Button variant="primary" onClick={() => navigate('/dashboard/master/agregar-material-teorico')}>Material Teórico</Button>

      <div className="table-responsive">
        <div className="mb-3">
          <Form.Group controlId="estatusFilter">
            <Form.Label>Filtrar por Estatus</Form.Label>
            <Form.Control as="select" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">Todos</option>
              <option value="Activo">Activos</option>
              <option value="Inactivo">Inactivo</option>
              <option value="Baja Temporal">Baja Temporal</option>
              <option value="Baja Definitiva">Baja Definitiva</option>
              <option value="Graduado">Graduados</option>
            </Form.Control>
          </Form.Group>
        </div>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>DisplayName</th>
              <th>Email</th>
              <th className="d-none d-md-table-cell">Profesor asignado</th>
              <th>¿Teacher?</th>
              <th>Estatus</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsuarios.map((usuario, index) => (
              <tr key={usuario.id}>
                <td>{index + 1}</td>
                <td>{usuario.displayName}</td>
                <td>{usuario.email}</td>
                <td className="d-none d-md-table-cell">{usuario.asignTeacher}</td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`flexCheck${usuario.id}`}
                      checked={usuario.teacher}
                      onChange={(e) => handleCheckChanges(e, usuario)}
                    />
                    <label className="form-check-label" htmlFor={`flexCheck${usuario.id}`}>Teacher</label>
                  </div>
                </td>
                <td>{usuario.estatus}</td>
                <td>
                  <Button variant="outline-secondary" onClick={() => handleOpenEditModal(usuario)}>Editar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export { MasterView };