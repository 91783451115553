import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useContext } from "react";
import { AppContext } from "../Context";
import { FormLogin } from "../components/FormLogin";
import styled from "styled-components";



const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0044cc, #002266);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 0 !important;
    font-family: 'Roboto', sans-serif;
    color: white;

    .card-body{
        background: whitegray;
    }
`;


export default function LoginView() {
    const { isAuth } = useContext(AppContext);

    async function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            console.warn('signInWithPopup', result);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

        <div className="container">
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                    <div className="form-2-wrapper">
                        <div className="logo text-center">
                            <img src="../img/default-profile.jpg" alt="logo" />
                            <h2>Bienvenido </h2>
                        </div>
                        <h4 className="text-center mb-4">Ingresa tus datos para acceder</h4>
                        <FormLogin /> {/* Asumiendo que este componente maneja el formulario regular de login */}
                        {/* <div className="social-login mb-3 type--A">
                            <h5 className="text-center mb-3">Social Login</h5>
                            <button className="btn btn-outline-secondary mb-3" onClick={signInWithGoogle}><i className="fa-brands fa-google text-danger"></i> Sign With Google</button>
                        </div> */}
                        {/* <p className="text-center register-test mt-3">Don't have an account? <a href="register-3.html" className="text-decoration-none">Register here</a></p> */}
                    </div>
                </div>
            </div>
        </div>
        </Container>

    );
}
