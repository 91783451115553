import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M8_Ejercicio8 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState('');

    const [causa, setCausa] = useState(''); // Estado para la causa
    const [efecto, setEfecto] = useState(''); // Estado para el efecto

    const options = [
        { key: 'a', text: 'Nombre, época a la que pertenecen, actividad económica, religión.' },
        { key: 'b', text: 'Ubicación, organización política, actividad económica, artes, arquitectura.' },
        { key: 'c', text: 'Ubicación, actividad económica, artes, arquitectura.' }
    ];

    const comparisonOptions = [
        { key: 'a', text: 'Ubicación' },
        { key: 'b', text: 'Actividad económica' },
        { key: 'c', text: 'Artes' },
        { key: 'd', text: 'Arquitectura' }
    ];

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleNextExercise = async () => {
        if (!selectedOption) {
            Swal.fire({
                icon: 'error',
                title: 'Campos Incompletos',
                text: 'Debe seleccionar una opción antes de continuar.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "respuestas"), {
                userId: currentUser.uid,
                pregunta: "¿Cuáles serían los principales criterios por los cuales podríamos cotejar las culturas clásicas de Suramérica?",
                respuesta: selectedOption,
                createdAt: new Date()
            });
            setStep(step + 1);
        } catch (error) {
            console.error("Error al guardar la respuesta: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo guardar la respuesta.',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const enviarResultados = async () => {
        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Causa y Efecto",
                nivel: "Intermedio",
                modulo: "8",
                tipo: "Causa y efecto",
                createdAt: new Date(),
                causa: causa,
                efecto: efecto
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal(); // Cierra la modal al confirmar
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const advanceStep = () => {
        setStep(step + 1);
    };

    return (
        <Container className="mt-4">
            
            {step === 1 && (
                <div>
                    <h3>Nivel Intermedio</h3>
                    <h3>Módulo 8 - Ejercicio 8</h3>
                    <h5>Compare y contraste</h5>


                    <p>
                    Comparar es analizar dos elementos diferentes con el objetivo de encontrar similitud. Contrastar es lo
contrario, busca encontrar diferencias. Es necesario definir primero el o los artículos en el cual se pide a
diferentes personas que fijen su posicion respecto a un tema, y quiere cotejar las opiniones; deberá fijar
un criterio, por ejemplo comparar las opciones respecto a la nueva ley en lo referente a: nuevas normas
laborales. Los criterios deberan corresponder a elementos relevantes para la demostración del
argumento y a informacion que poseamos. Tambien podra establecer comparaciones entre los
criterios: Pepe Pérez es escritor en lo relacionado con ... , pero tiene una opinión diferente en lo referente
a ...
</p>
<p>
Aprender a comparar y contrastar le servira no solo para establecer relaciones entre dos o mas hechos o
personajes o situaciones que el autor plantee, sino posteriormente para comparar y contrastar con las
ideas, conocimientos e imagenes que usted ya posee y asi poder sacar conclusiones.
</p>
<p>
Lea la lectura de la página siguiente, utilizando las técnicas de lectura rápida, es decir, tan rápido como
pueda, establezca el tiempo de lectura y para medir la comprensión realice los siguientes ejercicios de
comprensión.
</p>
     
                    <Button onClick={advanceStep}>Comenzar Lectura</Button>
                </div>
            )}
            {step === 2 && (
                <div>
                <h2>Culturas Clásicas de Sudamérica</h2>
                <h5>Cultura Mochica o Moche</h5>
                <p>Ubicada en la costa norte de Peru, alcanzo gran esplendor entre los anos 600 y 700 D.C. los restos
arqueologicos hallados muestran una fuerte jerarquizacion y religiosa y una intensa actividad
guerrera.
</p>
<p>
Los mochicas cultivaron maiz y tuberculos (papa). Para ser mas productivas sus tierras, utilizaron
canales de irrigación.
</p>
<p>
Tejieron en lana de llama o vicuña y trabajaron el oro, la plata y el cobre; pero es su cerámica la
muestra mas representativa de su desarrollo. A traves de ella se ha conocido lo más importante de los
mochica, ya que en la decoracion de sus vasijas aparecen escenas de su vida cotidiana. Las esculturas
en madera del pueblo y sus gubernaturas fue tan importante como la ceramica. Los colores más
utilizados en estas actividades fueron: rojo, ocre, negro y naranja.
</p>
<p>
La arquitectura tuvo importancia en la construccion de tumbas y templos, de esta cultura se destaca
la piramide de Moche, donde se haya las huacas del sol y la luna, realizadas en ladrillo cocido, muy
parecidas a las de Mesoamerica.
</p>
<p>
Las huacas eran divinidades titulares a quienes se les dada como lugar de residencias algun lugar
natural como una piedra o un monte.</p>

<h5>Cultura Nazca</h5>
<p>
Floreció en la costa sur del Perú y es famosa por su cerámica y sus tejidos. Aunque la cerámica no
tiene diversidad de formas, en muy rica en colorido, decoracion y terminado. Los temas favoritos de
su decoración son: serpientes, pájaros marinos, peces y motivos geométricos predominando los
colores negro, azul, gris, blanco y anaranjado. Tambien son frecuentes las representaciones
mitologicas como dioses en forma de felinos, pajaros y demonios de dos cabezas. Los tejidos hechos
en lana de llama, alpaca o vicuña, presentan motivos muy parecidos a los realizados en cerámica.
</p>
<p>
Este pueblo no representa edificaciones monumentales y solo Cahuachí tiene algunos edificios, por lo
que se piensa que puedo ser un centro ceremonial. La escasez de agua en la zona, los llevó a construir
obras hidraulicas de gran envergadura que le permitieron desarrollar la agricultura y especializar la
artesanía en cerámica y orfebrería.
</p>
<p>
Tambien se distinguieron por los trabajos en plumas de aves exoticas. Por no existir en la región se
piensa que son muestra de un intenso comercio con los pueblos vecinos. Hoy se estudian las líneas
de Nazca, gigantescos diseños de animales estilizados, que aparecen sobre una región entre el valle
de Nazca y Palpa. En esta área geográfica la lluviosidad es muy escasa, lo que ha permitido que estas
lineas se conserven. Son muchas las explicaciones que se le han dado. Se ha dicho que son
calendarios, formas de medición del tiempo, figuras sagradas para propiciar lluvias u otros fenómenos
favorables, y no han faltado los sensacionalistas que en afán comercial, hablan de pistas de aterrizaje
para los extraterrestres.
</p>

<h5>Cultura Chimú</h5>
<p>
El reino de Chimú se localiza en la orilla derecha del río Moche, estableciendo su centro político en la
cuidad de chan chan.<br></br>
Es un medio ambiente árido pero con una economía agrícola, complementada con la pesca. Fue
necesario construir complejos sistemas de canales que permitieron almacenar y distribuir el agua. La
red hidraulica estaba vigilada entre ellos por pequenos cuarteles siendo la clase dirigente quien
distribuía el agua.
</p>
<p>
La calidad de sus productos fue la base de su comercio; la cerámica de color negro y rojo, los trabajos
en oro, plata y bronceen el que fueron grandes maestros, tanto que muchas de sus obras artesanales
fueron llevadas por los indios al Cuzco.
Adoraban el sol y la luna y para tener contentos a los dioses les ofrecian sacrificios de ninos.

</p>

<h5>LA CULTURA DE TIAHUANACO</h5>
<p>
Estuvo situada a 3800 metros de altura, en la vertiente oriental del lago Titicaca (hoy en territorio de
Bolivia). Fue un gran centro ceremonial en donde sobresalen grandes edificios como el palacio de
Kalasasaya. De el hace parte la puerta del sol, enorme monumento cuyo dintel presenta decoraciones
en relieve.<br></br>
La cultura de Tiahuanaco fue organizada sobre la base de una economía artesanal con productos de
gran calidad en ceramica, textiles y manufacturas en bronce.
</p>
<p>
La clase sacerdotal era la encargada de administrarlos cultivos y sus técnicas. Utilizaron canales de
riego, terrazas y un preciso manejo de las épocas siembra y recolección. Como en tantas otras
culturas, eran los sacerdotes quienes hacían las veces de científicos y médicos, pues eran quienes
conservaban y producian el saber.
</p>
<h5>
CULTURAS COLOMBIANAS
</h5>
<p>
En Colombia las culturas Tayrona y Chibcha pueden considerarse clasicas por el auge que
presentaron la metalurgia y el oro; en la riqueza de la cerámica y de los tejidos.<br></br>
Con el descubrimiento en 1976 de Cuidad Perdida, centro de la cultura Tayrona, otro elemento
cultural se hace presente para complementar el estudio de nuestros pueblos más representativos.
</p>
<h5>
CIUDAD PERDIDA
</h5>
<p>
Ubicada en plena Sierra Nevada de Santa Marta, cubre un área de dos kilómetros cuadrados. Está
construida sobre la cuchilla de un cerro, entre los 900 y 1300 metros de altura y se abre hacia sus dos
laderas.
</p>
<p>
El aislamiento de esta cuidad no fue grande en su época, pues la población estaba conectada con el
exterior con una vastisima red de tuneles construidos en piedra: donde hay grandes declives, se
construyeron escaleras y las viviendas se ubican en terrazas, sostenidas por muros de contención.
En cuidad perdida se han descubiertos sistemas de canales y drenaje, muy eficaces para evitar que
las inundaciones, la erosión y para mejorar sus cultivos.
</p>
<p>
Tambien se han encontrado muchos adornos en oro, narigueras, orejeras y ochenta y una laminas
con dos orificios para poder adherirlas a las telas. Para elaborar estos objetos emplearon las tecnicas
del martillado y el repujado, así como el método de la cera perdida.
</p>
<p>
Entre 1973 y 1974 se encontraron mas de 180 poblados tarionas, todos sobre la Sierra, razon por la que
hoy se considera Parque Arqueologico Nacional.
</p>


                    <Button onClick={advanceStep}>Comenzar Lectura</Button>
                </div>
            )}
           {step === 3 && (
                <div>
                    <h5>Compare y contraste</h5>
                    <p>1. ¿Cuáles serían los principales criterios por los cuales podríamos cotejar las culturas clásicas de Suramérica?</p>
                    {options.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="criteriosCultura"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 4 && (
                <Container>
                    <h5>Compare y contraste</h5>
                    <p>2. Referente a la arquitectura, ¿en qué se diferencia la cultura Mochica de la Nazca?</p>
                    <Form>
                        <FormGroup className="mb-3">
                            
                            <FormControl
                                type="text"
                                placeholder="Escriba su respuesta aquí..."
                                value={causa}
                                onChange={e => setCausa(e.target.value)}
                            />
                        </FormGroup>
                        
                        <Button onClick={handleNextExercise}>Siguiente ejercicio</Button>
                    </Form>
                </Container>
            )}
            {step === 5 && (
                 <div>
                    <h5>Compare y contraste</h5>
                 <p>3. ¿Para cuál criterio de comparación encontramos la misma característica en todas las culturas?</p>
                 {comparisonOptions.map(option => (
                     <div key={option.key}>
                         <label>
                             <input type="radio" name="comparisonCriteria" checked={selectedOption === option.key} onChange={() => handleOptionChange(option.key)} />
                             {option.text}
                         </label>
                     </div>
                 ))}
                  <Button onClick={handleNextExercise}>Siguiente ejercicio</Button>
             </div>
            )}

{step === 6 && (
                <Container>
                    <h5>Compare y contraste</h5>
                <p>4. En cuánto a la artesanía más importante en qué se diferencia la producción artesanal de los Taironas
de la de los Nazca.</p>
                <Form>
                    <FormGroup className="mb-3">
                        
                        <FormControl
                            type="text"
                            placeholder="Escriba su respuesta aquí..."
                            value={causa}
                            onChange={e => setCausa(e.target.value)}
                        />
                    </FormGroup>
                    
                    <Button onClick={enviarResultados}>Finalizar Ejercicio</Button>
                </Form>
            </Container>
                 
             
            )}
        </Container>
    );
};

export default M8_Ejercicio8;