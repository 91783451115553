import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M8_Ejercicio7 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de retencion",
                nivel: "Intermerdio",
                modulo: "6",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h2>Ejercicio 7 - Infiera por Contexto</h2>
            
            <h4>
               La Guerra de las Galaxias
           </h4>
          <p> 
          La galaxia fue una vez una gran Republica de estrellas. Los miles y miles de planetas miembros estaban
gobernados con justicia y eficacia por Senado, y los Caballeros Jedi cuidaban de ellos y los protegian.
Inevitablemente, al incrementarse el número de mundos, la República se vio lastrada por una
burocracia demasiado grande. Había crecido demasiado, era demasiado vieja y la corrupción había
plantado sus semillas. Unos pocos senadores avaros y sin escrupulos iniciaron actividades
ilegales para obtener ganancias personales.
</p>

<p className='text-right'> <i> <strong>Basado en textos de Infografia Star Wars </strong></i> </p>
    <p>Intente deducir el significado de la palabra <i>Lastrada</i> leyendo nuevamente el párrafo 2 de la lectura
<i> La Guerra de las Galaxias</i>

</p>
            

            <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Lastrada"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};



export default M8_Ejercicio7;
