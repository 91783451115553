import React, { useContext } from 'react';
import { Button, Row, Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Context';

const MaterialTeorico = () => {
    const { lecciones, teacher, level } = useContext(AppContext);
    console.log("Lecciones: ", lecciones);
    console.log("level: ", level);
    console.log("isTeacher: ", teacher);
    const navigate = useNavigate();

    const nivelesVisibles = teacher ? [1, 2, 3] : Array.from({ length: parseInt(level, 10) }, (_, i) => i + 1);

    const handleVerDetalle = (nivel) => {
        navigate(`/dashboard/material-teorico/detalle/${nivel}`);
    };

    const leccionesFiltradas = lecciones.filter(leccion => nivelesVisibles.includes(leccion.level));
    console.log("Lecciones filtradas: ", leccionesFiltradas);

    return (
        <Container>
            <h3 className="text-center">Material Teorico</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {nivelesVisibles.map((nivel) => (
                            <th key={nivel}>NIVEL {nivel}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {nivelesVisibles.map((nivel) => (
                            <td key={nivel}>
                                <Button variant="primary" onClick={() => handleVerDetalle(nivel)}>
                                    Ver Nivel {nivel}
                                </Button>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
};

export { MaterialTeorico };
