import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M6_Ejercicio6 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({

        //Raíz: Abeo = Alejarse, retirarse, irse

        abdicar: '',
        abandonar: '',
        abadia: '',
        abad: '',

        //Raiz: ab, Abs = Privación, separación

        aborto: '',
        absoluto: '',

        //Raiz: Annus = año

        anales: '',
        anuario: '',
        milenio: '',

        //raiz: ager = agri, campo

        agrario: '',
        agricultura: '',
        agricultor: '',
        agreste: '',

        //Raiz: alienus = ajeno, extraño, extranjero

        alienacion: '',
        alienar: ''


    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Comprensión - Ejercicio 6",
                nivel: "Intermedio",
                modulo: "6",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h3>Nivel Intermedio</h3>
            <h3>Módulo 6 - Ejercicio 6</h3>
            <h5>Ejercicio de comprensión</h5>
            
            <p><strong>Incremente su vocabulario</strong></p>


            <Row>
                <Col md={12}>

                    <Form className='border p-3'>
                        <h4>Raíces Latinas</h4>
                        <p><strong>Raiz: abeo = alejarse, retirarse, irse</strong></p>

                        <Form>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="abdicar"
                                    value={respuestas.abdicar}
                                    onChange={handleChange}
                                    placeholder="Abdicar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="abandonar"
                                    value={respuestas.abandonar}
                                    onChange={handleChange}
                                    placeholder="Abandonar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="abadia"
                                    value={respuestas.abadia}
                                    onChange={handleChange}
                                    placeholder="Abadia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="abad"
                                    value={respuestas.abad}
                                    onChange={handleChange}
                                    placeholder="Abad"
                                />
                            </FormGroup>

                        </Form>

                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: ab. Abs = privación, separación</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="aborto"
                                    value={respuestas.aborto}
                                    onChange={handleChange}
                                    placeholder="Aborto"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="absoluto"
                                    value={respuestas.absoluto}
                                    onChange={handleChange}
                                    placeholder="Absoluto"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: annus = año</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="anales"
                                    value={respuestas.anales}
                                    onChange={handleChange}
                                    placeholder="Anales"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="anuario"
                                    value={respuestas.anuario}
                                    onChange={handleChange}
                                    placeholder="Anuario"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="milenio"
                                    value={respuestas.milenio}
                                    onChange={handleChange}
                                    placeholder="Milenio"
                                />
                            </FormGroup>
                        </Form>
                        <br />


                        <Form className='border p-3'>

                            <p><strong>Raíz: ager = agri, campo</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="agrario"
                                    value={respuestas.agrario}
                                    onChange={handleChange}
                                    placeholder="Agrario"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="agricultura"
                                    value={respuestas.agricultura}
                                    onChange={handleChange}
                                    placeholder="Agricultura"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="agricultor"
                                    value={respuestas.agricultor}
                                    onChange={handleChange}
                                    placeholder="Agricultor"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="agreste"
                                    value={respuestas.agreste}
                                    onChange={handleChange}
                                    placeholder="Agreste"
                                />
                            </FormGroup>

                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: Alienus = ajeno, extraño, extranjero</strong></p>
                            <p>Enajenar: pasar a otro el dominio de un bien</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="alienacion"
                                    value={respuestas.alienacion}
                                    onChange={handleChange}
                                    placeholder="Alienación"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="alienar"
                                    value={respuestas.alienar}
                                    onChange={handleChange}
                                    placeholder="Alienar"
                                />
                            </FormGroup>


                        </Form>

                    </Form>
                </Col>

                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M6_Ejercicio6;
