import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormStoreUser } from '../../components/FormStoreUser';

const AddUserModal = ({ show, handleClose, handleUserAdded }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormStoreUser onUserAdded={handleUserAdded} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUserModal;
