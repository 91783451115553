import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M10_Ejercicio7 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        // Raíz: physis = naturaleza
        física: 'Ciencia que estudia los cuerpos de la naturaleza',
        fisionomía: 'Aspecto Fisico',
        fisioterapia: '',
        fisiología: 'ciencia que estudia la vida',

        // Raíz: polis, polys = muchos
        poligamia: '',
        políglota: 'Que habla muchos idiomas',
        polígono: '',
        
        // Raíz: tele = lejos, a distancia, a lo lejos
        televisión: '',
        teléfono: '',
        telegrama: '',
        telepatía: '',
        
        // Raíz: theos, teos = dios
        teodor: 'donde dios',
        teología: '',
        teocracia: '',
        teofilo: '',
        ateo: '',

        // Raíz: sophia = saber, ciencia

        filósofo: 'Amigo del estudio, de la ciencia. Filos es amigo',

        //Raíz syn = unión, inclusión, unión en uno solo.

        síntesis: '',
        simpatía: '',
        simbólico: '',
        sinfonía: '',
        simétrico: '',
        sintonía: '',
        sinóptico: '',
        sinergia: '',
        sintaxis: 'Unido con un orden',

        
        // Raíz: sarkos = carne, cuerpo
        sarcoma: '',
        sarcófago: '',
        sarcasmo: '',

        // Raíz: therapeu, therapautos = curar, cultivar, cuidar
        hidroterapia: '',
        psicoterapia: '',
        terapeuta: '',
        
        // Raíz: xenos = extranjero, ajeno
        xenofobia: '',
        xenofilia: '',

        // Raíz: zoon = animal, ser viviente
        zoología: '',
        zoológico: '',
        protozoo: ''
        

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Comprensión - Ejercicio 10",
                nivel: "Basico",
                modulo: "5",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h2>Ejercicio de Comprensión</h2>
            <p><strong>Ejercicio 10: Incremente su vocabulario</strong></p>


            <Row>
                <Col md={6}>

                    <Form className='border p-3'>

                        <p><strong>Raiz: physis = naturaleza.</strong></p>
                        <p>Física: Ciencia que estudia los cuerpos de la naturaleza <br />
                            Fisonomía: Aspecto físico</p>



                        <Form>
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="fisioterapia"
                                    value={respuestas.fisioterapia}
                                    onChange={handleChange}
                                    placeholder="fisioterapia"
                                />
                            </FormGroup>
                           <p>
                            Fisiología: Ciencia que estudia la vida.</p> 
                        </Form>

                        <br />

                        <Form className='border p-3'>




                            <p><strong>Raíz: algos - Significado: Dolor</strong></p>
                            <p>Palabras derivadas: <br />
                                Neuralgia= dolor neural o de los nervios. <br />
                                Nostalgia= dolor nuestro, propio, interno</p>

                            <p>Deduzca:</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="analgesico"
                                    value={respuestas.analgesico}
                                    onChange={handleChange}
                                    placeholder="Analgésico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="gastralgia"
                                    value={respuestas.gastralgia}
                                    onChange={handleChange}
                                    placeholder="Gastralgia"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: argos - cratos - Significado: Poder, gobierno</strong></p>
                            <p>Ejemplo: <br />
                                Monarquía: poder de uno sólo. <br />
                                Anarquía: sin poder alguno. <br />
                                Autócrata: poder por el mismo</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="oligarquia"
                                    value={respuestas.oligarquia}
                                    onChange={handleChange}
                                    placeholder="Oligarquía"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="aristocracia"
                                    value={respuestas.aristocracia}
                                    onChange={handleChange}
                                    placeholder="Aristocracia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="plutocracia"
                                    value={respuestas.plutocracia}
                                    onChange={handleChange}
                                    placeholder="Plutocracia"
                                />
                            </FormGroup>
                        </Form>
                        <br />


                        <Form className='border p-3'>

                            <p><strong>Raíz: aer: aire</strong></p>
                            <p>Ejemplo:
                                <br />
                                Aerodinámica: Movimiento del aire <br />
                                Aerolito: Piedra del aire <br />
                                Aeromancia: Adivinación o predicción por el aire <br />
                                Aeronauta: Navegante del aire.</p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="aeropuerto"
                                    value={respuestas.aeropuerto}
                                    onChange={handleChange}
                                    placeholder="Aeropuerto"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="aerobico"
                                    value={respuestas.aerobico}
                                    onChange={handleChange}
                                    placeholder="Aeróbico"
                                />
                            </FormGroup>

                        </Form>
                        <br />

                        <Form className='border p-3'>






                            <p><strong>Raíz: anti: en lugar de, opuesto, contrario</strong></p>
                            <p>Ejemplo:
                                <br />
                                Antónimo: en lugar del igual <br />
                                Antonomasia: en lugar del nombre <br />
                            </p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="antivirus"
                                    value={respuestas.antivirus}
                                    onChange={handleChange}
                                    placeholder="Antivirus"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="antitesis"
                                    value={respuestas.antitesis}
                                    onChange={handleChange}
                                    placeholder="Antitesis"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="antideslizante"
                                    value={respuestas.antideslizante}
                                    onChange={handleChange}
                                    placeholder="Antideslizante"
                                />
                            </FormGroup>

                        </Form>    </Form>
                </Col>
                <Col md={6}>
                    <Form>
                        <Form className='border p-3'>
                            <p><strong>Prefijo: a, an: negación, privación, sin.</strong></p>
                            <p>Ateo: sin Dios
                                <br />
                                Acéfalo: Sin cabeza <br />
                                Apatía: sin empatía <br />
                            </p>

                            <FormGroup className="mb-3 ">
                                <FormControl
                                    type="text"
                                    name="anarquia"
                                    value={respuestas.anarquia}
                                    onChange={handleChange}
                                    placeholder="Anarquía"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="apatia"
                                    value={respuestas.apatia}
                                    onChange={handleChange}
                                    placeholder="Apatía"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="apnea"
                                    value={respuestas.apnea}
                                    onChange={handleChange}
                                    placeholder="Apnea"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="afonia"
                                    value={respuestas.afonia}
                                    onChange={handleChange}
                                    placeholder="Afonia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="amorfo"
                                    value={respuestas.amorfo}
                                    onChange={handleChange}
                                    placeholder="Amorfo"
                                />
                            </FormGroup>

                        </Form>
                        <br />
                        <Form className='border p-3'>

                            <p><strong>Raíz: anthropos: hombre</strong></p>
                            <p>Ejemplo:
                                <br />
                                Misántropo: Huye de los hombres. <br />
                                Filántropo: Que ama a los hombres. <br />
                            </p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="antropomorfo"
                                    value={respuestas.antropomorfo}
                                    onChange={handleChange}
                                    placeholder="Antropomorfo"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="antropologia"
                                    value={respuestas.antropologia}
                                    onChange={handleChange}
                                    placeholder="Antropología"
                                />
                            </FormGroup>
                        </Form>
                        <br />
                        <Form className='border p-3'>


                            <p><strong>Raíz: auto, por sí mismo</strong></p>
                            <p>Ejemplo:
                                <br />
                                Autónomos: Se gobierna a sí mismo. <br />
                                Autóctono: Propia de una raza. <br />
                                Autómata: Se mueve por sí mismo. <br />
                            </p>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="automovil"
                                    value={respuestas.automovil}
                                    onChange={handleChange}
                                    placeholder="Automóvil"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="automatico"
                                    value={respuestas.automatico}
                                    onChange={handleChange}
                                    placeholder="Automático"
                                />
                            </FormGroup>

                        </Form>
                        <br />
                        <Form className='border p-3'>



                            <p><strong>Raíz: biblio, libro, papiro</strong></p>


                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="biblioteca"
                                    value={respuestas.biblioteca}
                                    onChange={handleChange}
                                    placeholder="Biblioteca"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="biografia"
                                    value={respuestas.biografia}
                                    onChange={handleChange}
                                    placeholder="Biografía"
                                />
                            </FormGroup>
                        </Form>
                        <br />
                        <Form className='border p-3'>



                            <p><strong>Raíz: bios: vida</strong></p>


                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="biologia"
                                    value={respuestas.biologia}
                                    onChange={handleChange}
                                    placeholder="Biología"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="bioquimica"
                                    value={respuestas.bioquimica}
                                    onChange={handleChange}
                                    placeholder="Bioquímica"
                                />
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="biografo"
                                    value={respuestas.biografo}
                                    onChange={handleChange}
                                    placeholder="Biógrafo"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="anfibio"
                                    value={respuestas.anfibio}
                                    onChange={handleChange}
                                    placeholder="Anfibio"
                                />
                            </FormGroup>
                        </Form>





                    </Form>
                </Col>
                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M10_Ejercicio7;
