import React, { useState } from 'react';
import AlumnoForm from './DetailsStudent/AlumnoForm'; // Asegúrate de que las rutas sean correctas
import RegistroPracticas from './DetailsStudent/RegistroPracticas';
import SesionesAlumno from './DetailsStudent/SesionesAlumno';
import { Button, Nav, NavItem, Navbar, Container } from 'react-bootstrap';
import './DetalleAlumno.css'; // Asegúrate de que los estilos sean correctos
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Importa Link y useNavigate de React Router

const DetalleAlumno = () => {
  const [activeTab, setActiveTab] = useState('detalles'); // Estado para la pestaña activa
  const navigate = useNavigate(); // Hook para navegación
  const location = useLocation(); // Hook para obtener la ruta actual

  const renderContent = () => {
    switch (activeTab) {
      case 'detalles':
        return <AlumnoForm />;
      case 'practicas':
        return <RegistroPracticas />;
      case 'sesiones':
        return <SesionesAlumno />;
      default:
        return <AlumnoForm />;
    }
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" className="mb-4">
        <Container>
          <Navbar.Brand>Detalles del Alumno</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Item>
              <Button
                variant={activeTab === 'detalles' ? 'primary' : 'outline-primary'}
                onClick={() => setActiveTab('detalles')}
              >
                Ficha del Alumno
              </Button>
            </Nav.Item>
            <Nav.Item>
              <Button
                variant={activeTab === 'sesiones' ? 'primary' : 'outline-primary'}
                onClick={() => setActiveTab('sesiones')}
              >
                Sesiones del Alumno
              </Button>
            </Nav.Item>
            
            <Nav.Item>
              <Button
                variant={activeTab === 'practicas' ? 'primary' : 'outline-primary'}
                onClick={() => setActiveTab('practicas')}
              >
                Registro de Prácticas
              </Button>
            </Nav.Item>
            <Nav className="me-auto">
            <Button variant="outline-light" onClick={() => navigate(-1)}>Volver</Button>
          </Nav>
            
          </Nav>
        </Container>
      </Navbar>

      <div className="container">
        {renderContent()}
      </div>
    </div>
  );
};

export { DetalleAlumno };
