import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

const EstatusModal = ({ show, onHide, estatus, onEstatusChange, comentario, onComentarioChange, onSave }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Cambiar Estatus del Alumno</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="mb-3">
        <Form.Label>Estatus</Form.Label>
        <Form.Control as="select" value={estatus} onChange={(e) => onEstatusChange(e.target.value)}>
          <option>Activo</option>
          <option>Inactivo</option>
          <option>Baja Temporalidad</option>
          <option>Baja Definitiva</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Comentario</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={comentario}
          onChange={(e) => onComentarioChange(e.target.value)}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>Cerrar</Button>
      <Button variant="primary" onClick={onSave}>Guardar Cambios</Button>
    </Modal.Footer>
  </Modal>
);

export default EstatusModal;
