import { useRoutes } from 'react-router-dom'
import LoginView from '../../routes/LoginView'

const PublicRoutes = () => {
  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginView />
    },
    {
      path: '/*',
      element: <LoginView />
    }
  ]);

  return routes;
}

export { PublicRoutes };
