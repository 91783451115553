import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Container, Modal, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, runTransaction, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase'; // Verificar rutas de importación
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddMaterialTeorico = () => {
    const [lecciones, setLecciones] = useState([]);
    const [leccionActual, setLeccionActual] = useState({ level: '', titulo: '', descripcion: '', imagenDestacada: '' });
    const [imageFile, setImageFile] = useState(null);
    const [filterLevel, setFilterLevel] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingLesson, setEditingLesson] = useState(null);

    const handleEdit = (leccion) => {
        setEditingLesson(leccion);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
        setEditingLesson(null); // Limpiar el formulario al cerrar la modal
    };
    



    useEffect(() => {
        const obtenerLecciones = async () => {
            const querySnapshot = await getDocs(collection(db, 'Lecciones'));
            setLecciones(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), level: doc.data().level.toString() }))); // Ensure level is a string for filtering
        };
        obtenerLecciones();
    }, []);

      // Mano de cambio en el filtro
      const handleFilterChange = (e) => {
        setFilterLevel(e.target.value);
    };

     // Renderiza lecciones filtradas o todas si no hay filtro seleccionado
     const filteredLecciones = lecciones.filter(leccion => 
        filterLevel === '' || leccion.level === parseInt(filterLevel, 10) // Asegura comparación correcta
    );

    const handleImageChange = (event) => {
        if (event.target.files[0]) {
            setImageFile(event.target.files[0]);
        }
    };


    
    const handleAddLeccion = async (event) => {
        event.preventDefault();
        if (!leccionActual.level || !leccionActual.titulo || !leccionActual.descripcion || !imageFile) {
            alert('Por favor, completa todos los campos antes de guardar.');
            return;
        }

        const contadorRef = doc(db, "Contadores", `nivel_${leccionActual.level}`);
        try {
            await runTransaction(db, async (transaction) => {
                const contadorDoc = await transaction.get(contadorRef);
                let nuevoContador = 1;
                if (contadorDoc.exists()) {
                    nuevoContador = contadorDoc.data().contador + 1;
                } else {
                    transaction.set(contadorRef, { contador: nuevoContador });
                }

                const imageExtension = imageFile.name.split('.').pop();
                const imageName = `${nuevoContador}-${leccionActual.titulo.replace(/ /g, '_')}-${Date.now()}.${imageExtension}`;
                const storagePath = `lecciones/${imageName}`;
                const imageRef = storageRef(storage, storagePath);

                const snapshot = await uploadBytes(imageRef, imageFile);
                const downloadURL = await getDownloadURL(snapshot.ref);
                const updatedLeccion = {
                    ...leccionActual,
                    level: leccionActual.level.toString(), // Ensure level is stored as a string
                    imagenDestacada: downloadURL,
                    id: nuevoContador.toString()
                };

                transaction.set(doc(collection(db, 'Lecciones'), updatedLeccion.id), updatedLeccion);
                transaction.update(contadorRef, { contador: nuevoContador });

                setLecciones([...lecciones, updatedLeccion]);
                setLeccionActual({ level: '', titulo: '', descripcion: '', imagenDestacada: '' });
                setImageFile(null);
                alert('Lección guardada con éxito');
            });
        } catch (error) {
            console.error("Error al guardar la lección:", error);
            alert('Ocurrió un error al guardar la lección. Por favor, intenta nuevamente.');
        }
    };

    // const handleEdit = (leccion) => {
    //     setLeccionActual(leccion);
    //     setImageFile(null); // Clear any selected file
    // };

    const handleDelete = async (id) => {
        await deleteDoc(doc(db, 'Lecciones', id));
        setLecciones(lecciones.filter(leccion => leccion.id !== id));
    };

    const getNextLessonId = (level) => {
        // Filtra lecciones por el nivel y encuentra el máximo ID
        const maxId = lecciones
            .filter(leccion => leccion.level === level)
            .reduce((max, leccion) => leccion.id > max ? leccion.id : max, 0);
        return maxId + 1;
    };

    

const handleUpdateLesson = async (leccion) => {
    if (!leccion.level || !leccion.titulo || !leccion.descripcion) {
        alert('Por favor, completa todos los campos requeridos.');
        return;
    }

    try {
        const leccionRef = doc(db, 'Lecciones', leccion.id);
        const updatedFields = {
            level: leccion.level,
            titulo: leccion.titulo,
            descripcion: leccion.descripcion
        };

        // Carga y actualiza la imagen solo si se ha seleccionado un archivo nuevo
        if (imageFile) {
            const imageUrl = await uploadImage(leccion.id);
            if (imageUrl) {
                updatedFields.imagenDestacada = imageUrl;
            }
        }

        await updateDoc(leccionRef, updatedFields);
        setLecciones(lecciones.map(l => l.id === leccion.id ? { ...l, ...updatedFields } : l)); // Actualiza el estado local
        handleCloseModal();
        alert('Lección actualizada con éxito.');
    } catch (error) {
        console.error("Error al actualizar la lección:", error);
        alert('Ocurrió un error al actualizar la lección. Por favor, intenta nuevamente.');
    }
};


const uploadImage = async (lessonId) => {
    if (!imageFile) return null;

    const imageExtension = imageFile.name.split('.').pop();
    const imageName = `${lessonId}-${Date.now()}.${imageExtension}`;
    const storagePath = `lecciones/${imageName}`;
    const imageRef = storageRef(storage, storagePath);

    const snapshot = await uploadBytes(imageRef, imageFile);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
};

    
    
    
    

    return (
        <Container>
            <div>
                <h2>Añadir Material Teórico</h2>
                <Form onSubmit={handleAddLeccion}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nivel</Form.Label>
                        <Form.Select value={leccionActual.level} onChange={(e) => setLeccionActual({ ...leccionActual, level: e.target.value })}>
                            <option value="">Selecciona un nivel</option>
                            <option value="1">Nivel 1</option>
                            <option value="2">Nivel 2</option>
                            <option value="3">Nivel 3</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        
                        <Form.Label>Título</Form.Label>
                        <Form.Control
                            type="text"
                            value={leccionActual.titulo}
                            onChange={(e) => setLeccionActual({ ...leccionActual, titulo: e.target.value })}
                            placeholder="Ingresa el título de la lección"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={leccionActual.descripcion}
                            onChange={(e) => setLeccionActual({ ...leccionActual, descripcion: e.target.value })}
                            placeholder="Ingresa una descripción"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Imagen Destacada</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={handleImageChange}
                            accept="image/jpeg, image/jpg"
                        />
                    </Form.Group>
                    
                    <Button variant="primary" type="submit">
                        {leccionActual.id ? 'Actualizar' : 'Añadir'}
                    </Button>
                </Form>
                <hr />
                <h3>Listado de Lecciones</h3>
            <Form.Group className="mb-3">
                <Form.Label>Filtrar por Nivel</Form.Label>
                <Form.Select value={filterLevel} onChange={(e) => setFilterLevel(e.target.value)}>
                    <option value="">Todos los Niveles</option>
                    <option value="1">Nivel 1</option>
                    <option value="2">Nivel 2</option>
                    <option value="3">Nivel 3</option>
                </Form.Select>
            </Form.Group>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nivel</th>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {lecciones.filter(leccion => filterLevel === '' || leccion.level === filterLevel).map((leccion) => (
                        <tr key={leccion.id}>
                            <td>{leccion.level}</td>
                            <td>{leccion.id}</td>
                            <td>{leccion.titulo}</td>
                            <td>
                                <Button variant="info" onClick={() => handleEdit(leccion)}>Editar</Button>
                                <Button variant="danger" onClick={() => handleDelete(leccion.id)}>Eliminar</Button>
                            </td>
        </tr>
    ))}
</tbody>
                </Table>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
        <Modal.Title>Editar Lección</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
            <FormGroup className="mb-3">
                <FormLabel>Nivel</FormLabel>
                <Form.Select value={editingLesson?.level} onChange={(e) => setEditingLesson({ ...editingLesson, level: e.target.value })}>
                    <option value="1">Nivel 1</option>
                    <option value="2">Nivel 2</option>
                    <option value="3">Nivel 3</option>
                </Form.Select>
            </FormGroup>
            <FormGroup className="mb-3">
                <FormLabel>Título</FormLabel>
                <FormControl type="text" value={editingLesson?.titulo} onChange={(e) => setEditingLesson({ ...editingLesson, titulo: e.target.value })} />
            </FormGroup>
            <FormGroup className="mb-3">
                <FormLabel>Descripción</FormLabel>
                <FormControl as="textarea" rows={3} value={editingLesson?.descripcion} onChange={(e) => setEditingLesson({ ...editingLesson, descripcion: e.target.value })} />
            </FormGroup>
            <FormGroup className="mb-3">
                <FormLabel>Imagen Destacada</FormLabel>
                <FormControl type="file" onChange={handleImageChange} accept="image/*" />
            </FormGroup>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>Cerrar</Button>
        <Button variant="primary" onClick={() => handleUpdateLesson(editingLesson)}>Guardar Cambios</Button>
    </Modal.Footer>
</Modal>
        </Container>

        
    );
};

export { AddMaterialTeorico };
