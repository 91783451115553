import React from 'react';
import { useRoutes } from 'react-router-dom';
import DashboardView from '../../routes/dashboardView';
import { SignOutView } from '../../routes/SignOutView';
import { PublicProfileView } from '../../routes/PublicProfileView';
import { CuadernoDigital } from '../../routes/CuadernoDigital';
import { MaterialTeorico } from '../../routes/MaterialTeorico';
import { MaterialDidactico } from '../../routes/MaterialDidactico';
import { ChooseUsernameView } from '../../routes/ChooseUsernameView';
import { EditProfileView } from '../../routes/EditProfileView';
import { MasterView } from '../../routes/MasterView';
import { RegistroDePracticas } from '../../routes/RegistroDePracticas';
import { ListadoAlumnos } from '../../routes/ListadoAlumnos';
import { DetalleAlumno } from '../../routes/DetalleAlumno';
import { AddMaterialDidactico } from '../../routes/Components/AddMaterialDidactico';
import { AddMaterialTeorico } from '../../routes/Components/AddMaterialTeorico';
import { AddCuadernoDigital } from '../../routes/Components/AddCuadernoDigital';
import { ViewMaterialTeorico } from '../../routes/ViewMaterialTeorico';
import { DetalleMaterialTeorico } from '../../routes/DetalleMaterialTeorico';
import { M1_Ejercicio10 } from '../../components/evaluaciones/Basico/Modulo1/Ejercicio10';
import { Evaluacion2 } from '../../components/evaluaciones/Evaluacion2';
import { DetalleEvaluacion } from '../../routes/DetalleEvaluacion';
import EvaluacionBasica from '../../routes/EvaluacionBasica';
import EvaluacionIntermedia from '../../routes/EvaluacionIntermedia';
import EvaluacionAvanzada from '../../routes/EvaluacionAvanzada';
import AlumnoForm from '../../routes/DetailsStudent/AlumnoForm';
import EstatusModal from '../../routes/DetailsStudent/EstatusModal';
import FichasTable from '../../routes/DetailsStudent/FichasTable';
import RegistroPracticas from '../../routes/DetailsStudent/RegistroPracticas';
import SesionesAlumno from '../../routes/DetailsStudent/SesionesAlumno';
import AudioPlayer from '../../routes/Components/AudioPlayer';
import EstimulacionNeuronal from '../../routes/Components/EstimulacionNeuronal';

const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/master', element: <MasterView /> },
    { path: '/dashboard', element: <DashboardView /> },
    { path: '/material-didactico', element: <MaterialDidactico /> },
    { path: '/material-teorico', element: <MaterialTeorico /> },
    { path: '/cuaderno-digital', element: <CuadernoDigital /> },
    { path: '/dashboard/profile', element: <EditProfileView /> },
    { path: '/signout', element: <SignOutView /> },
    { path: '/u/:username', element: <PublicProfileView /> },
    { path: '/choose.username', element: <ChooseUsernameView /> },
    { path: '/listado-alumnos', element: <ListadoAlumnos /> },
    { path: '/*', element: <DashboardView /> },
    { path: 'dashboard/listado-alumnos/detalle-alumno/:id', element: <DetalleAlumno /> },
    { path: 'dashboard/listado-alumnos/registro-de-practicas/:id', element: <RegistroDePracticas /> },
    { path: 'dashboard/master/agregar-material-didactico', element: <AddMaterialDidactico /> },
    { path: 'dashboard/master/agregar-material-teorico', element: <AddMaterialTeorico /> },
    { path: 'dashboard/master/agregar-cuaderno-digital', element: <AddCuadernoDigital /> },
    { path: 'dashboard/master/ver-material-teorico/:id', element: <ViewMaterialTeorico /> },
    { path: 'dashboard/material-teorico', element: <MaterialTeorico /> },
    { path: 'dashboard/material-teorico/detalle/:nivel', element: <DetalleMaterialTeorico /> },
    { path: '/M1_Ejercicio10', element: <M1_Ejercicio10 /> },
    { path: '/evaluacion2', element: <Evaluacion2 /> },
    { path: '/evaluacion/:id', element: <DetalleEvaluacion /> },
    { path: '/evaluacion-basica', element: <EvaluacionBasica /> },
    { path: '/evaluacion-intermedia', element: <EvaluacionIntermedia /> },
    { path: '/evaluacion-avanzada', element: <EvaluacionAvanzada /> },
    { path: '/AlumnoForm', element: <AlumnoForm />},
    { path: '/EstatusModal', element: <EstatusModal />},
    { path: '/FichasTable', element: <FichasTable />},
    { path: '/RegistroPractica', element: <RegistroPracticas />},
    { path: '/SesionesAlumno', element:<SesionesAlumno />},
    { path: '/EstimulacionNeuronal', element:<EstimulacionNeuronal/> }
  ]);

  return routes;
};

export { AppRoutes };
