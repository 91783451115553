import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, setDoc, getFirestore } from 'firebase/firestore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useState } from 'react';
import { ButtonLoader } from '../ButtonLoader';

const MySwal = withReactContent(Swal);

const FormStoreUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [showSubmitLoaderButton, setShowSubmitLoaderButton] = useState(false);

  const auth = getAuth();
  const db = getFirestore();

  const handlerOnSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      MySwal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor completa todos los campos',
      });
      return;
    }

    try {
      setShowSubmitLoaderButton(true);

      // Crear usuario con Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Preparar datos para Firestore
      const userData = {
        email: email,
        username: username || '',
        displayName: displayName || '',
        // Puedes agregar más campos si es necesario
      };

      // Guardar en Firestore
      await setDoc(doc(db, 'users', user.uid), userData);

      // Notificar éxito
      MySwal.fire({
        icon: 'success',
        title: 'Usuario creado',
        text: 'El usuario ha sido creado exitosamente',
      });
    } catch (error) {
      console.error('Error creando el usuario:', error);

      // Notificar error
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo crear el usuario',
      });
    } finally {
      setShowSubmitLoaderButton(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handlerOnSubmit(e);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handlerOnSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Correo electrónico</label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Contraseña</label>
            <input
              type="password"
              className="form-control"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Nombre de usuario</label>
            <input
              type="text"
              className="form-control"
              id="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="displayName" className="form-label">Nombre para mostrar</label>
            <input
              type="text"
              className="form-control"
              id="displayName"
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
          {showSubmitLoaderButton ? (
            <ButtonLoader />
          ) : (
            <button type="submit" className="btn btn-primary">Crear Usuario</button>
          )}
        </form>
      </div>
    </div>
  );
};

export { FormStoreUser };
