import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Container, ListGroup, ListGroupItem } from 'react-bootstrap';
import { collection, query, where, getDocs, doc, getDoc, getFirestore, orderBy } from 'firebase/firestore';
import { AppContext } from '../Context';


const RegistroDePracticas = () => {
  const { id } = useParams();
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [key, setKey] = useState('1');
  const [userName, setUserName] = useState('');
  const db = getFirestore();
  const { currentUser } = useContext(AppContext); // Usa AppContext para obtener currentUser


  useEffect(() => {
    const obtenerEvaluaciones = async () => {
      const q = query(collection(db, "evaluaciones"), where("userId", "==", id));
      const querySnapshot = await getDocs(q);
      const datos = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        respuestas: doc.data().respuestas || [] // Asegura que respuestas siempre sea un array
      }));
      setEvaluaciones(datos);
    };
  
    if (currentUser && id) {
      obtenerEvaluaciones();
    }
  }, [id, currentUser, db]);
  

  const renderRespuestas = (respuestas) => {
    // Si las respuestas son un array, únelas con comas
    if (Array.isArray(respuestas)) {
      return respuestas.map((respuesta, index) => {
        if (typeof respuesta === 'object' && respuesta !== null) {
          // Si es un objeto dentro del array, renderiza sus pares clave-valor
          return <div key={index}>{Object.entries(respuesta).map(([key, value], idx) => <span key={idx}>{`${key}: ${value}; `}</span>)}</div>;
        }
        return <span key={index}>{respuesta}; </span>;
      });
    } else if (respuestas && typeof respuestas === 'object') {
      // Si es un objeto, muestra cada clave y valor
      return Object.entries(respuestas).map(([key, value], index) => <div key={index}>{`${key}: ${value}; `}</div>);
    }
    // Manejo por defecto si no hay respuestas o es una cadena de texto simple
    return respuestas || 'No hay respuestas';
  };
  

  return (
    <Container>
      <h2>Registro de Prácticas de {userName}</h2>
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        {Array.from(new Set(evaluaciones.map(e => e.nivel))).sort().map(nivel => (
          <Tab eventKey={nivel} title={`Nivel  ${nivel}`} key={nivel}>
            <ListGroup variant="flush">
              {evaluaciones.filter(e => e.nivel === nivel).map(evaluacion => (
                <ListGroupItem key={evaluacion.id}>
                  <h5>{evaluacion.nombre || 'Nombre no disponible'}</h5>
                  <p><strong>Respuestas:</strong> {renderRespuestas(evaluacion.respuestas)}</p>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export { RegistroDePracticas };
