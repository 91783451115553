import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M9_Ejercicio7 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({
        // Raíz: physis = naturaleza
        física: 'Ciencia que estudia los cuerpos de la naturaleza',
       licencia: '',
       licito: '',
       licor: '',
       licuar: '',
       liquidez: '',
       colocar: '',
       dislocar: '',
       locomocion: '',
       locatario: '',
       longitud: '',

        

    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 7 - Ejercicio de comprensión",
                nivel: "intermedio",
                modulo: "9",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h2>Ejercicio de Comprensión</h2>
            <p><strong>Ejercicio 7: Raíces Latinas</strong></p>


            <Row>
                <Col md={12}>

                    <Form className='border p-3'>

                        <p><strong>Raiz: licet = permitido</strong></p>
                       
                        <Form>
                            
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="licencia"
                                    value={respuestas.licencia}
                                    onChange={handleChange}
                                    placeholder="Licencia"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="licito"
                                    value={respuestas.licito}
                                    onChange={handleChange}
                                    placeholder="Licito"
                                />
                            </FormGroup>
                          
                        </Form>
                        </Form>

                        <br />

                        <Form className='border p-3'>




                            <p><strong>Raíz: liquodos = líquido</strong></p>
                           
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="licor"
                                    value={respuestas.licor}
                                    onChange={handleChange}
                                    placeholder="Licor"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="licuar"
                                    value={respuestas.licuar}
                                    onChange={handleChange}
                                    placeholder="Licuar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="liquidez"
                                    value={respuestas.liquidez}
                                    onChange={handleChange}
                                    placeholder="Liquidez"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: locus = lugar, sitio</strong></p>
                           
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="colocar"
                                    value={respuestas.colocar}
                                    onChange={handleChange}
                                    placeholder="Colocar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="dislocar"
                                    value={respuestas.dislocar}
                                    onChange={handleChange}
                                    placeholder="Dislocar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="locomocion"
                                    value={respuestas.locomocion}
                                    onChange={handleChange}
                                    placeholder="Locomoción"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="locatario"
                                    value={respuestas.locatario}
                                    onChange={handleChange}
                                    placeholder="Locatario"
                                />
                            </FormGroup>
                        </Form>
                        <br />


                        <Form className='border p-3'>

                            <p><strong>Raíz: longus = largo</strong></p>
                           
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="longitud"
                                    value={respuestas.longitud}
                                    onChange={handleChange}
                                    placeholder="Longitud"
                                />
                            </FormGroup>

                            <p><strong>Raíz: luctus = pena, tristeza, llanto</strong></p>
                           
                        </Form>
                        <br />

                          
                </Col>
               
                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M9_Ejercicio7;
