import { useState } from "react";
import { ButtonLoader } from "../ButtonLoader";
import { singIn } from "../../firebase/firebase";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function FormLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSubmitLoaderButton, setShowSubmitLoaderButton] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlerOnSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
        MySwal.fire({
            icon: "warning",
            title: "Campos incompletos",
            text: "Por favor completa todos los campos",
        });
        return;
    }

    try {
        setShowSubmitLoaderButton(true);
        console.warn("handlerOnSubmit", email, password);

        await singIn(email, password);

        // Redirigir o actualizar el estado de sesión aquí si es necesario

    } catch (err) {
        console.error("Error on FormLogin.handlerOnSubmit", err);
        let errorMessage = "Correo o contraseña incorrectos";

        if (err.code === "auth/user-not-found") {
            errorMessage = "Usuario no encontrado";
        } else if (err.code === "auth/wrong-password") {
            errorMessage = "Contraseña incorrecta";
        } else if (err.code === "auth/invalid-credential") {
            errorMessage = "Credenciales inválidas";
        } else if (err.code) {
            errorMessage = `Error de autenticación: ${err.message}`;
        }

        MySwal.fire({
            icon: "error",
            title: "Error de autenticación",
            text: errorMessage,
        });
        return;
    } finally {
        setShowSubmitLoaderButton(false);
    }
};


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlerOnSubmit(e);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handlerOnSubmit}>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Correo electrónico
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Contraseña
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="exampleInputPassword1"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <span
                className="input-group-text"
                onClick={handleTogglePasswordVisibility}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </div>
          {showSubmitLoaderButton ? (
            <ButtonLoader />
          ) : (
            <button type="submit" className="btn btn-primary">
              Ingresar
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export { FormLogin };
