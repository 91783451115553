import { useContext, useState, useEffect } from "react";
import { Nav } from 'react-bootstrap';
import { FaBars } from "react-icons/fa";
import { AppContext } from "../../Context";
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import './Sidebar.css';
import { useNavigate } from "react-router-dom";



function NavItem({ to, children, onClick }) {
    return (
        <Nav.Link as={Link} to={to} className="nav-link" onClick={onClick}>
            {children}
        </Nav.Link>
    );
}

function LoginNavbar() {
    const { isAuth, logOut } = useContext(AppContext);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const unsubscribeDoc = onSnapshot(userDocRef, (doc) => {
                    if (doc.exists()) {
                        setCurrentUser(doc.data());
                    } else {
                        console.log("No such document!");
                    }
                }, (error) => {
                    console.log("Error getting document:", error);
                });
                return () => unsubscribeDoc();
            } else {
                setCurrentUser(null);
            }
        });
        return () => unsubscribe();
    }, [auth, db]);

    const handleLogout = async () => {
        await logOut();
        toggleSidebar(); // Asegúrate de cerrar el sidebar al salir
        navigate('/');
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className={`sidebar bg-primary ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-brand">
                    <h2>Tecnica</h2>
                    {currentUser && (
                        <div className="profile-info">
                            <img src={currentUser.profilePicture || 'default-profile.png'} alt="Profile" className="profile-img" />
                            <p>{currentUser.displayName || 'Usuario'}</p>
                        </div>
                    )}
                </div>
                <Nav className="flex-column">
                    <NavItem to="/dashboard" onClick={toggleSidebar}>Dashboard</NavItem>
                    <NavItem to="/dashboard/profile" onClick={toggleSidebar}>Perfil</NavItem>
                    {currentUser && currentUser.isMaster && (
                        <NavItem to="/master" onClick={toggleSidebar}>Master</NavItem>
                    )}
                    {isAuth && (
                        <NavItem to="/signout" onClick={handleLogout}>Cerrar sesión</NavItem>
                    )}
                </Nav>
            </div>
            <div className="sidebar-toggle" onClick={toggleSidebar}>
                <FaBars />
            </div>
        </>
    );
}


export { LoginNavbar };
