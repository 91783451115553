import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { Container } from './components/Container';
import { AppProvider } from './Context';
import { App } from './Pages/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <Container>
        <App />
      </Container>
    </AppProvider>
  </BrowserRouter>
);

reportWebVitals();
