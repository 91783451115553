
import { initializeApp } from "firebase/app";
import {
        getAuth,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        onAuthStateChanged
} from "firebase/auth";
import {
        getStorage,
        ref,
        uploadBytes,
        getDownloadURL,
        getBytes,
} from "firebase/storage"
import {
        getFirestore,
        collection,
        addDoc,
        getDocs,
        getDoc,
        doc,
        query,
        where,
        updateDoc,
        setDoc,
        deleteDoc,
        querySnapshot,
} from "firebase/firestore";



/*Este fragmento de código JavaScript define una constante firebaseConfig que es un objeto que contiene parámetros de configuración para un proyecto de Firebase.
 Los valores de estos parámetros se obtienen a través de variables de entorno que comienzan con REACT_APP_. 
 Estas variables de entorno suelen establecerse en un archivo .env o a través de otros medios, lo que permite personalizar la configuración para diferentes entornos.*/
const firebaseConfig = {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINSENDERID,
        appId: process.env.REACT_APP_APPID,

};



// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExists(uid) {
        const docRef = doc(db, 'users', uid);
        const res = await getDoc(docRef);
        console.log = (res);
        return res.exists();
}

export const getCurrentUser = () => {
        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, user => {
                unsubscribe(); // Clean up the subscription
                resolve(user);
            }, reject);
        });
    };

    export const createAuthUser = async (email, password) => {
        return await createUserWithEmailAndPassword(auth, email, password);
    };

export async function singIn(email, password) {
        try {
                const res = await signInWithEmailAndPassword(auth, email, password);
                return res.user;

        } catch (error) {
                console.error(error);
        }
}

export async function existsUsername(username) {
        const users = [];
        const docsRef = collection(db, 'users');
        const q = query(docsRef, where('username', '==', username));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(doc => {
                users.push(doc.data());
        });

        return users.length > 0 ? users[0].uid : null;

}


export async function registerNewUser(user) {
        try {
                const collectionRef = collection(db, "users");
                const docRef = doc(collectionRef, user.uid);
                await setDoc(docRef, user);

        } catch (error) { }
}

export async function storeUser(docId, data) {
        try {
                const collectionRef = collection(db, "users");
                const docRef = doc(collectionRef, docId);
                await setDoc(docRef, data);
        } catch (error) {
                console.error(error);
        }
}

export async function updateUser(user) {
        try {
                const collectionRef = collection(db, "users");
                const docRef = doc(collectionRef, user.uid);
                await setDoc(docRef, user);

        } catch (error) { }
}

export async function updateById(uid, data) {
        try {
                const docRef = doc(db, 'users', uid);
                await updateDoc(docRef, data);
        } catch (error) {
                console.error(error);
        }
}

export async function getUserInfo(uid) {
        try {
                const docRef = doc(db, 'users', uid);
                const document = await getDoc(docRef);
                console.log(uid);
                return document.data();


        } catch (error) {

        }
}


// export async function setUserProfilePhoto(uid, file) {
//         try {
//                 const imageRef = ref(storage, `images/${uid}`);
//                 const resUpload = await uploadBytes(imageRef, file);
//                 return resUpload;
//         } catch (error) {
//                 console.error(error);
//         }
// }

export async function setUserProfilePhoto(uid, file) {
        try {
            const imageRef = ref(storage, `profilePicture/${uid}`);
            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            console.log("URL de descarga:", downloadURL);
            return { downloadURL };
        } catch (error) {
            console.error("Error subiendo archivo de perfil:", error);
            return null;
        }
    }
    
    
    

export async function obtenerCantidadUsuarios() {


        const usuariosColeccion = collection(db, 'users'); // Asegúrate de que 'users' sea el nombre correcto de tu colección
        const snapshot = await getDocs(usuariosColeccion);
        const cantidadUsuarios = snapshot.size; // La propiedad size contiene el número de documentos en el snapshot

        return cantidadUsuarios;
};




export async function obtenerUsuarios() {
        const usuariosColeccion = collection(db, 'users'); // Asume 'users' es el nombre de tu colección
        const snapshot = await getDocs(usuariosColeccion);
        const usuarios = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return usuarios;
};

export async function logout() {
        await auth.signOut();

}





