import React, { useState } from 'react';
import { M1_Ejercicio10 } from '../components/evaluaciones/Basico/Modulo1/Ejercicio10';
import Ejercicio11 from '../components/evaluaciones/Basico/Modulo1/Ejercicio11';
import Ejercicio7 from '../components/evaluaciones/Basico/Modulo2/Ejercicio7';
import Ejercicio9 from '../components/evaluaciones/Basico/Modulo3/Ejercicio9';
import Ejercicio10 from '../components/evaluaciones/Basico/Modulo3/Ejercicio10';
import M4Ejercicio7 from '../components/evaluaciones/Basico/Modulo4/m4_ejercicio7';
import M4_Ejercicio8 from '../components/evaluaciones/Basico/Modulo4/M4Ejecicio8';
import M4_Ejercicio9 from '../components/evaluaciones/Basico/Modulo4/M4_Ejercicio9';
import M4_Ejercicio10 from '../components/evaluaciones/Basico/Modulo4/M4_Ejercicio10';
import M5_Ejercicio7 from '../components/evaluaciones/Basico/Modulo5/M5_Ejercicio7';
import M5_Ejercicio8 from '../components/evaluaciones/Basico/Modulo5/M5_Ejercicio8';
import M5_Ejercicio9 from '../components/evaluaciones/Basico/Modulo5/M5_Ejercicio9';
import M5_Ejercicio10 from '../components/evaluaciones/Basico/Modulo5/M5_Ejercicio10';


import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import './EvaluacionBasica.css'; // Asegúrate de crear este archivo CSS

// Agrupando ejercicios por módulo
const modulos = {
  Modulo1: [
    { id: 1, nombre: "Ejercicio 10", componente: M1_Ejercicio10 },
    { id: 2, nombre: "Ejercicio 11", componente: Ejercicio11 }
  ],
  Modulo2: [
    { id: 3, nombre: "Ejercicio 7", componente: Ejercicio7 }
  ],
  Modulo3: [
    { id: 4, nombre: "Ejercicio 9", componente: Ejercicio9 },
    { id: 5, nombre: "Ejercicio 10", componente: Ejercicio10 }
  ],
  Modulo4: [
    { id: 6, nombre: "Ejercicio 7", componente: M4Ejercicio7 },
    { id: 7, nombre: "Ejercicio 8", componente: M4_Ejercicio8 },
    { id: 8, nombre: "Ejercicio 9", componente: M4_Ejercicio9 },
    { id: 9, nombre: "Ejercicio 10", componente: M4_Ejercicio10 }
  ],
  Modulo5: [
    { id: 10, nombre: "Ejercicio 7", componente: M5_Ejercicio7 },
    { id: 11, nombre: "Ejercicio 8", componente: M5_Ejercicio8 },
    { id: 12, nombre: "Ejercicio 10", componente: M5_Ejercicio10}

  ]
};

const EvaluacionBasica = () => {
  const [modalShow, setModalShow] = useState(false);
  const [componenteActivo, setComponenteActivo] = useState(null);
  const [completadas, setCompletadas] = useState([]);

  const abrirModal = (Componente, id) => {
    if (!completadas.includes(id)) {
      setComponenteActivo(<Componente cerrarModal={() => setModalShow(false)} />);
      setModalShow(true);
    } else {
      alert("Esta evaluación ya ha sido completada.");
    }
  };

  const cerrarModal = () => {
    setModalShow(false);
  };

  return (
    <Container>
      <h1 className="text-center my-4">Nivel 1 Básico</h1>
      {Object.entries(modulos).map(([modulo, evaluaciones], index) => (
        <div key={index}>
          <h2 className='my-4'>{modulo}</h2>
          <Row xs={1} md={2} lg={4} className="g-4 mb-5">
            {evaluaciones.map(evaluacion => (
              <Col key={evaluacion.id}>
                <div className="evaluacion-item">
                  <h3>{evaluacion.nombre}</h3>
                  <Button 
                    onClick={() => abrirModal(evaluacion.componente, evaluacion.id)}
                    disabled={completadas.includes(evaluacion.id)}
                    className="w-100">
                    Realizar Ejercicio
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
      <Modal show={modalShow} onHide={cerrarModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Ejercicio en Curso</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componenteActivo}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EvaluacionBasica;

