import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { collection, query, where, getDocs, getFirestore, doc, updateDoc } from "firebase/firestore";

const useTeachers = () => {
  const [teachers, setTeachers] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const loadTeachers = async () => {
      try {
        const q = query(collection(db, "users"), where("teacher", "==", true));
        const querySnapshot = await getDocs(q);
        const loadedTeachers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTeachers(loadedTeachers);
      } catch (error) {
        console.error("Error fetching teachers: ", error);
      }
    };

    loadTeachers();
  }, [db]);

  return teachers;
};

const EditUserModal = ({ show, handleClose, user }) => {
  const teachers = useTeachers();
  const [editedUser, setEditedUser] = useState({
    userid: '',
    displayName: '',
    username: '',
    email: '',
    teacherId: '',
    isMaster: false,
    estatus: '',
    comentario: '',
    name: '',
    matricula: '',
    inscripcion: '',
    estrategia: '',
    date: '',
    age: '',
    country: '',
    city: '',
    phone: '',
    other: '',
    zone: '',
    level: '',
    activity: '',
    password: ''
  });

  useEffect(() => {
    if (user) {
      setEditedUser({
        userid: user.id || '',
        displayName: user.displayName || '',
        username: user.username || '',
        email: user.email || '',
        teacherId: user.teacherId || '',
        isMaster: user.isMaster || false,
        estatus: user.estatus || '',
        comentario: user.comentarioEstatus || '',
        name: user.name || '',
        matricula: user.matricula || '',
        inscripcion: user.inscripcion || '',
        estrategia: user.estrategia || '',
        date: user.date || '',
        age: user.age || '',
        country: user.country || '',
        city: user.city || '',
        phone: user.phone || '',
        other: user.other || '',
        zone: user.zone || '',
        level: user.level || '',
        activity: user.activity || '',
        password: user.password || ''
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedUser(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTeacherChange = (e) => {
    const teacherId = e.target.value;
    const teacher = teachers.find(t => t.id === teacherId);
    setEditedUser(prev => ({
      ...prev,
      teacherId: teacherId,
      asignTeacher: teacher ? teacher.displayName : ''
    }));
  };

  const handleSave = async () => {
    const db = getFirestore();
    const userRef = doc(db, "users", editedUser.userid);

    // try {
    //   await updateDoc(userRef, {
    //     displayName: editedUser.displayName,
    //     username: editedUser.username,
    //     email: editedUser.email,
    //     teacherId: editedUser.teacherId,
    //     asignTeacher: editedUser.asignTeacher,
    //     isMaster: editedUser.isMaster,
    //     estatus: editedUser.estatus,
    //     comentarioEstatus: editedUser.comentario,
    //     name: editedUser.name,
    //     matricula: editedUser.matricula,
    //     inscripcion: editedUser.inscripcion,
    //     estrategia: editedUser.estrategia,
    //     date: editedUser.date,
    //     age: editedUser.age,
    //     country: editedUser.country,
    //     city: editedUser.city,
    //     phone: editedUser.phone,
    //     other: editedUser.other,
    //     zone: editedUser.zone,
    //     level: editedUser.level,
    //     activity: editedUser.activity,
    //     password: editedUser.password
    //   });
    //   alert("Usuario actualizado con éxito");
    //   handleClose(); // Cerrar el modal después de la actualización
    // } catch (error) {
    //   console.error("Error al actualizar el usuario: ", error);
    //   alert("Ocurrió un error al intentar actualizar la información.");
    // }
  

  const updatedData = {
    ...(editedUser.displayName && { displayName: editedUser.displayName }),
    ...(editedUser.username && { username: editedUser.username }),
    ...(editedUser.email && { email: editedUser.email }),
    ...(editedUser.teacherId && { teacherId: editedUser.teacherId }),
    ...(editedUser.asignTeacher && { asignTeacher: editedUser.asignTeacher }),
    ...(editedUser.isMaster !== undefined && { isMaster: editedUser.isMaster }),
    ...(editedUser.estatus && { estatus: editedUser.estatus }),
    ...(editedUser.comentario && { comentarioEstatus: editedUser.comentario }),
    ...(editedUser.name && { name: editedUser.name }),
    ...(editedUser.matricula && { matricula: editedUser.matricula }),
    ...(editedUser.inscripcion && { inscripcion: editedUser.inscripcion }),
    ...(editedUser.estrategia && { estrategia: editedUser.estrategia }),
    ...(editedUser.date && { date: editedUser.date }),
    ...(editedUser.age && { age: editedUser.age }),
    ...(editedUser.country && { country: editedUser.country }),
    ...(editedUser.city && { city: editedUser.city }),
    ...(editedUser.phone && { phone: editedUser.phone }),
    ...(editedUser.other && { other: editedUser.other }),
    ...(editedUser.zone && { zone: editedUser.zone }),
    ...(editedUser.level && { level: editedUser.level }),
    ...(editedUser.activity && { activity: editedUser.activity }),
    ...(editedUser.password && { password: editedUser.password })
  };

  try {
    await updateDoc(userRef, updatedData);
    alert("Usuario actualizado con éxito");
    handleClose(); // Cerrar el modal después de la actualización
  } catch (error) {
    console.error("Error al actualizar el usuario: ", error);
    alert("Ocurrió un error al intentar actualizar la información.");
  }
};

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3" controlId="userid">
            <Form.Label>User ID</Form.Label>
            <Form.Control
              type="text"
              name="userid"
              value={editedUser.userid}
              onChange={handleChange}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="displayName">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              type="text"
              name="displayName"
              value={editedUser.displayName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={editedUser.username}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={editedUser.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formTeacherSelect">
            <Form.Label>Asignar Profesor</Form.Label>
            <Form.Control as="select" name="teacherId" value={editedUser.teacherId} onChange={handleTeacherChange}>
              <option value="">Ninguno</option>
              {teachers.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>{teacher.displayName}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="isMaster">
            <Form.Check 
              type="checkbox"
              label="Is Master"
              name="isMaster"
              checked={editedUser.isMaster}
              onChange={handleChange}
            />
          </Form.Group>

         
          <Form.Group controlId="formEstatus">
            <Form.Label>Estado</Form.Label>
            <Form.Control as="select" name="estatus" value={editedUser.estatus} onChange={handleChange}>
              <option value="">Seleccionar</option>
              <option value="Activos">Activos</option>
              <option value="Inactivo">Inactivo</option>
              <option value="Baja Temporal">Baja Temporal</option>
              <option value="Baja Definitiva">Baja Definitiva</option>
              <option value="Graduados">Graduados</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="comentario">
            <Form.Label>Comentario</Form.Label>
            <Form.Control
              type="text"
              name="comentario"
              value={editedUser.comentario || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={editedUser.name || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="matricula">
            <Form.Label>Matrícula</Form.Label>
            <Form.Control
              type="text"
              name="matricula"
              value={editedUser.matricula || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="inscripcion">
            <Form.Label>Inscripción</Form.Label>
            <Form.Control
              type="text"
              name="inscripcion"
              value={editedUser.inscripcion || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="estrategia">
            <Form.Label>Estrategia</Form.Label>
            <Form.Control
              type="text"
              name="estrategia"
              value={editedUser.estrategia || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="date">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={editedUser.date || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="age">
            <Form.Label>Edad</Form.Label>
            <Form.Control
              type="number"
              name="age"
              value={editedUser.age || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="country">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={editedUser.country || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="city">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={editedUser.city || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={editedUser.phone || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="other">
            <Form.Label>Datos adicionales</Form.Label>
            <Form.Control
              type="text"
              name="other"
              value={editedUser.other || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="zone">
            <Form.Label>Zona Horaria</Form.Label>
            <Form.Control
              type="text"
              name="zone"
              value={editedUser.zone || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="level">
            <Form.Label>Nivel</Form.Label>
            <Form.Control as="select" name="level" value={editedUser.level || ''} onChange={handleChange}>
              <option value="">Seleccionar</option>
              <option value="Nivel 1">Nivel 1</option>
              <option value="Nivel 2">Nivel 2</option>
              <option value="Nivel 3">Nivel 3</option>
            </Form.Control>
                  </Form.Group>

<Form.Group className="mb-3" controlId="activity">
  <Form.Label>Actividad</Form.Label>
  <Form.Control
    type="text"
    name="activity"
    value={editedUser.activity || ''}
    onChange={handleChange}
  />
</Form.Group>

<Form.Group className="mb-3" controlId="password">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={editedUser.password || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;

