import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDocs, getFirestore, collection, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Button, Table, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const SesionesAlumno = () => {
  const { id } = useParams();
  const db = getFirestore();
  const [fichas, setFichas] = useState([]);
  const [newFicha, setNewFicha] = useState({
    modulo: '',
    ejercicios: '',
    velocidad: '',
    comprension: '',
    lecturaRealizada: '',
    observaciones: ''
  });

  useEffect(() => {
    const fetchFichas = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'sesiones'));
        const sesiones = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().alumnoId === id) {
            sesiones.push({ id: doc.id, ...doc.data() });
          }
        });
        setFichas(sesiones);
      } catch (error) {
        console.error('Error fetching fichas:', error);
      }
    };

    fetchFichas();
  }, [id, db]);

  const handleChange = (index, field, value) => {
    const updatedFichas = fichas.map((ficha, i) =>
      i === index ? { ...ficha, [field]: value } : ficha
    );
    setFichas(updatedFichas);
  };

  const handleAddRow = () => {
    setFichas([
      ...fichas,
      {
        id: null,
        alumnoId: id,
        modulo: '',
        ejercicios: '',
        velocidad: '',
        comprension: '',
        lecturaRealizada: '',
        observaciones: ''
      }
    ]);
  };

  const handleSave = async (index) => {
    const ficha = fichas[index];
    try {
      if (ficha.id) {
        await updateDoc(doc(db, 'sesiones', ficha.id), ficha);
      } else {
        await addDoc(collection(db, 'sesiones'), ficha);
      }
      Swal.fire({
        title: 'Guardado',
        text: 'La sesión ha sido guardada con éxito.',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error al guardar:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al guardar',
        text: 'Ocurrió un error al intentar guardar la sesión.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleDelete = async (index) => {
    const ficha = fichas[index];
    try {
      if (ficha.id) {
        await deleteDoc(doc(db, 'sesiones', ficha.id));
        setFichas(fichas.filter((_, i) => i !== index));
        Swal.fire({
          title: 'Eliminado',
          text: 'La sesión ha sido eliminada con éxito.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        });
      }
    } catch (error) {
      console.error('Error al eliminar:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar',
        text: 'Ocurrió un error al intentar eliminar la sesión.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div>
      <Button onClick={handleAddRow}>Añadir Fila</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>N-sesión</th>
            <th>Módulo</th>
            <th>Ejercicios</th>
            <th>Velocidad</th>
            <th>Comprensión</th>
            <th>Lectura realizada</th>
            <th>Observaciones y Registro de práctica</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {fichas.map((ficha, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.modulo}
                  onChange={(e) => handleChange(index, 'modulo', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.ejercicios}
                  onChange={(e) => handleChange(index, 'ejercicios', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.velocidad}
                  onChange={(e) => handleChange(index, 'velocidad', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.comprension}
                  onChange={(e) => handleChange(index, 'comprension', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.lecturaRealizada}
                  onChange={(e) => handleChange(index, 'lecturaRealizada', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={ficha.observaciones}
                  onChange={(e) => handleChange(index, 'observaciones', e.target.value)}
                />
              </td>
              <td>
                <Button onClick={() => handleSave(index)}>Guardar</Button>
                <Button variant="danger" onClick={() => handleDelete(index)}>Eliminar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SesionesAlumno;
