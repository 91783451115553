import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M4_Ejercicio10 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [tituloLectura1, setTituloLectura1] = useState('');
    const [argumentoLectura1, setArgumentoLectura1] = useState('');
    const [tituloLectura2, setTituloLectura2] = useState('');
    const [argumentoLectura2, setArgumentoLectura2] = useState('');
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const enviarResultados = async () => {
        if (currentStep === 2 && (!tituloLectura1.trim() || !argumentoLectura1.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Campos Incompletos',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        if (currentStep === 3 && (!tituloLectura2.trim() || !argumentoLectura2.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Campos Incompletos',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        let data = {
            userId: currentUser.uid,
            nombre: "Modulo 4 - Ejercicio 10",
            nivel: "Basico",
            createdAt: new Date(),
            tipo: "Respuesta libre",
            tituloLectura1,
            argumentoLectura1,
            tituloLectura2,
            argumentoLectura2
        };

        if (currentStep === 3) {
            try {
                await addDoc(collection(db, "evaluaciones"), data);
                Swal.fire({
                    title: '¡Bien hecho!',
                    text: 'Evaluación finalizada con éxito.',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Excelente'
                }).then((result) => {
                    if (result.isConfirmed) {
                        cerrarModal();  // Cierra la modal al confirmar la alerta
                    }
                });
            } catch (error) {
                console.error("Error al enviar la evaluación: ", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al enviar la evaluación.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar'
                });
            }
        } else {
            handleNextStep();  // Avanza al siguiente paso si todo está correcto
        }
    };

    return (
        <Container className="mt-4">
            {currentStep === 1 && (
                <div>
                      <h3>Nivel Básico</h3>
                    <h3>Módulo 4 - Ejercicio 10</h3>
                    <h5>Ejercicio de retención</h5>
                    
                    <p><strong>Retenga ideas principales </strong></p>
                    <p>Recuerde que los procesos de retención mejoran con la repetición. Retome las
                    palabras clave de la o las lecturas que realizó con su psicopedagoga, repita el ejercicio
                    de asociación y escriba aquí la idea principal.</p>
                    <p>
                        Ahora tome todas las ideas principales y narre el argumento de las siguientes lecturas
                    </p>
                    <Button variant="primary" onClick={handleNextStep}>Comenzar ejercicio</Button>
                </div>
            )}
            {currentStep === 2 && (
                <div>
                    <Form>
                        <FormGroup>
                            <Form.Label>Título de la primera lectura</Form.Label>
                            <FormControl 
                                type="text"
                                value={tituloLectura1}
                                onChange={(e) => setTituloLectura1(e.target.value)}
                                placeholder="Ingrese el título de la primera lectura"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Argumente la primera lectura</Form.Label>
                            <FormControl
                                as="textarea" 
                                rows={3}
                                value={argumentoLectura1}
                                onChange={(e) => setArgumentoLectura1(e.target.value)}
                                placeholder="Argumente la primera lectura aquí..."
                            />
                        </FormGroup>
                        <Button variant="primary" onClick={enviarResultados}>Continuar al siguiente paso</Button>
                    </Form>
                </div>
            )}
            {currentStep === 3 && (
                <div>
                    <Form>
                        <FormGroup>
                            <Form.Label>Título de la segunda lectura</Form.Label>
                            <FormControl 
                                type="text"
                                value={tituloLectura2}
                                onChange={(e) => setTituloLectura2(e.target.value)}
                                placeholder="Ingrese el título de la segunda lectura"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Argumente la segunda lectura</Form.Label>
                            <FormControl
                                as="textarea" 
                                rows={3}
                                value={argumentoLectura2}
                                onChange={(e) => setArgumentoLectura2(e.target.value)}
                                placeholder="Argumente la segunda lectura aquí..."
                            />
                        </FormGroup>
                        <Button variant="primary" onClick={enviarResultados}>Enviar Respuesta</Button>
                    </Form>
                </div>
            )}
        </Container>
    );
};

export default M4_Ejercicio10;
