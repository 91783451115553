import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Row, Col, Form, Button, ListGroup, ListGroupItem, FormGroup, FormControl, Label } from 'react-bootstrap';
import Swal from 'sweetalert2';


const M7_Ejercicio8 = ({ cerrarModal }) => {

    const { currentUser } = useContext(AppContext);
    const [respuestas, setRespuestas] = useState({

        //Raíz: Arbor = arbol, higuera

        arborizacion: '',
        arboleda: '',
        arbolar: '',




        estricto: '',
        restringir: '',
        trenzar: '',

        //Raiz: Annus = año

        avanzar: '',
        adelantar: '',

        //raiz: ager = agri, campo

        belico: '',
        beligerante: '',
        belicoso: '',


        //Raiz: alienus = ajeno, extraño, extranjero

        bendicion: '',
        benefico: '',
        benevolo: '',
        beneficio: '',
        benigno: ''


    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRespuestas(prevRespuestas => ({
            ...prevRespuestas,
            [name]: value
        }));
    };


    const enviarResultados = async () => {
        if (!Object.values(respuestas).every(value => value.trim() !== '')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos deben estar llenos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio de Comprensión - Ejercicio 8",
                nivel: "Intermedio",
                modulo: "7",
                createdAt: new Date(),
                tipo: "Texto libre",
                respuestas
            });
            Swal.fire({
                title: '¡Evaluación Enviada!',
                text: 'Resultados enviados con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar las respuestas: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar las respuestas.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="container mt-4">
            <h2>Ejercicio de Comprensión</h2>
            <p><strong>Ejercicio 8: Incremente su vocabulario</strong></p>


            <Row>
                <Col md={12}>

                    <Form className='border p-3'>
                        <p><strong>Raiz:Arbor = árbol, higuera</strong></p>

                        <Form>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="arborizacion"
                                    value={respuestas.arborizacion}
                                    onChange={handleChange}
                                    placeholder="Arborización"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="arboleda"
                                    value={respuestas.arboleda}
                                    onChange={handleChange}
                                    placeholder="Arboleda"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="arbolar"
                                    value={respuestas.arbolar}
                                    onChange={handleChange}
                                    placeholder="Arbolar"
                                />
                            </FormGroup>


                        </Form>

                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: Astrictus = estrecho, angosto</strong></p>
                            <p><strong>Astringo, trinxi, trictum = estrechar, amarrar</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="estricto"
                                    value={respuestas.estricto}
                                    onChange={handleChange}
                                    placeholder="Estricto"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="restringir"
                                    value={respuestas.restringir}
                                    onChange={handleChange}
                                    placeholder="Restringir"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="trenzar"
                                    value={respuestas.trenzar}
                                    onChange={handleChange}
                                    placeholder="Trenzar"
                                />
                            </FormGroup>
                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: Avante = adelante</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="avanzar"
                                    value={respuestas.avanzar}
                                    onChange={handleChange}
                                    placeholder="Avanzar"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="adelantar"
                                    value={respuestas.adelantar}
                                    onChange={handleChange}
                                    placeholder="Adelantar"
                                />
                            </FormGroup>

                        </Form>
                        <br />


                        <Form className='border p-3'>

                            <p><strong>Raíz:Bullem = guerra, provocar la guerra, iniciar a...</strong></p>

                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="belico"
                                    value={respuestas.belico}
                                    onChange={handleChange}
                                    placeholder="Bélico"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="beligerante"
                                    value={respuestas.beligerante}
                                    onChange={handleChange}
                                    placeholder="Beligerante"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormControl
                                    type="text"
                                    name="belicoso"
                                    value={respuestas.belicoso}
                                    onChange={handleChange}
                                    placeholder="belicoso"
                                />
                            </FormGroup>


                        </Form>
                        <br />

                        <Form className='border p-3'>

                            <p><strong>Raíz: Bonus = bueno, bello, agradable</strong></p>
                            <p><strong>Bene = bien, favorablemente, feliz</strong></p>
                                <FormGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        name="bendicion"
                                        value={respuestas.bendicion}
                                        onChange={handleChange}
                                        placeholder="Bendición"
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        name="benefico"
                                        value={respuestas.benefico}
                                        onChange={handleChange}
                                        placeholder="Benéfico"
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        name="benevolo"
                                        value={respuestas.benevolo}
                                        onChange={handleChange}
                                        placeholder="Benévolo"
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        name="beneficio"
                                        value={respuestas.beneficio}
                                        onChange={handleChange}
                                        placeholder="Beneficio"
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormControl
                                        type="text"
                                        name="benigno"
                                        value={respuestas.benigno}
                                        onChange={handleChange}
                                        placeholder="Benigno"
                                    />
                                </FormGroup>


                        </Form>
                        
                    </Form>
                </Col>

                <Button onClick={enviarResultados} variant="primary">Enviar Resultados</Button>
            </Row>
        </div>
    );
};




export default M7_Ejercicio8;
