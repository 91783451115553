import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M6_Ejercicio7 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState('');

    const p1 = [
        { key: 'a', text: 'Harry Potter es el fenómeno de la literatura infantil de los últimos años.' },
        { key: 'b', text: 'La zaga de siete libros ya ha sacado su primera producción cinematográfica.' },
        { key: 'c', text: 'Harry es un nino de 11 anos que vive con sus tíos.' },
        { key: 'd', text: 'Su historia no anda muy lejos de la de Cenicienta.' }
    ];

    const p2 = [
        { key: 'a', text: 'Un día llega a casa de los Dursley una carta para Harry.' },
        { key: 'b', text: 'El día señalado se presenta en la estación para tomar el tren.' },
        { key: 'c', text: 'Durante el viaje conoce a Ron Weasley.' },
        { key: 'd', text: 'El niño descubre que tiene poderes mágicos.' }
    ];

    const p3 = [
        { key: 'a', text: 'Harry Ron deben someterse a la prueba del sombrero.' },
        { key: 'b', text: 'A ambos les asignan la casa Griffindor dirigida por la estricta Minerva.' },
        { key: 'c', text: 'En las clases coinciden con Hermione Grander.' },
        { key: 'd', text: 'Al principio ella no se lleva bien con ellos.' }
    ];

    const p4 = [
        { key: 'a', text: 'Harry tiene problemas con Draco Malfoy.' },
        { key: 'b', text: 'Tambien tiene aliados como el guardabosques Hagrid.' },
        { key: 'c', text: 'El primer curso en Hogwarts le permite a Harry descubrir sus grandes dotes como mago.' },
        { key: 'd', text: 'Lord Voldemort quiere robarse la piedra filosofal.' }
    ];

    const p5 = [
        { key: 'a', text: 'Estos son los perfiles de algunos de ellos.' },
        { key: 'b', text: 'La historia de Harry promete ser una de las grandes sagas de todos los tiempos.' },
        { key: 'c', text: 'Rowling logra crear una perfecta lógica interna.' }
    ];

    const p6 = [
        { key: 'a', text: 'Ron Weasley, el mejor amigo de Harry, es un experto jugador de ajedrez mágico.' },
        { key: 'b', text: 'Ron estudia en Hogwarts al igual que sus hermanos mayores.' },
        { key: 'c', text: 'Ron no soporta heredar las ropas usadas de sus hermanos.' }
    ];

    const p7 = [
        { key: 'a', text: 'Hermione no teme a encontrarse con una pesadilla de monstruos.' },
        { key: 'b', text: 'Hermione es la alumna mas estudiosa y no tiene poderes mágicos.' },
        { key: 'c', text: 'Los padres de Hermione son dentistas.' }

    ];

    const p8 = [
        { key: 'a', text: 'Albus Dumbledore es inteligente, totalmente y con sentido del humor.' },
        { key: 'b', text: 'Lord Voldemort teme enfrentarse a Albus, el director.' },
        { key: 'c', text: 'Albus Dumbledore, el director, es el mago más poderoso de la actualidad.' }

    ];

    const p9 = [
        { key: 'a', text: 'Minerva es la subdirectora, es muy estricta y tiene el poder de transformarse' },
        { key: 'b', text: 'Minerva siempre actúa en forma justa' },
        { key: 'c', text: 'Minerva es muy astricta en el cumplimiento de las normas' }

    ];

    const p10 = [
        { key: 'a', text: 'Rubeus era alumno de la escuela y lo expulsaron' },
        { key: 'b', text: 'Rubeus es un gigante bonachon y es el guardabosques' },
        { key: 'c', text: 'Rubeus adora los monstruos y otras criaturas extranas.' }
    ];

    const nombresDePaso = {
        2: "Párrafo 1", // Paso 2 corresponde a Párrafo 1
        3: "Párrafo 2",
        4: "Párrafo 3",
        5: "Párrafo 4",
        6: "Párrafo 5",
        7: "Párrafo 6",
        8: "Párrafo 7",
        9: "Párrafo 8",
        10: "Párrafo 9",
        11: "Párrafo 10"
    };




    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handleNextExercise = async () => {
        if (!selectedOption) {
            Swal.fire({
                icon: 'error',
                title: 'Campos Incompletos',
                text: 'Debe seleccionar una opción antes de continuar.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        
        const nombreDePregunta = nombresDePaso[step]; // Obtener el nombre del paso actual


        // Si no se encuentra la pregunta, muestra un error y detén la ejecución
        if (!nombreDePregunta) {
            console.error("No se encontró la pregunta correspondiente a la opción seleccionada.");
            Swal.fire({
                icon: 'error',
                title: 'Error Interno',
                text: 'No se pudo encontrar la pregunta correspondiente.',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 7: Extraiga ideas principales",
                nivel: "Intermedio",
                modulo: "6",
                tipo: "Seleccion simple",
                pregunta: nombreDePregunta,
                respuesta: selectedOption,
                createdAt: new Date()
            });
            setStep(step + 1);
        } catch (error) {
            console.error("Error al guardar la respuesta: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo guardar la respuesta.',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 7: Extraiga ideas principales",
                nivel: "Intermedio",
                modulo: "6",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            {step === 1 && (
                <div>
                    <h3>Nivel Intermedio</h3>
                    <h3>Módulo 6 - Ejercicio 7</h3>
                    <h5>Extraiga ideas principales</h5>

                    <h4>Harry Potter</h4>

                    <p>
                        Harry Potter es el fenómeno de literatura infantil de los últimos años. La saga de siete libros de J.K.
                        Rowling. ya ha sacado el publico su primer superproduccion cinematografica: Harry Potter y la Piedra
                        filosofal. La historia, que ha cautivado a grandes y chicos por igual, tiene una estructura sencilla, pero es
                        de una gran riqueza en detalles. Harry Potter es un niño de 11 años que vive con sus tíos, los Durley.
                        desde que sus padres murieron cuando él era un bebé. Su historia no anda muy lejos de la que vivía
                        Cenicienta en casa de su madrastra, ya que sus tíos y su primo no lo querían y lo trataban como a un
                        sirviente.
                    </p>
                    <p>
                        Un día llega a casa de los Durley una carta para Harry en la que lo invitan a ingresar en la Escuela de
                        Magia y Brujería Hogwarts. Es entonces cuando el nino descubre que es hijo de dos poderosos
                        hechiceros que fueron asesinados por el malvado Valdemort. Y que él también tiene poderes mágicos ...
                        Pese al rechazo de sus tíos que odian la magia, Harry decide ingresar en Hogwarts. Así que el día
                        senalado se presenta en la estacion de King's Cross para tomar el tren que ha de llevarle a la escuela.
                        Durante el viaje conoce a Ron Weasley, otro novato que pronto se convierte en su mejor amigo.
                    </p>
                    <p>
                        Ya en Hogwarts, Harry y Ron, como el resto de alumnos recién llegados, deben someterse a la prueba
                        del Sombrero seleccionador para saber a que hermandad perteneceran. A ambos les asignan
                        Gryffindor, la casa dirigida por la estricta Minerva McGonagall. En las clases coinciden con Hermione
                        Granger, la sabihonda del curso. Al principio ella no se lleva bien con ellos, pero acaba uniéndose a la
                        pandilla.
                    </p>
                    <p>
                        El primer curso en Hogwarts le permite a Harry Potter descubrir sus grandes dotes de mago y su
                        habilidad innata para jugar al Quidditch, el deporte oficial de la escuela. Tiene problemas con Draco
                        Malfoy, el niño rebelde de una casa rival, y con el profesor Snape, que guarda una especial envidia por
                        Harry. Pero tambien encuentra aliados como el guardabosques Hagrid y el director de la escuela, Albus
                        Dumbledore. Harry y sus amigos tambien se tienen que enfrentar a Lord Voldemort, que merodea por
                        los bosques de Hogwarts, dispuesto a introducirse en la escuela y robar la piedra filosofal. La historia de
                        Harry, cuyos libros aun no estan todos escritos, ya promete ser una de las grandes sagas de todos los
                        tiempos. Además de crear una perfecta lógica interna, Rawlings acertó al definir sus personajes. Estos
                        son los perfiles de algunos de ellos:
                    </p>
                    <p>
                        HARRY POTTER: Aprendiz de mago hijo de dos poderosos hechiceros que murieron cuando el era
                        un bebé. No descubre sus podres mágicos hasta que cumple 11 años, cuando lo invitan a ingresar en la
                        Escuela de Magia y Brujería de Hogwarts y es uno de los pocos magos capaz de enfrentar al malvado
                        Voldemort.
                    </p>
                    <p>
                        RON WEASLEY: El mejor amigo de Harry Potter. Estudia en Hogwarts siguiendo los pasos de sus
                        hermanos mayores, que han alcanzado un gran prestigio en las artes mágicas. Ron admira a sus
                        hermanos y le gustaria imitarlos, pero no soporta heredar sus ropas usadas para ir a la escuela. Es un
                        buen aficionado al Quiddich y un experto jugador de ajedrez mágico.
                    </p>
                    <p>
                        HERMIONE GRANGER: La alumna mas estudiosa de la casa Gryffindor. Pertenece a una familia de
                        muggles (gente sin poderes mágicos) dentistas que están orgullosos de ella por ser la primera en
                        estudiar en Hogwarts. Muy estricta con las normas escolares, teme más un suspenso que encontrarse
                        con una pandilla de monstruos. Aun asi, acompaña a Harry y Ron en sus aventuras.
                    </p>
                    <p>
                        ALBUS DUMBLEDORE: Director de Hogwarts y, según dicen, el mago más poderoso en la
                        actualidad (Orden de Merlin, Primera Clase, Gran Hechicero, Jefe de Magos, Jefe Supremo,
                        Confederación Internacional de Magos), hasta el punto que incluso el malvado Lord Voldemort teme
                        enfrentarse a él. Inteligente, tolerante y con un gran sentido del humor, es el mejor aliado de
                        Harry.
                    </p>
                    <p>
                        MINERVA MCGONAGALL: Subdirectora de Hogwarts y jefa de
                        casa Gryffindor. La consideran una bruja severa y muy estricta con el cumplimiento de las normas, pero
                        siempre actúa de forma justa. Su poder mágico más destacado es el de transformaciones de hecho, es
                        la profesora de esta materia y suele convertirse en animales, especialmente en su favorito, el gato.
                    </p>
                    <p>
                        RUBEUS HAGRID: El gigante bonachon trabaja como guardabosques en Hogwarts. Tiempo
                        atras fue alumno de la escuela, pero le expulsaron en su tercer curso acusado de un delito que no
                        cometio. Aun asi, le permitieron seguir en Hogwarts y encargarse de los terrenos. Adora los monstruos y
                        otras extrañas y es uno de los mejores amigos de Harry.
                    </p>
                    <hr>
                    </hr>
                    <h4>INSTRUCCIONES</h4>
                    <p>

                        La idea principal de un texto se diferencia del argumento en que es mucho más concisa. El argumento
                        narra la secuencia de una historia. La idea principal debe expresar pocas palabras el mensaje de la
                        lectura.
                        Para extraerla y como método para mejorar la comprensión, es mejor seguir siempre el orden de
                        extraer primero palabras clave por parrafo, luego ideas principales por parrafo para de ahi extraer una
                        sola idea principal. En general, la idea principal respondera en maximo dos o tres líneas, a las preguntas
                        ¿qué, quién, cómo, por qué y dónde?
                        En la lectura de este modulo extraiga las palabras clave, luego extracte ideas principales por parrafo y
                        finalmente establezca una sola idea principal para toda la lectura.
                    </p>


                    <Button variant="primary" onClick={handleNextStep}>Comenzar ejercicio</Button>
                </div>
            )}
            {step === 2 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 1</strong></p>
                    {p1.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 1"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}



            {step === 3 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 2</strong></p>
                    {p2.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 2"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 4 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 3</strong></p>
                    {p3.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 3"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 5 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 4</strong></p>
                    {p4.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 4"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 6 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 5</strong></p>
                    {p5.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 5"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 7 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 6</strong></p>
                    {p6.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 6"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 8 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 7</strong></p>
                    {p7.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 7"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 9 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 8</strong></p>
                    {p8.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 8"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 10 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 9</strong></p>
                    {p9.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 9"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 11 && (
                <div>
                    <h5>Lectura: HARRY POTTER</h5>
                    <p><strong>Párrafo 10</strong></p>
                    {p10.map(option => (
                        <div key={option.key}>
                            <label>
                                <input
                                    type="radio"
                                    name="Parrafo 10"
                                    checked={selectedOption === option.key}
                                    onChange={() => handleOptionChange(option.key)}
                                />
                                {option.text}
                            </label>
                        </div>
                    ))}
                    <Button className='mt-4' onClick={handleNextExercise}>Siguiente ejercicio</Button>
                </div>
            )}
            {step === 12 && (
             <div>
        <h5>Resumen del Texto</h5>
        <p><strong>Ahora una las ideas de cada párrafo y estructure una sola idea general que resuma todo el texto:</strong></p>
        <Form.Group className="mb-3">
            <Form.Control
                as="textarea"
                rows={3}
                value={respuestaLibre}
                onChange={e => setRespuestaLibre(e.target.value)}
                placeholder="Ingrese su respuesta"
            />
        </Form.Group>
        <Button className='mt-4' variant="primary" onClick={enviarResultados}>Enviar Respuesta</Button>
    </div>
)}



        </Container>
    );
};

export default M6_Ejercicio7;
