import React, { useState, useContext, lazy, Suspense } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AppContext } from '../Context';
import './CuadernoDigital.css'; // Asegúrate de tener este archivo de estilos
import './Components/EstimulacionNeuronal.css';  // Confirma que la ruta es correcta y el archivo existe.


// Componentes de evaluación cargados dinámicamente
const EvaluacionBasica = lazy(() => import('./EvaluacionBasica'));
const EvaluacionIntermedia = lazy(() => import('./EvaluacionIntermedia'));
const EvaluacionAvanzada = lazy(() => import('./EvaluacionAvanzada'));


const CuadernoDigital = () => {
  const { evaluaciones, togglePlayPause, playNext, playPrev, isPlaying } = useContext(AppContext);

  
  const [nivelSeleccionado, setNivelSeleccionado] = useState('');

  const renderEvaluacion = () => {
    switch (nivelSeleccionado) {
      case 'Basico':
        return <EvaluacionBasica />;
      case 'Intermedio':
        return <EvaluacionIntermedia />;
      case 'Avanzado':
        return <EvaluacionAvanzada />;
      default:
        return <p className="select-level-message">Seleccione un nivel para mostrar las evaluaciones.</p>;
    }
  };

  return (
    <Container className='pb-5'>
      <Row className="justify-content-center mb-4">
        <h1>Cuaderno Digital</h1>
        <p className='centro'> Seleccione un nivel de evaluación para continuar.</p>
      </Row>
      <Row className="mb-3">
        {['Basico', 'Intermedio', 'Avanzado'].map(nivel => (
          <Col key={nivel} md={4} className="mb-3">
            <Button variant="primary" size="lg" className="evaluation-button" onClick={() => setNivelSeleccionado(nivel)}>
              {nivel}
            </Button>
            
          </Col>
         
        ))}
      </Row>
      {/* <Row>
      <Col>
      <div className="audio-player fixed-footer">

         <button onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
      <button onClick={playNext}>Next</button>
      <button onClick={playPrev}>Prev</button>
      </div>
         </Col> 
      </Row> */}
      <Suspense fallback={<div className="loading-message">Cargando evaluación...</div>}>
        {renderEvaluacion()}
      </Suspense>
    </Container>
  );
};

export { CuadernoDigital };
