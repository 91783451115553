import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../Context';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase/firebase';
import { Container, Form, Button, FormGroup, FormControl, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

const M7_Ejercicio6 = ({ cerrarModal }) => {
    const { currentUser } = useContext(AppContext);
    const [respuestaLibre, setRespuestaLibre] = useState('');
    const [open, setOpen] = useState(false);

    const enviarResultados = async () => {
        if (!respuestaLibre.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe llenar todos los campos antes de enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        try {
            await addDoc(collection(db, "evaluaciones"), {
                userId: currentUser.uid,
                nombre: "Ejercicio 6: Infiera significado por contexto",
                nivel: "Intermedio",
                modulo: "7",
                tipo: "Respuesta libre",
                createdAt: new Date(),
                respuesta: respuestaLibre
            });
            Swal.fire({
                title: '¡Bien hecho!',
                text: 'Evaluación finalizada con éxito.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Excelente'
            }).then((result) => {
                if (result.isConfirmed) {
                    cerrarModal();  // Cierra la modal al confirmar la alerta
                }
            });
        } catch (error) {
            console.error("Error al enviar la evaluación: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al enviar la evaluación.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <Container className="mt-4">
            <h2>  EJERCICIO 6: INFIERA SIGNIFICADO POR CONTEXTO</h2>
            <h4>Analice el siguiente párrafo:</h4>
           
                       <p>

Cuentan que en tiempos remotos hubo un sultan en Persia llamado Schariar que desconfiaba tanto de
sus mujeres que todos los días se casaba con una nueva y la mandaba a matar al dia siguiente. El pobre
visir tenía que obedecerlo sin protestar, aunque ello le causara mucha tristeza. El mismo tenía dos hijas
a las que quería mucho: la mayor se llamaba Scherezade y la menor, muy joven aun, Dinarzada. Un día,
el sultán decidió que quería casarse con Scherezade, y así se lo comunicó al visir. Este se puso muy
triste, pensando en que su hija iba a morir, pero él no podía contradecir las órdenes de su jefe.
Scherezade, viendo sufría su padre, le dijo <strong> Padre mío, no te preocupes, si me haces la esposa del
sultán, estoy segura que lograré librar el país de muchas crueldades.</strong>
</p>
<p>
La palabra <strong>Visir </strong>, si usted no conoce el significado, intente deducirlo por el significado de las palabras
que se encuentran antes o después.
            </p>
         
            <Form>
                <FormGroup className="mb-3">
                    <FormControl
                        type="text"
                        value={respuestaLibre}
                        onChange={e => setRespuestaLibre(e.target.value)}
                        placeholder="Ingrese su respuesta"
                    />
                </FormGroup>
                <Button onClick={enviarResultados} variant="primary">Finalizar Evaluación</Button>
            </Form>
        </Container>
    );
};

export default M7_Ejercicio6;
