import { LoginNavbar } from "../LoginNavbar";
import "./index.css";

function Layout({ children }) {
  return (
    <>
        <LoginNavbar />
        <div className="bg-image">
        {children}
        </div>
    </>
  );
}

export { Layout };