import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../Context';
import { Container, Row, Col, ListGroup, Card, Navbar, Nav, Button } from 'react-bootstrap';

const DetalleMaterialTeorico = () => {
    const { nivel } = useParams();
    const { lecciones } = useContext(AppContext);
    const [leccionSeleccionada, setLeccionSeleccionada] = useState(null);
    const navigate = useNavigate();
    // Filtrar lecciones al cargar y cuando cambie el nivel o las lecciones
    useEffect(() => {
        const leccionesFiltradas = lecciones.filter(leccion => parseInt(leccion.level) === parseInt(nivel));
        if (leccionesFiltradas.length > 0) {
            setLeccionSeleccionada(leccionesFiltradas[0]); // Establecer la primera lección por defecto
        } else {
            setLeccionSeleccionada(null); // No hay lecciones para este nivel
        }
    }, [lecciones, nivel]);

    return (
        <div>
        <Navbar bg="dark" variant="dark" className="mb-4">
        <Container>
          <Navbar.Brand>Nivel</Navbar.Brand>
          <Nav className="me-auto">
            <Button variant="outline-light" onClick={() => navigate(-1)}>Volver</Button>
          </Nav>
        </Container>
      </Navbar>
        
        <div className='container'>
             
        <Container fluid>
            <Row>
                <Col md={4}>
                    <ListGroup>
                        {lecciones.filter(leccion => parseInt(leccion.level) === parseInt(nivel)).map(leccion => (
                            <ListGroup.Item key={leccion.id} action onClick={() => setLeccionSeleccionada(leccion)}>
                                {leccion.titulo}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col md={8}>
                    {leccionSeleccionada ? (
                        <Card>
                            <Card.Img variant="top" src={leccionSeleccionada.imagenDestacada} alt="Imagen destacada" />
                            <Card.Body>
                                <Card.Title>{leccionSeleccionada.titulo}</Card.Title>
                                <Card.Text>{leccionSeleccionada.descripcion}</Card.Text>
                            </Card.Body>
                        </Card>
                    ) : <p>No hay lecciones seleccionadas o disponibles para este nivel.</p>}
                </Col>
            </Row>
        </Container>

        </div>
        </div>
    );
};


export  { DetalleMaterialTeorico };
