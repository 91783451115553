import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { Form, Button, Modal, Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

const AlumnoForm = () => {
  const { id } = useParams();
  const db = getFirestore();

  const [alumno, setAlumno] = useState({});
  const [estatus, setEstatus] = useState('Activo');
  const [comentario, setComentario] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const alumnoRef = doc(db, "users", id);
        const alumnoSnap = await getDoc(alumnoRef);
        if (alumnoSnap.exists()) {
          setAlumno(alumnoSnap.data());
          setEstatus(alumnoSnap.data().estatus || 'Activo');
          setComentario(alumnoSnap.data().comentarioEstatus || '');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDetails();
  }, [id, db]);

  const handleChange = (e, field) => {
    setAlumno({ ...alumno, [field]: e.target.value });
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, 'users', id);
      await updateDoc(docRef, {
        ...alumno,
        estatus,
        comentarioEstatus: comentario,
        lastUpdatedAt: new Date()
      });
      Swal.fire({
        title: 'Guardado',
        text: 'Los cambios han sido guardados con éxito.',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error al guardar:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al guardar',
        text: 'Ocurrió un error al intentar guardar los cambios.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleSaveStatus = async () => {
    await handleSave(); // Save changes including estatus and comentario
    setShowModal(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={9}> {/* Primera columna con un 60% de ancho */}
          <div>
            <h2>Detalle del Alumno</h2>
            {alumno.profilePicture && (
              <img
                src={alumno.profilePicture}
                alt="Perfil"
                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
              />
            )}
            <Form>
              <Row>
                {/* <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Profile Picture</Form.Label>
                    <Form.Control
                      type="text"
                      name="profilePicture"
                      value={alumno.profilePicture || ''}
                      onChange={(e) => handleChange(e, 'profilePicture')}
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={alumno.name || ''}
                      onChange={(e) => handleChange(e, 'name')}
                    />
                  </Form.Group>
                </Col>
                {/* Add the remaining fields in a similar way */}
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={alumno.username || ''}
                      onChange={(e) => handleChange(e, 'username')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="displayName"
                      value={alumno.displayName || ''}
                      onChange={(e) => handleChange(e, 'displayName')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={alumno.email || ''}
                      onChange={(e) => handleChange(e, 'email')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Matricula</Form.Label>
                    <Form.Control
                      type="text"
                      name="matricula"
                      value={alumno.matricula || ''}
                      onChange={(e) => handleChange(e, 'matricula')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Estrategia</Form.Label>
                    <Form.Control
                      type="text"
                      name="estrategia"
                      value={alumno.estrategia || ''}
                      onChange={(e) => handleChange(e, 'estrategia')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={alumno.phone || ''}
                      onChange={(e) => handleChange(e, 'phone')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Pais</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      value={alumno.country || ''}
                      onChange={(e) => handleChange(e, 'country')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={alumno.city || ''}
                      onChange={(e) => handleChange(e, 'city')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Nivel Actual</Form.Label>
                    <Form.Control
                      type="text"
                      name="level"
                      value={alumno.level || ''}
                      onChange={(e) => handleChange(e, 'level')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Fecha de Inscripcion</Form.Label>
                    <Form.Control
                      type="date"
                      name="inscription"
                      value={alumno.inscription || ''}
                      onChange={(e) => handleChange(e, 'inscription')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Edad</Form.Label>
                    <Form.Control
                      type="number"
                      name="age"
                      value={alumno.age || ''}
                      onChange={(e) => handleChange(e, 'age')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Actividad</Form.Label>
                    <Form.Control
                      type="text"
                      name="activity"
                      value={alumno.activity || ''}
                      onChange={(e) => handleChange(e, 'activity')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={alumno.password || ''}
                      onChange={(e) => handleChange(e, 'password')}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Otros</Form.Label>
                    <Form.Control
                      type="text"
                      name="other"
                      value={alumno.other || ''}
                      onChange={(e) => handleChange(e, 'other')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span>Estatus Actual: <strong>{estatus}</strong></span><hr></hr>
                  <span className="ml-4">Comentario Actual: {comentario}</span>
                </Col>
              </Row>

              <Button variant="primary" onClick={() => setShowModal(true)} className="mt-3">
                Cambiar Estatus del Alumno
              </Button>

              <Button variant="success" onClick={handleSave} className="mt-3">
                Guardar Cambios
              </Button>
            </Form>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Cambiar Estatus del Alumno</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control as="select" value={estatus} onChange={(e) => setEstatus(e.target.value)}>
                      <option>Activo</option>
                      <option>Inactivo</option>
                      <option>Baja Temporalidad</option>
                      <option>Baja Definitiva</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Comentario</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={comentario}
                      onChange={(e) => setComentario(e.target.value)}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
                  <Button variant="primary" onClick={handleSaveStatus}>Guardar Cambios</Button>
                </Modal.Footer>
              </Modal>
          </div>
        </Col>
        <Col md={3}>
          {/* Aquí puedes añadir una sección adicional si necesitas */}
        </Col>
      </Row>
    </Container>
  );
};

export default AlumnoForm;

