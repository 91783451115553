import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button, Form, FormGroup, FormControl, Card, FormLabel, Container, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';

import './EditProfileView.css';
import { FaCamera } from 'react-icons/fa'; // Importa el ícono de cámara



function EditProfileView() {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(false); // Estado para manejar la carga
    const [imageURL, setImageURL] = useState(null);
    const [newImage, setNewImage] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                getDoc(userRef).then(docSnap => {
                    if (docSnap.exists()) {
                        setCurrentUser({ uid: user.uid, ...docSnap.data() });
                        setImageURL(docSnap.data().profilePicture || null);
                    }
                });
            } else {
                navigate('/login');
            }
        });
        return unsubscribe;
    }, [auth, db, navigate]);

     const handleFileChange = async (event) => {
         const file = event.target.files[0];
         if (file) {
             const fileRef = storageRef(storage, `profilePictures/${currentUser.uid}`);
             const snapshot = await uploadBytes(fileRef, file);
             const downloadURL = await getDownloadURL(snapshot.ref);
             setCurrentUser({ ...currentUser, profilePicture: downloadURL }); // Actualizar estado con nueva URL
             await updateDoc(doc(db, "users", currentUser.uid), { profilePicture: downloadURL });
         }
     };

    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     setNewImage(file);
    // };

    const handleSavePhoto = async () => {
        if (!newImage) {
            Swal.fire("Error", "Please select an image first.", "error");
            return;
        }

        const fileRef = storageRef(storage, `profilePictures/${currentUser.uid}`);
        const snapshot = await uploadBytes(fileRef, newImage);
        const downloadURL = await getDownloadURL(snapshot.ref);

        await updateDoc(doc(db, "users", currentUser.uid), {
            profilePicture: downloadURL
        });

        setCurrentUser(prevState => ({ ...prevState, profilePicture: downloadURL }));
        Swal.fire("Success", "Profile picture updated successfully.", "success");
    };

    const inputFileRef = React.createRef();  // Crear una referencia para el input file


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser({ ...currentUser, [name]: value });
    };

    const handleSaveChanges = async (event) => {
        event.preventDefault();  // Detener la recarga de la página
        const { uid, username, phone, country, city } = currentUser;
    
        if (uid) {
            setLoading(true); // Suponiendo que tienes un estado 'loading' para controlar el indicador de carga
            const updateData = {};
            if (username !== undefined) updateData.username = username;
            if (phone !== undefined) updateData.phone = phone;
            if (country !== undefined) updateData.country = country;
            if (city !== undefined) updateData.city = city;
    
            const userRef = doc(db, "users", uid);
            try {
                await updateDoc(userRef, updateData);
                setLoading(false); // Detener el indicador de carga
                Swal.fire({
                    title: 'Exito!',
                    text: 'Información actualizada de manera correcta!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            } catch (error) {
                setLoading(false); // Detener el indicador de carga en caso de error
                console.error("Error updating profile:", error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to update profile.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'No user ID found.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };

    return (
        <Container className='mt-4'>
        <Row >
            <Col md={12} >
                
            <Card className='col-md-12 mx-auto glass'>
                    
                    {/* Placeholder for profile image */}
                    <Row className='my-3'>
                        <Col my={3} className="text-center">
                        <img src={currentUser.profilePicture || "/placeholder.jpg"} alt="Profile" className="img-thumbnail mb-3 rounded-circle" style={{ width: "100px", height: "100px", border: '4px solid gray' }} />
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Cambia tu foto de perfil</Tooltip>}
            >
                <label className="icon-button-label">
                    <FaCamera />
                    <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                </label>
            </OverlayTrigger>
                        <h2>{currentUser.name}</h2>
                        <p>@{currentUser.username}</p>
                    
                        </Col>
                    <Col md={4} className='text-center'>
                    
                    <h2>{currentUser.name}</h2>
                    <p>{currentUser.email}</p>
                    <p> <strong>Nombre de usuario: </strong> <br></br>@{currentUser.username}</p>
                    <p> <strong>Matricula: </strong> <br></br>{currentUser.matricula || 'Sin Asignar'}</p>
                    
                    </Col>
                    <Col md={4} className='text-center'>
                    
                    <p> <strong>Fecha de nacimiento:</strong> <br></br>{currentUser.date}</p>
                    <p> <strong>Inscripción:</strong> <br></br>{currentUser.inscripcion}</p>
                    <p> <strong>Profesor a cargo:</strong> <br></br>{currentUser.asignTeacher || 'Sin Asignar'}</p>
                    <p> <strong>Nivel:</strong> <br></br>{currentUser.level}</p>
                    </Col>
                    </Row>
                
            </Card>
            
            </Col>
        
            <Col md={6}    >
            
            <Card className='mt-4 glass'>
                    <Card.Body >
                        <Form>
                            
                            <FormGroup className="mb-3">
                                <FormLabel>Username:</FormLabel>
                                <FormControl type="text" name="username" value={currentUser.username || ''} onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel>Telefono:</FormLabel>
                                <FormControl type="text" name="phone" value={currentUser.phone || ''} onChange={handleInputChange} />
                            </FormGroup>
                            
                           
                        </Form>
                    </Card.Body>
                </Card>
                
            </Col>
            <Col md={6} >
                
                <Card className='mt-4 glass'>
                    <Card.Body>
                        <Form>
                        <FormGroup className="mb-3">
                    <Form.Label className="form-label">País:</Form.Label>
                    <FormControl type="text" className="form-control" name="country" value={currentUser.country || ''} onChange={handleInputChange} />
                </FormGroup>
                        <FormGroup className="mb-3">
                    <Form.Label className="form-label">Ciudad:</Form.Label>
                    <FormControl type="text" className="form-control" name="city" value={currentUser.city || ''} onChange={handleInputChange} />
                </FormGroup>

               
                
                           
                        </Form>
                    </Card.Body>
                </Card>
                
            </Col>
            <div className='text-center mt-3'>
            <Button variant="primary" onClick={handleSaveChanges} disabled={loading}>
                                {loading ? 'Guardando...' : 'Guarda Cambios'}
                            </Button>
                            </div>
        </Row>
    </Container>
            /* </Card>
            <Form>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Nombre:</Form.Label>
                    <FormControl type="text" className="form-control" value={currentUser.name || ''} readOnly />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Username:</Form.Label>
                    <FormControl type="text" className="form-control" name="username" value={currentUser.username || ''} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Email:</Form.Label>
                    <FormControl type="email" className="form-control" value={currentUser.email || ''} readOnly />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Teléfono:</Form.Label>
                    <FormControl type="text" className="form-control" name="phone" value={currentUser.phone || ''} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">País:</Form.Label>
                    <FormControl type="text" className="form-control" name="country" value={currentUser.country || ''} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Ciudad:</Form.Label>
                    <FormControl type="text" className="form-control" name="city" value={currentUser.city || ''} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Nivel Actual:</Form.Label>
                    <FormControl type="text" className="form-control" value={currentUser.level || ''} readOnly />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label className="form-label">Profesor Asignado:</Form.Label>
                    <FormControl type="text" className="form-control" value={currentUser.teacher ? "Yes" : "No"} readOnly />
                </FormGroup>
                <Button onClick={handleSaveChanges} disabled={loading}>
                    {loading ? 'Saving...' : 'Save Changes'}
                </Button>
            </Form> */
            
            
        
    );
}

export { EditProfileView };
