import React, { useState, useContext, useEffect } from 'react';
import styles from './Ejercicio9.module.css';
import { db } from '../../../../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { AppContext } from '../../../../Context';
import Swal from 'sweetalert2';

const Ejercicio9 = ({ cerrarModal }) => {
  const { currentUser } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [timer, setTimer] = useState(120); // Timer set for 2 minutes (120 seconds)

  const examplePiramide = [
    [1045],
    [495, 550],
    [233, 262, 288],
    [112, 121, 141, 147],
    [58, 54, 67, 74, 73],
    [34, 24, 30, 37, 37, 36],
    [20, 14, 10, 20, 17, 20, 16],
    [6, 14, 0, 10, 10, 7, 13, 3]
  ];

  const [piramideOne, setPiramideOne] = useState([
    ["796"],
    ["", ""],
    ["", "", "189"],
    ["", "106", "", ""],
    ["", "", "62", "", ""],
    ["14", "", "", "", "16", ""],
    ["", "", "", "", "11", "", ""],
    ["", "", "", "10", "", "", "", ""]
  ]);

  const [piramideTwo, setPiramideTwo] = useState([
    [685],
    ["", ""],
    ["", 159, ""],
    ["", "", "", ""],
    ["", 64, "", 25, ""],
    [44, "", "", 12, "", ""],
    ["", "", 15, "", "", "", ""],
    ["", "", "", "", "", 1, "", ""]
  ]);

  useEffect(() => {
    let interval = null;
    if ((currentStep === 2 || currentStep === 3) && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      Swal.fire({
        icon: 'info',
        title: 'Tiempo Agotado',
        text: 'Se ha acabado el tiempo para esta sección.',
        confirmButtonText: 'Ok'
      }).then(() => advanceStep(currentStep + 1));
    }

    return () => clearInterval(interval);
  }, [currentStep, timer]);

  const handleChange = (value, setPiramide, index, subIndex) => {
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setPiramide(piramide => {
        const newPiramide = [...piramide];
        newPiramide[index][subIndex] = value;
        return newPiramide;
      });
    }
  };

  const allFieldsFilled = (piramide) => {
    return piramide.every(level => level.every(value => value.toString().trim() !== ''));
  };

  const advanceStep = async (nextStep) => {
    if (nextStep === 3 && !allFieldsFilled(piramideOne)) {
      Swal.fire({
        icon: 'error',
        title: 'Campos Incompletos',
        text: 'Debe completar todos los campos de la pirámide antes de avanzar.',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (nextStep === 4 && !allFieldsFilled(piramideTwo)) {
      Swal.fire({
        icon: 'error',
        title: 'Campos Incompletos',
        text: 'Debe completar todos los campos de la pirámide antes de finalizar.',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (nextStep === 3) {
      await sendResults(piramideOne, 'Pirámide 1');
    }

    if (nextStep === 4) {
      await sendResults(piramideTwo, 'Pirámide 2');
      Swal.fire({
        title: '¡Ejercicio Finalizado!',
        text: 'Resultados enviados con éxito.',
        icon: 'success',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
        if (result.isConfirmed) {
          cerrarModal();
        }
      });
      return;
    }

    setCurrentStep(nextStep);
    setTimer(120); // Reset timer for the next step
  };

  const sendResults = async (piramideData, piramideName) => {
    try {
      const tiempoUsado = 120 - timer; // Calculate time taken
      await addDoc(collection(db, "evaluaciones"), {
        userId: currentUser.uid,
        [piramideName]: piramideData.flat().filter(n => n),
        tiempoUsado: tiempoUsado,
        nombre: `Ejercicio 9: ${piramideName}`,
        nivel: "Basico",
        modulo: "3",
        createdAt: new Date()
      });
      console.log("Resultados enviados.");
    } catch (error) {
      console.error("Error al enviar las respuestas: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Error al enviar las respuestas',
        text: 'Hubo un error al enviar las respuestas.',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className={styles.container}>
       <h3>Nivel Básico</h3>
      <h3>Módulo 3 - Ejercicio 9</h3>
      <h5>Ejercicio de Atención</h5>

      
      {/* Display content based on current step */}
      {currentStep === 1 && (
        <div>
          <p>Ponga mucha atención y hagalo lo más rapido posible</p>
          <p>Dispones de 2 minutos en cada practica, al momento de hacer clic se mostrará la primera pidamide que deberás completar</p>
          <p>Vamos a activar el pensamiento lógico. No anote nada en su registro de práctica</p>
          <h3><strong>Ejemplo</strong></h3>
          {examplePiramide.map((level, idx) => (
            <div key={idx} className={styles[`nivel${level.length}`]}>
              {level.map((num, index) => (
                <span key={index} className={styles.block}>{num}</span>
              ))}
            </div>
          ))}
          <button className="btn btn-primary" onClick={() => advanceStep(2)}>Completar pirámide 1</button>
        </div>
      )}
      {currentStep === 2 && (
        <div>
          <h3>Timer: {timer} segundos</h3>
          {piramideOne.map((level, idx) => (
            <div key={idx} className={styles[`nivel${level.length}`]}>
              {level.map((num, index) => (
                <input
                  key={index}
                  type="text"
                  className={`${styles.block} form-control form-control-sm`}
                  value={num}
                  onChange={(e) => handleChange(e.target.value, setPiramideOne, idx, index)}
                  maxLength={3}
                />
              ))}
            </div>
          ))}
          <button className="btn btn-primary" onClick={() => advanceStep(3)}>Completar pirámide 2</button>
        </div>
      )}
      {currentStep === 3 && (
        <div>
          <h3>Timer: {timer} segundos</h3>
          {piramideTwo.map((level, idx) => (
            <div key={idx} className={styles[`nivel${level.length}`]}>
              {level.map((num, index) => (
                <input
                  key={index}
                  type="text"
                  className={`${styles.block} form-control form-control-sm`}
                  value={num}
                  onChange={(e) => handleChange(e.target.value, setPiramideTwo, idx, index)}
                  maxLength={3}
                />
              ))}
            </div>
          ))}
          <button className="btn btn-primary" onClick={() => advanceStep(4)}>Finalizar ejercicio</button>
        </div>
      )}
    </div>
  );
};

export default Ejercicio9;
