import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../Context';
import { storage, db } from '../../firebase/firebase';
import { doc, collection, setDoc, deleteDoc} from  'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid'; // Para generar IDs únicos para los archivos
import { Button, Form, Table, Container, Row, Col } from 'react-bootstrap';

function AddMaterialDidactico() {
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [level, setLevel] = useState('1');
    const { materials, setMaterials } = useContext(AppContext);
    

    const deleteMaterial = async (materialId, fileRef) => {
      try {
          // Eliminar el documento de Firestore
          const docRef = doc(db, 'materials', materialId);
          await deleteDoc(docRef);
  
          // Eliminar el archivo de Storage
          const storageRef = ref(storage, fileRef);
          await deleteObject(storageRef);
  
          // Actualizar el estado para reflejar la eliminación en la UI
          setMaterials(materials.filter(material => material.id !== materialId));
  
          alert('Material eliminado correctamente');
      } catch (error) {
          console.error('Error al eliminar material:', error);
          alert('Error al eliminar material');
      }
  };


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleLevelChange = (event) => {
        setLevel(event.target.value);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!file) return;
      const fileRef = ref(storage, `materials/${uuidv4()}.pdf`);
      try {
          const uploadResult = await uploadBytes(fileRef, file);
          const downloadUrl = await getDownloadURL(uploadResult.ref);
          const exists = await checkFileExists(downloadUrl); // Verificar si el archivo existe en Storage
          const newMaterial = { name, level, url: downloadUrl, exists };
          const materialsDocRef = doc(collection(db, 'materials')); // Asegúrate de incluir el estado 'exists'
          await setDoc(materialsDocRef, newMaterial);
          setMaterials([...materials, newMaterial]); 
          alert('Archivo cargado correctamente');
      } catch (error) {
          alert('Error al cargar el archivo');
          console.error(error);
      }
  };

    const checkFileExists = async (fileRef) => {
      try {
        await getDownloadURL(ref(storage, fileRef));
        return true;  // El archivo existe
      } catch (error) {
        return false; // El archivo no existe
      }
    };

    return (
      <Container style={{ marginTop: '20px' }}>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <Form onSubmit={handleSubmit} style={{ backgroundColor: '#f0f8ff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre del material</Form.Label>
                            <Form.Control type="text" value={name} onChange={handleNameChange} placeholder="Ingrese el nombre" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nivel</Form.Label>
                            <Form.Select value={level} onChange={handleLevelChange}>
                                <option value="1">Nivel 1</option>
                                <option value="2">Nivel 2</option>
                                <option value="3">Nivel 3</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Archivo</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} accept=".pdf" />
                        </Form.Group>
                        <Button variant="primary" type="submit">Cargar Material</Button>
                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
                <Col md={8}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Nivel</th>
                                <th>Descargar</th>
                                <th>Disponible</th>
                                <th>Acciones</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {materials.map((material, index) => (
                                <tr key={index}>
                                    <td>{material.name}</td>
                                    <td>{material.level}</td>
                                    <td>{material.exists ? <a href={material.url} download="true">Descargar</a> : 'No disponible'}</td>
                                    <td>{material.exists ? 'Sí' : 'No'}</td>
                                    <td>
                    <Button variant="danger" onClick={() => deleteMaterial(material.id, material.url)}>Eliminar</Button>
                </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export { AddMaterialDidactico };