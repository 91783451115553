import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { FaBook, FaChalkboardTeacher, FaFolderOpen, FaListAlt, FaFileAlt, FaMusic, FaPenAlt } from "react-icons/fa";
import { LuPencilLine, LuFileDown, LuBookMarked, LuMousePointerClick, LuInspect } from "react-icons/lu";
import ChatComponent from './Components/ChatComponent.jsx';



import './DashboardView.css'; // Importamos el archivo CSS
import { Button, Form, FormGroup, FormControl, Card, FormLabel, Container, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import EstimulacionNeuronal from '../routes/Components/EstimulacionNeuronal.jsx';
import './EditProfileView.css';

export default function DashboardView() {
  const [currentUser, setCurrentUser] = useState({});
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const [professorProfile, setProfessorProfile] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser.teacherId) {
      const professorRef = doc(db, "users", currentUser.teacherId);

      getDoc(professorRef).then(docSnap => {
        if (docSnap.exists()) {
          setProfessorProfile(docSnap.data());
        } else {
          console.log("No such document! Ensure 'teacherId' is a correct user ID.");
          setProfessorProfile(null); // Asegúrate de manejar el estado cuando no se encuentra el documento.
        }
      }).catch(error => {
        console.error("Error getting document:", error);
      });
    }
  }, [currentUser, db]); // Dependencias para el useEffect








  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        getDoc(userDocRef).then((doc) => {
          if (doc.exists()) {
            setCurrentUser(doc.data());
          } else {
            console.log("No such document!");
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });
      } else {
        setCurrentUser({});
      }
    });
    return () => unsubscribe();
  }, [auth, db]);

  const showAlert = (event) => {
    event.preventDefault(); // Prevenir que el enlace realice la navegación
    Swal.fire({
      title: 'Próximamente',
      text: 'Esta sección estará disponible próximamente.',
      icon: 'info',
      confirmButtonText: 'Ok'
    });
  };

  return (
    <div className="container">
      <h3 className='text-center text-md-start'>Dashboard</h3>
      <Row>
      
      <div className="col-12 col-md-4 mb-4  mb-4 ">
          <Card className="option-card mb-4 glass">

            <Card.Body >
              <div className=' d-flex justify-content-around'>
                <h4 className="card-title "> Perfil </h4>
                <div className='editProfile'>
                  <LuPencilLine className="option-icon" onClick={() => navigate('/dashboard/profile')} style={{ cursor: 'pointer' }} />
                </div>
              </div>
              <Form>

                <FormGroup className="mb-3">
                  <img src={currentUser.profilePicture || "/placeholder.jpg"} alt="Profile" className="img-thumbnail mb-3 rounded-circle" style={{ width: "100px", height: "100px", border: '4px solid gray' }} />
                  <h2 className="card-title"> {currentUser.name}</h2>
                  <p>@{currentUser.username}</p>
                </FormGroup>



              </Form>
            </Card.Body>
          </Card>
          {!currentUser?.teacher && (
          <>
          <Card className="option-card mb-4 glass">
                <Card.Body>
                  <div className=' d-flex justify-content-around'>
                    <h4 className="card-title "> Tu profesor asignado </h4>


                  </div>
                  <Form>

                    <FormGroup className="mb-3">
                      {professorProfile && (
                        <div className="mt-3">
                          <img src={professorProfile ? professorProfile.profilePicture : "/default-avatar.jpg"} alt="Profile of Assigned Teacher" className="img-thumbnail mb-3 rounded-circle" style={{ width: "100px", height: "100px", border: '4px solid gray' }} />
                          <h2 className='card-title'>{professorProfile.name}</h2>
                        </div>
                      )}


                    </FormGroup>



                  </Form>
                </Card.Body>
              </Card>
              </>

          )}
        </div>
        <div className="col-12 col-md-8 mb-4">

          <Row className="align-items-strech">
            <Col md={3} className=' mb-3 mb-md-0'>

              <div className="card option-card glass h-100 justify-content-between">
                <div className="card-body text-center ">
                  <LuFileDown className="option-icon" />
                  <h5 className="card-title mt-3">Material para imprimir</h5>
                  <p className="card-text"></p>
                  <Link to="/material-didactico" className="btn btn-primary btn-sm w-100">Ir</Link>
                </div>
              </div>

            </Col>
            <Col md={3} className=' mb-3 mb-md-0'>

              <div className="card option-card glass h-100 justify-content-between">
                <div className="card-body text-center">
                  <LuBookMarked className="option-icon" />
                  <h5 className="card-title mt-3">Material Teórico</h5>
                  <p className="card-text"></p>
                  <Link to="/material-teorico" className="btn btn-primary btn-sm w-100">Ir</Link>
                </div>
              </div>

            </Col>

            <Col md={3} className=' mb-3 mb-md-0'>
              <div className="card option-card glass h-100 justify-content-between">
                <div className="card-body text-center">
                  <LuMousePointerClick className="option-icon" />
                  <h5 className="card-title mt-3">Cuaderno Digital</h5>
                  <p className="card-text"></p>
                  <Link to="/cuaderno-digital" className="btn btn-primary btn-sm w-100">Ir</Link>
                </div>

              </div>
            </Col>

            <Col md={3}>
              <div className="card option-card glass h-100 justify-content-between">
                <div className="card-body text-center">
                  <LuInspect className="option-icon" />
                  <h5 className="card-title mt-3">Ejercicios Prácticos</h5>
                  <p className="card-text"></p>
                  <button onClick={showAlert} className="btn btn-primary btn-sm w-100">
                    Ir
                  </button>
                </div>
              </div>
            </Col>
            </Row>
            <Row className='mt-4'>
            <Col md={12}>
            {!currentUser?.teacher && (
          <>
            
              <Card className="option-card glass">
                <Card.Body>
                  {/* <FaMusic className="option-icon" />
                    <h5 className="card-title mt-3">Estimulación neuronal</h5>
                    <p className="card-text
                    "></p>
                     */}
                  <h4 className="card-title "> Reproductor de estimulación Neuronal </h4>
                  <EstimulacionNeuronal />
                  <Link to="/EstimulacionNeuronal" className=" mt-4 btn btn-primary btn-sm w-100">Ir</Link>
                </Card.Body>
              </Card>
            
          </>
        )}
        </Col>
          </Row>
          {currentUser?.teacher && (
          <>
          <Row>
            <Col md={12} className='glass'>
          
            
              <h2 className="card-title mt-3">Sección Profesor</h2>
            

            {/* <div className="col-12 col-md-4 col-lg-3 mb-4">
              <div className="card option-card">
                <div className="card-body text-center">
                  <FaListAlt className="option-icon" />
                  <h5 className="card-title mt-3">Registro de Prácticas</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <Link to="/registro-practicas" className="btn btn-primary btn-sm w-100">Ir</Link>
                </div>
              </div>
            </div> */}

            
              
                <div className="card-body text-center">
                  <FaListAlt className="option-icon" />
                  <h5 className="card-title mt-3">Listado de Alumnos</h5>
                  <p className="card-text"></p>
                  <div>
                  <Link to="/listado-alumnos" className="btn px-5  mb-4 btn-primary btn-sm w-100">Ir</Link>
                  </div>
                </div>
              
            
          
          </Col>

          </Row>
          
          </>
        )}
        </div>
        <Row className='mt-4'>
            <Col md={12}>
              <Card className="option-card glass">
                <Card.Body>
                  <h4 className="card-title">Chat en vivo</h4>
                  <ChatComponent />  {/* Aquí integras el componente de TalkJS */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        
        
        </Row>
     
    </div>
  );
}
